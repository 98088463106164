import { UserRoleTypes } from "../services/db-services/userDBService/types";

export enum PermissionTypes {
  // Tabs
  ViewBasePagesTab = "view-base-pages-tab",
  ViewYevmiyePagesTab = "view-yevmiye-pages-tab",
  ViewDataAnalysisPagesTab = "view-data-analysis-pages-tab",
  ViewHealthPagesTab = "view-health-pages-tab",
  ViewFundPagesTab = "view-fund-pages-tab",
  ViewHospitalPagesTab = "view-hospital-pages-tab",
  ViewHospitalInEngPagesTab = "view-hospital-in-eng-pages-tab",
  ViewWorkCubePagesTab = "view-workcube-pages-tab",
  // Pages
  ViewBaseAssignmentsPage = "view-base-assignments-page",
  ViewBaseDashboardPage = "view-base-dashboard-page",
  ViewBaseQueryLogsPage = "view-base-query-logs-page",
  ViewBaseLLMLogsPage = "view-base-llm-logs-page",
  ViewBaseQuestionCollectionPage = "view-base-question-collection-page",
  ViewBaseDocumentCollectionsPage = "view-base-document-collections-page",
  ViewYevmiyeQueryLogsPage = "view-yevmiye-query-logs-page",
  ViewYevmiyeLLMLogsPage = "view-yevmiye-llm-logs-page",
  ViewYevmiyeSQLLLMLogsPage = "view-yevmiye-sql-llm-logs-page",
  ViewYevmiyeSQLQueryLogsPage = "view-yevmiye-sql-query-logs-page",
  ViewYevmiyePage = "view-yevmiye-page",
  ViewYevmiyeSQLPage = "view-yevmiye-sql-page",
  ViewDataAnalysisPage = "view-data-analysis-page",
  ViewDataAnalysisLogsPage = "view-data-analysis-logs-page",
  ViewDataAnalysisLLMLogsPage = "view-data-analysis-llm-logs-page",
  ViewHealthLLMLogsPage = "view-health-llm-logs-page",
  ViewHealthQueryLogsPage = "view-health-query-logs-page",
  ViewHealthChatQueryLogsPage = "view-health-chat-query-logs-page",
  ViewHealthPatientPages = "view-health-patient-pages",
  ViewHealthInstructionsPages = "view-health-instructions-pages",
  ViewFundQueryPage = "view-fund-query-page",
  ViewFundLogsPage = "view-fund-logs-page",
  ViewFundLLMLogsPage = "view-fund-llm-logs-page",
  ViewHospitalQueryPage = "view-hospital-query-page",
  ViewHospitalLogsPage = "view-hospital-logs-page",
  ViewHospitalLLMLogsPage = "view-hospital-llm-logs-page",
  ViewHospitalInEngQueryPage = "view-hospital-in-eng-query-page",
  ViewHospitalInEngLogsPage = "view-hospital-in-eng-logs-page",
  ViewHospitalInEngLLMLogsPage = "view-hospital-in-eng-llm-logs-page",
  ViewWorkCubeQueryPage = "view-workcube-query-page",
  ViewWorkCubeLogsPage = "view-workcube-logs-page",
  ViewWorkCubeLLMLogsPage = "view-workcube-llm-logs-page",
}

export const permissions = {
  [UserRoleTypes.Admin]: [
    // Tabs
    PermissionTypes.ViewBasePagesTab,
    PermissionTypes.ViewYevmiyePagesTab,
    PermissionTypes.ViewDataAnalysisPagesTab,
    PermissionTypes.ViewHealthPagesTab,
    PermissionTypes.ViewFundPagesTab,
    PermissionTypes.ViewHospitalPagesTab,
    PermissionTypes.ViewHospitalInEngPagesTab,
    PermissionTypes.ViewWorkCubePagesTab,
    // Pages
    PermissionTypes.ViewBaseAssignmentsPage,
    PermissionTypes.ViewBaseDashboardPage,
    PermissionTypes.ViewBaseQueryLogsPage,
    PermissionTypes.ViewBaseLLMLogsPage,
    PermissionTypes.ViewBaseQuestionCollectionPage,
    PermissionTypes.ViewYevmiyeQueryLogsPage,
    PermissionTypes.ViewYevmiyeLLMLogsPage,
    PermissionTypes.ViewYevmiyeSQLQueryLogsPage,
    PermissionTypes.ViewYevmiyeSQLLLMLogsPage,
    PermissionTypes.ViewYevmiyePage,
    PermissionTypes.ViewYevmiyeSQLPage,
    PermissionTypes.ViewHealthPatientPages,
    PermissionTypes.ViewBaseDocumentCollectionsPage,
    PermissionTypes.ViewDataAnalysisPage,
    PermissionTypes.ViewDataAnalysisLogsPage,
    PermissionTypes.ViewDataAnalysisLLMLogsPage,
    PermissionTypes.ViewHealthLLMLogsPage,
    PermissionTypes.ViewHealthQueryLogsPage,
    PermissionTypes.ViewHealthChatQueryLogsPage,
    PermissionTypes.ViewHealthInstructionsPages,
    PermissionTypes.ViewFundQueryPage,
    PermissionTypes.ViewFundLogsPage,
    PermissionTypes.ViewFundLLMLogsPage,
    PermissionTypes.ViewHospitalQueryPage,
    PermissionTypes.ViewHospitalLogsPage,
    PermissionTypes.ViewHospitalLLMLogsPage,
    PermissionTypes.ViewHospitalInEngQueryPage,
    PermissionTypes.ViewHospitalInEngLogsPage,
    PermissionTypes.ViewHospitalInEngLLMLogsPage,
    PermissionTypes.ViewWorkCubeQueryPage,
    PermissionTypes.ViewWorkCubeLogsPage,
    PermissionTypes.ViewWorkCubeLLMLogsPage
  ],
  [UserRoleTypes.Mevzuat]: [
    // Tabs
    PermissionTypes.ViewBasePagesTab,
    PermissionTypes.ViewDataAnalysisPagesTab,
    // Pages
    PermissionTypes.ViewBaseAssignmentsPage,
    PermissionTypes.ViewBaseDashboardPage,
    PermissionTypes.ViewBaseQuestionCollectionPage,
    PermissionTypes.ViewBaseDocumentCollectionsPage,
    PermissionTypes.ViewDataAnalysisPage,
  ],
  [UserRoleTypes.Yevmiye]: [
    // Tabs
    PermissionTypes.ViewYevmiyePagesTab,
    PermissionTypes.ViewDataAnalysisPagesTab,
    // Pages
    PermissionTypes.ViewYevmiyePage,
    PermissionTypes.ViewYevmiyeSQLPage,
    PermissionTypes.ViewDataAnalysisPage,
  ],
  [UserRoleTypes.YevmiyeDev]: [
    // Tabs
    PermissionTypes.ViewYevmiyePagesTab,
    PermissionTypes.ViewDataAnalysisPagesTab,
    // Pages
    PermissionTypes.ViewYevmiyeQueryLogsPage,
    PermissionTypes.ViewYevmiyeLLMLogsPage,
    PermissionTypes.ViewYevmiyeSQLQueryLogsPage,
    PermissionTypes.ViewYevmiyeSQLLLMLogsPage,
    PermissionTypes.ViewYevmiyePage,
    PermissionTypes.ViewYevmiyeSQLPage,
    PermissionTypes.ViewDataAnalysisPage,
  ],
  [UserRoleTypes.Standart]: [
    // Tabs
    PermissionTypes.ViewBasePagesTab,
    PermissionTypes.ViewDataAnalysisPagesTab,
    // Pages
    PermissionTypes.ViewBaseDocumentCollectionsPage,
    PermissionTypes.ViewDataAnalysisPage,
  ],
  [UserRoleTypes.Health]: [
    // Tabs
    PermissionTypes.ViewDataAnalysisPagesTab,
    PermissionTypes.ViewHealthPagesTab,
    // Pages
    PermissionTypes.ViewHealthPatientPages,
    PermissionTypes.ViewDataAnalysisPage,
    PermissionTypes.ViewHealthInstructionsPages,
  ],
  [UserRoleTypes.HealthDev]: [
    // Tabs
    PermissionTypes.ViewHealthPagesTab,
    // Pages
    PermissionTypes.ViewHealthPatientPages,
    PermissionTypes.ViewHealthLLMLogsPage,
    PermissionTypes.ViewHealthQueryLogsPage,
    PermissionTypes.ViewHealthChatQueryLogsPage,
    PermissionTypes.ViewHealthInstructionsPages,
  ],
  [UserRoleTypes.Demo]: [
    // Tabs
    PermissionTypes.ViewBasePagesTab,
    PermissionTypes.ViewYevmiyePagesTab,
    PermissionTypes.ViewDataAnalysisPagesTab,
    PermissionTypes.ViewHealthPagesTab,
    // Pages
    PermissionTypes.ViewYevmiyeSQLPage,
    PermissionTypes.ViewHealthPatientPages,
    PermissionTypes.ViewBaseDocumentCollectionsPage,
    PermissionTypes.ViewDataAnalysisPage,
  ],
  [UserRoleTypes.Fund]: [
    // Tabs
    PermissionTypes.ViewDataAnalysisPagesTab,
    PermissionTypes.ViewFundPagesTab,
    // Pages
    PermissionTypes.ViewDataAnalysisPage,
    PermissionTypes.ViewFundQueryPage,
  ],
  [UserRoleTypes.FundDev]: [
    // Tabs
    PermissionTypes.ViewDataAnalysisPagesTab,
    PermissionTypes.ViewFundPagesTab,
    // Pages
    PermissionTypes.ViewDataAnalysisPage,
    PermissionTypes.ViewFundQueryPage,
    PermissionTypes.ViewFundLogsPage,
    PermissionTypes.ViewFundLLMLogsPage,
  ],
  [UserRoleTypes.Hospital]: [
    // Tabs
    PermissionTypes.ViewHospitalPagesTab,
    // Pages
    PermissionTypes.ViewHospitalQueryPage,
  ],
  [UserRoleTypes.HospitalDev]: [
    // Tabs
    PermissionTypes.ViewHospitalPagesTab,
    // Pages
    PermissionTypes.ViewHospitalQueryPage,
    PermissionTypes.ViewHospitalLogsPage,
    PermissionTypes.ViewHospitalLLMLogsPage
  ],
  [UserRoleTypes.HospitalInEng]: [
    // Tabs
    PermissionTypes.ViewHospitalInEngPagesTab,
    // Pages
    PermissionTypes.ViewHospitalInEngQueryPage
  ],
  [UserRoleTypes.HospitalInEngDev]: [
    // Tabs
    PermissionTypes.ViewHospitalInEngPagesTab,
    // Pages
    PermissionTypes.ViewHospitalInEngQueryPage,
    PermissionTypes.ViewHospitalInEngLogsPage,
    PermissionTypes.ViewHospitalInEngLLMLogsPage
  ],
  [UserRoleTypes.WorkCube] : [
    // Tabs
    PermissionTypes.ViewWorkCubePagesTab,
    // Pages
    PermissionTypes.ViewWorkCubeQueryPage,
  ],
  [UserRoleTypes.WorkCubeDev] : [
    // Tabs
    PermissionTypes.ViewWorkCubePagesTab,
    // Pages
    PermissionTypes.ViewWorkCubeQueryPage,
    PermissionTypes.ViewWorkCubeLogsPage,
    PermissionTypes.ViewWorkCubeLLMLogsPage
  ],
};
