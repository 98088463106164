import { Api } from "../../../api";
import QueryLogDBService from "../../db-services/queryLogDBService";
import {
  ICreateUpdateCommentInputModel,
  IExecuteGeneratedCodeInputModel,
  IExecuteGeneratedCodeResponseModel,
  IQueryLogResponseModel,
} from "./types";

class QueryLogService {
  private dbService;

  constructor() {
    this.dbService = new QueryLogDBService();
  }

  async getAllQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllQueryLogsWithSQL(
    dayAgo: number
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllQueryLogsWithSQL(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllFundQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllFundQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllWorkCubeQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllWorkCubeQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllDataAnalysisQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllDataAnalysisQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllBaseQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllBaseQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllHospitalQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllHospitalQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllHospitalInEngQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllHospitalInEngQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogsByEmail(email: string): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogsSQLByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getQueryLogsSQLByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getFundQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getFundQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getWorkCubeQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getWorkCubeQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getDataAnalysisQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getDataAnalysisQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getBaseQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getBaseQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getHospitalQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getHospitalQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getHospitalInEngQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getHospitalInEngQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogSQLById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getByIdWithSQL(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getFundQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getFundLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getWorkCubeQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getWorkCubeLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getDataAnalysisQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getDataAnalysisLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getBaseQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getBaseLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getHospitalQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getHospitalLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getHospitalInEngQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getHospitalInEngLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateComment(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateCommentWithSQL(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateByIdWithSQL(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateFundComment(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateFundLogById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateWorkCubeComment(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateWorkCubeLogById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateHospitalComment(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateHospitalLogById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateHospitalInEngComment(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateHospitalInEngLogById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async executeGeneratedCode(
    input: IExecuteGeneratedCodeInputModel
  ): Promise<IExecuteGeneratedCodeResponseModel> {
    try {
      const response = await Api.post("/exec_python_code", input, {});

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async executeGeneratedCodeWithSQL(
    input: IExecuteGeneratedCodeInputModel
  ): Promise<IExecuteGeneratedCodeResponseModel> {
    try {
      const response = await Api.post("/exec_plpgsql_code", input, {});

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async executeGeneratedFundCode(
    input: IExecuteGeneratedCodeInputModel
  ): Promise<IExecuteGeneratedCodeResponseModel> {
    try {
      const response = await Api.post("/exec_python_code_for_funds", input, {});

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async executeGeneratedWorkCubeCode(
    input: IExecuteGeneratedCodeInputModel
  ): Promise<IExecuteGeneratedCodeResponseModel> {
    try {
      const response = await Api.post("/exec_python_code_for_workcube", input, {});

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async executeGeneratedDataAnalysisCode(
    input: IExecuteGeneratedCodeInputModel
  ): Promise<IExecuteGeneratedCodeResponseModel> {
    try {
      const response = await Api.post("/exec_python_code_for_titanic", input, {});

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async executeGeneratedHospitalCode(
    input: IExecuteGeneratedCodeInputModel
  ): Promise<IExecuteGeneratedCodeResponseModel> {
    try {
      const response = await Api.post("/exec_python_code_for_hospital", input, {});

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default QueryLogService;
