export const dataAnalysis = [
    {
        "PassengerId": 1,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Braund, Mr. Owen Harris",
        "Sex": "male",
        "Age": 22,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "A/5 21171",
        "Fare": 7.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 2,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Cumings, Mrs. John Bradley (Florence Briggs Thayer)",
        "Sex": "female",
        "Age": 38,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "PC 17599",
        "Fare": 71.2833,
        "Cabin": "C85",
        "Embarked": "C"
    },
    {
        "PassengerId": 3,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Heikkinen, Miss. Laina",
        "Sex": "female",
        "Age": 26,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O2. 3101282",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 4,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Futrelle, Mrs. Jacques Heath (Lily May Peel)",
        "Sex": "female",
        "Age": 35,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 113803,
        "Fare": 53.1,
        "Cabin": "C123",
        "Embarked": "S"
    },
    {
        "PassengerId": 5,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Allen, Mr. William Henry",
        "Sex": "male",
        "Age": 35,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 373450,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 6,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Moran, Mr. James",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 330877,
        "Fare": 8.4583,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 7,
        "Survived": 0,
        "Pclass": 1,
        "Name": "McCarthy, Mr. Timothy J",
        "Sex": "male",
        "Age": 54,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 17463,
        "Fare": 51.8625,
        "Cabin": "E46",
        "Embarked": "S"
    },
    {
        "PassengerId": 8,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Palsson, Master. Gosta Leonard",
        "Sex": "male",
        "Age": 2,
        "SibSp": 3,
        "Parch": 1,
        "Ticket": 349909,
        "Fare": 21.075,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 9,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Johnson, Mrs. Oscar W (Elisabeth Vilhelmina Berg)",
        "Sex": "female",
        "Age": 27,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 347742,
        "Fare": 11.1333,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 10,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Nasser, Mrs. Nicholas (Adele Achem)",
        "Sex": "female",
        "Age": 14,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 237736,
        "Fare": 30.0708,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 11,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Sandstrom, Miss. Marguerite Rut",
        "Sex": "female",
        "Age": 4,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "PP 9549",
        "Fare": 16.7,
        "Cabin": "G6",
        "Embarked": "S"
    },
    {
        "PassengerId": 12,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Bonnell, Miss. Elizabeth",
        "Sex": "female",
        "Age": 58,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113783,
        "Fare": 26.55,
        "Cabin": "C103",
        "Embarked": "S"
    },
    {
        "PassengerId": 13,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Saundercock, Mr. William Henry",
        "Sex": "male",
        "Age": 20,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/5. 2151",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 14,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Andersson, Mr. Anders Johan",
        "Sex": "male",
        "Age": 39,
        "SibSp": 1,
        "Parch": 5,
        "Ticket": 347082,
        "Fare": 31.275,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 15,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Vestrom, Miss. Hulda Amanda Adolfina",
        "Sex": "female",
        "Age": 14,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 350406,
        "Fare": 7.8542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 16,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Hewlett, Mrs. (Mary D Kingcome)",
        "Sex": "female",
        "Age": 55,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 248706,
        "Fare": 16,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 17,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Rice, Master. Eugene",
        "Sex": "male",
        "Age": 2,
        "SibSp": 4,
        "Parch": 1,
        "Ticket": 382652,
        "Fare": 29.125,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 18,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Williams, Mr. Charles Eugene",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 244373,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 19,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Vander Planke, Mrs. Julius (Emelia Maria Vandemoortele)",
        "Sex": "female",
        "Age": 31,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 345763,
        "Fare": 18,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 20,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Masselmani, Mrs. Fatima",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2649,
        "Fare": 7.225,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 21,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Fynney, Mr. Joseph J",
        "Sex": "male",
        "Age": 35,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 239865,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 22,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Beesley, Mr. Lawrence",
        "Sex": "male",
        "Age": 34,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 248698,
        "Fare": 13,
        "Cabin": "D56",
        "Embarked": "S"
    },
    {
        "PassengerId": 23,
        "Survived": 1,
        "Pclass": 3,
        "Name": "McGowan, Miss. Anna \"Annie",
        "Sex": "female",
        "Age": 15,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 330923,
        "Fare": 8.0292,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 24,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Sloper, Mr. William Thompson",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113788,
        "Fare": 35.5,
        "Cabin": "A6",
        "Embarked": "S"
    },
    {
        "PassengerId": 25,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Palsson, Miss. Torborg Danira",
        "Sex": "female",
        "Age": 8,
        "SibSp": 3,
        "Parch": 1,
        "Ticket": 349909,
        "Fare": 21.075,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 26,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Asplund, Mrs. Carl Oscar (Selma Augusta Emilia Johansson)",
        "Sex": "female",
        "Age": 38,
        "SibSp": 1,
        "Parch": 5,
        "Ticket": 347077,
        "Fare": 31.3875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 27,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Emir, Mr. Farred Chehab",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2631,
        "Fare": 7.225,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 28,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Fortune, Mr. Charles Alexander",
        "Sex": "male",
        "Age": 19,
        "SibSp": 3,
        "Parch": 2,
        "Ticket": 19950,
        "Fare": 263,
        "Cabin": "C23 C25 C27",
        "Embarked": "S"
    },
    {
        "PassengerId": 29,
        "Survived": 1,
        "Pclass": 3,
        "Name": "O'Dwyer, Miss. Ellen \"Nellie",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 330959,
        "Fare": 7.8792,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 30,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Todoroff, Mr. Lalio",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349216,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 31,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Uruchurtu, Don. Manuel E",
        "Sex": "male",
        "Age": 40,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17601",
        "Fare": 27.7208,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 32,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Spencer, Mrs. William Augustus (Marie Eugenie)",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "PC 17569",
        "Fare": 146.5208,
        "Cabin": "B78",
        "Embarked": "C"
    },
    {
        "PassengerId": 33,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Glynn, Miss. Mary Agatha",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 335677,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 34,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Wheadon, Mr. Edward H",
        "Sex": "male",
        "Age": 66,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A. 24579",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 35,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Meyer, Mr. Edgar Joseph",
        "Sex": "male",
        "Age": 28,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "PC 17604",
        "Fare": 82.1708,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 36,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Holverson, Mr. Alexander Oskar",
        "Sex": "male",
        "Age": 42,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 113789,
        "Fare": 52,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 37,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Mamee, Mr. Hanna",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2677,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 38,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Cann, Mr. Ernest Charles",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A./5. 2152",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 39,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Vander Planke, Miss. Augusta Maria",
        "Sex": "female",
        "Age": 18,
        "SibSp": 2,
        "Parch": 0,
        "Ticket": 345764,
        "Fare": 18,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 40,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Nicola-Yarred, Miss. Jamila",
        "Sex": "female",
        "Age": 14,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 2651,
        "Fare": 11.2417,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 41,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Ahlin, Mrs. Johan (Johanna Persdotter Larsson)",
        "Sex": "female",
        "Age": 40,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 7546,
        "Fare": 9.475,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 42,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Turpin, Mrs. William John Robert (Dorothy Ann Wonnacott)",
        "Sex": "female",
        "Age": 27,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 11668,
        "Fare": 21,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 43,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Kraeff, Mr. Theodor",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349253,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 44,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Laroche, Miss. Simonne Marie Anne Andree",
        "Sex": "female",
        "Age": 3,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": "SC/Paris 2123",
        "Fare": 41.5792,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 45,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Devaney, Miss. Margaret Delia",
        "Sex": "female",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 330958,
        "Fare": 7.8792,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 46,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Rogers, Mr. William John",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "S.C./A.4. 23567",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 47,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lennon, Mr. Denis",
        "Sex": "male",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 370371,
        "Fare": 15.5,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 48,
        "Survived": 1,
        "Pclass": 3,
        "Name": "O'Driscoll, Miss. Bridget",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 14311,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 49,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Samaan, Mr. Youssef",
        "Sex": "male",
        "Age": "",
        "SibSp": 2,
        "Parch": 0,
        "Ticket": 2662,
        "Fare": 21.6792,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 50,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Arnold-Franchi, Mrs. Josef (Josefine Franchi)",
        "Sex": "female",
        "Age": 18,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 349237,
        "Fare": 17.8,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 51,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Panula, Master. Juha Niilo",
        "Sex": "male",
        "Age": 7,
        "SibSp": 4,
        "Parch": 1,
        "Ticket": 3101295,
        "Fare": 39.6875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 52,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Nosworthy, Mr. Richard Cater",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/4. 39886",
        "Fare": 7.8,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 53,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Harper, Mrs. Henry Sleeper (Myna Haxtun)",
        "Sex": "female",
        "Age": 49,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "PC 17572",
        "Fare": 76.7292,
        "Cabin": "D33",
        "Embarked": "C"
    },
    {
        "PassengerId": 54,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Faunthorpe, Mrs. Lizzie (Elizabeth Anne Wilkinson)",
        "Sex": "female",
        "Age": 29,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 2926,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 55,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Ostby, Mr. Engelhart Cornelius",
        "Sex": "male",
        "Age": 65,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 113509,
        "Fare": 61.9792,
        "Cabin": "B30",
        "Embarked": "C"
    },
    {
        "PassengerId": 56,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Woolner, Mr. Hugh",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 19947,
        "Fare": 35.5,
        "Cabin": "C52",
        "Embarked": "S"
    },
    {
        "PassengerId": 57,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Rugg, Miss. Emily",
        "Sex": "female",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A. 31026",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 58,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Novel, Mr. Mansouer",
        "Sex": "male",
        "Age": 28.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2697,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 59,
        "Survived": 1,
        "Pclass": 2,
        "Name": "West, Miss. Constance Mirium",
        "Sex": "female",
        "Age": 5,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": "C.A. 34651",
        "Fare": 27.75,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 60,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Goodwin, Master. William Frederick",
        "Sex": "male",
        "Age": 11,
        "SibSp": 5,
        "Parch": 2,
        "Ticket": "CA 2144",
        "Fare": 46.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 61,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sirayanian, Mr. Orsen",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2669,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 62,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Icard, Miss. Amelie",
        "Sex": "female",
        "Age": 38,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113572,
        "Fare": 80,
        "Cabin": "B28",
        "Embarked": ""
    },
    {
        "PassengerId": 63,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Harris, Mr. Henry Birkhardt",
        "Sex": "male",
        "Age": 45,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 36973,
        "Fare": 83.475,
        "Cabin": "C83",
        "Embarked": "S"
    },
    {
        "PassengerId": 64,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Skoog, Master. Harald",
        "Sex": "male",
        "Age": 4,
        "SibSp": 3,
        "Parch": 2,
        "Ticket": 347088,
        "Fare": 27.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 65,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Stewart, Mr. Albert A",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17605",
        "Fare": 27.7208,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 66,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Moubarek, Master. Gerios",
        "Sex": "male",
        "Age": "",
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 2661,
        "Fare": 15.2458,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 67,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Nye, Mrs. (Elizabeth Ramell)",
        "Sex": "female",
        "Age": 29,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A. 29395",
        "Fare": 10.5,
        "Cabin": "F33",
        "Embarked": "S"
    },
    {
        "PassengerId": 68,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Crease, Mr. Ernest James",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "S.P. 3464",
        "Fare": 8.1583,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 69,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Andersson, Miss. Erna Alexandra",
        "Sex": "female",
        "Age": 17,
        "SibSp": 4,
        "Parch": 2,
        "Ticket": 3101281,
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 70,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Kink, Mr. Vincenz",
        "Sex": "male",
        "Age": 26,
        "SibSp": 2,
        "Parch": 0,
        "Ticket": 315151,
        "Fare": 8.6625,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 71,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Jenkin, Mr. Stephen Curnow",
        "Sex": "male",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A. 33111",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 72,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Goodwin, Miss. Lillian Amy",
        "Sex": "female",
        "Age": 16,
        "SibSp": 5,
        "Parch": 2,
        "Ticket": "CA 2144",
        "Fare": 46.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 73,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Hood, Mr. Ambrose Jr",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "S.O.C. 14879",
        "Fare": 73.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 74,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Chronopoulos, Mr. Apostolos",
        "Sex": "male",
        "Age": 26,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 2680,
        "Fare": 14.4542,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 75,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Bing, Mr. Lee",
        "Sex": "male",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 1601,
        "Fare": 56.4958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 76,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Moen, Mr. Sigurd Hansen",
        "Sex": "male",
        "Age": 25,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 348123,
        "Fare": 7.65,
        "Cabin": "F G73",
        "Embarked": "S"
    },
    {
        "PassengerId": 77,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Staneff, Mr. Ivan",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349208,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 78,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Moutal, Mr. Rahamin Haim",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 374746,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 79,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Caldwell, Master. Alden Gates",
        "Sex": "male",
        "Age": 0.83,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 248738,
        "Fare": 29,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 80,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Dowdell, Miss. Elizabeth",
        "Sex": "female",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 364516,
        "Fare": 12.475,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 81,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Waelens, Mr. Achille",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 345767,
        "Fare": 9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 82,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Sheerlinck, Mr. Jan Baptist",
        "Sex": "male",
        "Age": 29,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 345779,
        "Fare": 9.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 83,
        "Survived": 1,
        "Pclass": 3,
        "Name": "McDermott, Miss. Brigdet Delia",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 330932,
        "Fare": 7.7875,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 84,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Carrau, Mr. Francisco M",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113059,
        "Fare": 47.1,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 85,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Ilett, Miss. Bertha",
        "Sex": "female",
        "Age": 17,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SO/C 14885",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 86,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Backstrom, Mrs. Karl Alfred (Maria Mathilda Gustafsson)",
        "Sex": "female",
        "Age": 33,
        "SibSp": 3,
        "Parch": 0,
        "Ticket": 3101278,
        "Fare": 15.85,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 87,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Ford, Mr. William Neal",
        "Sex": "male",
        "Age": 16,
        "SibSp": 1,
        "Parch": 3,
        "Ticket": "W./C. 6608",
        "Fare": 34.375,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 88,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Slocovski, Mr. Selman Francis",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/OQ 392086",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 89,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Fortune, Miss. Mabel Helen",
        "Sex": "female",
        "Age": 23,
        "SibSp": 3,
        "Parch": 2,
        "Ticket": 19950,
        "Fare": 263,
        "Cabin": "C23 C25 C27",
        "Embarked": "S"
    },
    {
        "PassengerId": 90,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Celotti, Mr. Francesco",
        "Sex": "male",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 343275,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 91,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Christmann, Mr. Emil",
        "Sex": "male",
        "Age": 29,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 343276,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 92,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Andreasson, Mr. Paul Edvin",
        "Sex": "male",
        "Age": 20,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347466,
        "Fare": 7.8542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 93,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Chaffee, Mr. Herbert Fuller",
        "Sex": "male",
        "Age": 46,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "W.E.P. 5734",
        "Fare": 61.175,
        "Cabin": "E31",
        "Embarked": "S"
    },
    {
        "PassengerId": 94,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Dean, Mr. Bertram Frank",
        "Sex": "male",
        "Age": 26,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": "C.A. 2315",
        "Fare": 20.575,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 95,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Coxon, Mr. Daniel",
        "Sex": "male",
        "Age": 59,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 364500,
        "Fare": 7.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 96,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Shorney, Mr. Charles Joseph",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 374910,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 97,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Goldschmidt, Mr. George B",
        "Sex": "male",
        "Age": 71,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17754",
        "Fare": 34.6542,
        "Cabin": "A5",
        "Embarked": "C"
    },
    {
        "PassengerId": 98,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Greenfield, Mr. William Bertram",
        "Sex": "male",
        "Age": 23,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": "PC 17759",
        "Fare": 63.3583,
        "Cabin": "D10 D12",
        "Embarked": "C"
    },
    {
        "PassengerId": 99,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Doling, Mrs. John T (Ada Julia Bone)",
        "Sex": "female",
        "Age": 34,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 231919,
        "Fare": 23,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 100,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Kantor, Mr. Sinai",
        "Sex": "male",
        "Age": 34,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 244367,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 101,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Petranec, Miss. Matilda",
        "Sex": "female",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349245,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 102,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Petroff, Mr. Pastcho (\"Pentcho\")",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349215,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 103,
        "Survived": 0,
        "Pclass": 1,
        "Name": "White, Mr. Richard Frasar",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 35281,
        "Fare": 77.2875,
        "Cabin": "D26",
        "Embarked": "S"
    },
    {
        "PassengerId": 104,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Johansson, Mr. Gustaf Joel",
        "Sex": "male",
        "Age": 33,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 7540,
        "Fare": 8.6542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 105,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Gustafsson, Mr. Anders Vilhelm",
        "Sex": "male",
        "Age": 37,
        "SibSp": 2,
        "Parch": 0,
        "Ticket": 3101276,
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 106,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Mionoff, Mr. Stoytcho",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349207,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 107,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Salkjelsvik, Miss. Anna Kristine",
        "Sex": "female",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 343120,
        "Fare": 7.65,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 108,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Moss, Mr. Albert Johan",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 312991,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 109,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Rekic, Mr. Tido",
        "Sex": "male",
        "Age": 38,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349249,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 110,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Moran, Miss. Bertha",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 371110,
        "Fare": 24.15,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 111,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Porter, Mr. Walter Chamberlain",
        "Sex": "male",
        "Age": 47,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 110465,
        "Fare": 52,
        "Cabin": "C110",
        "Embarked": "S"
    },
    {
        "PassengerId": 112,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Zabour, Miss. Hileni",
        "Sex": "female",
        "Age": 14.5,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 2665,
        "Fare": 14.4542,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 113,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Barton, Mr. David John",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 324669,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 114,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Jussila, Miss. Katriina",
        "Sex": "female",
        "Age": 20,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 4136,
        "Fare": 9.825,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 115,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Attalah, Miss. Malake",
        "Sex": "female",
        "Age": 17,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2627,
        "Fare": 14.4583,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 116,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Pekoniemi, Mr. Edvard",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O 2. 3101294",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 117,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Connors, Mr. Patrick",
        "Sex": "male",
        "Age": 70.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 370369,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 118,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Turpin, Mr. William John Robert",
        "Sex": "male",
        "Age": 29,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 11668,
        "Fare": 21,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 119,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Baxter, Mr. Quigg Edmond",
        "Sex": "male",
        "Age": 24,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": "PC 17558",
        "Fare": 247.5208,
        "Cabin": "B58 B60",
        "Embarked": "C"
    },
    {
        "PassengerId": 120,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Andersson, Miss. Ellis Anna Maria",
        "Sex": "female",
        "Age": 2,
        "SibSp": 4,
        "Parch": 2,
        "Ticket": 347082,
        "Fare": 31.275,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 121,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Hickman, Mr. Stanley George",
        "Sex": "male",
        "Age": 21,
        "SibSp": 2,
        "Parch": 0,
        "Ticket": "S.O.C. 14879",
        "Fare": 73.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 122,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Moore, Mr. Leonard Charles",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A4. 54510",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 123,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Nasser, Mr. Nicholas",
        "Sex": "male",
        "Age": 32.5,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 237736,
        "Fare": 30.0708,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 124,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Webber, Miss. Susan",
        "Sex": "female",
        "Age": 32.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 27267,
        "Fare": 13,
        "Cabin": "E101",
        "Embarked": "S"
    },
    {
        "PassengerId": 125,
        "Survived": 0,
        "Pclass": 1,
        "Name": "White, Mr. Percival Wayland",
        "Sex": "male",
        "Age": 54,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 35281,
        "Fare": 77.2875,
        "Cabin": "D26",
        "Embarked": "S"
    },
    {
        "PassengerId": 126,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Nicola-Yarred, Master. Elias",
        "Sex": "male",
        "Age": 12,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 2651,
        "Fare": 11.2417,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 127,
        "Survived": 0,
        "Pclass": 3,
        "Name": "McMahon, Mr. Martin",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 370372,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 128,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Madsen, Mr. Fridtjof Arne",
        "Sex": "male",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C 17369",
        "Fare": 7.1417,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 129,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Peter, Miss. Anna",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 2668,
        "Fare": 22.3583,
        "Cabin": "F E69",
        "Embarked": "C"
    },
    {
        "PassengerId": 130,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Ekstrom, Mr. Johan",
        "Sex": "male",
        "Age": 45,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347061,
        "Fare": 6.975,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 131,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Drazenoic, Mr. Jozef",
        "Sex": "male",
        "Age": 33,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349241,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 132,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Coelho, Mr. Domingos Fernandeo",
        "Sex": "male",
        "Age": 20,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/O.Q. 3101307",
        "Fare": 7.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 133,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Robins, Mrs. Alexander A (Grace Charity Laury)",
        "Sex": "female",
        "Age": 47,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "A/5. 3337",
        "Fare": 14.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 134,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Weisz, Mrs. Leopold (Mathilde Francoise Pede)",
        "Sex": "female",
        "Age": 29,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 228414,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 135,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Sobey, Mr. Samuel James Hayden",
        "Sex": "male",
        "Age": 25,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A. 29178",
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 136,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Richard, Mr. Emile",
        "Sex": "male",
        "Age": 23,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SC/PARIS 2133",
        "Fare": 15.0458,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 137,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Newsom, Miss. Helen Monypeny",
        "Sex": "female",
        "Age": 19,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 11752,
        "Fare": 26.2833,
        "Cabin": "D47",
        "Embarked": "S"
    },
    {
        "PassengerId": 138,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Futrelle, Mr. Jacques Heath",
        "Sex": "male",
        "Age": 37,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 113803,
        "Fare": 53.1,
        "Cabin": "C123",
        "Embarked": "S"
    },
    {
        "PassengerId": 139,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Osen, Mr. Olaf Elon",
        "Sex": "male",
        "Age": 16,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 7534,
        "Fare": 9.2167,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 140,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Giglio, Mr. Victor",
        "Sex": "male",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17593",
        "Fare": 79.2,
        "Cabin": "B86",
        "Embarked": "C"
    },
    {
        "PassengerId": 141,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Boulos, Mrs. Joseph (Sultana)",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 2678,
        "Fare": 15.2458,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 142,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Nysten, Miss. Anna Sofia",
        "Sex": "female",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347081,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 143,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Hakkarainen, Mrs. Pekka Pietari (Elin Matilda Dolck)",
        "Sex": "female",
        "Age": 24,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "STON/O2. 3101279",
        "Fare": 15.85,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 144,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Burke, Mr. Jeremiah",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 365222,
        "Fare": 6.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 145,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Andrew, Mr. Edgardo Samuel",
        "Sex": "male",
        "Age": 18,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 231945,
        "Fare": 11.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 146,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Nicholls, Mr. Joseph Charles",
        "Sex": "male",
        "Age": 19,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "C.A. 33112",
        "Fare": 36.75,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 147,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Andersson, Mr. August Edvard (\"Wennerstrom\")",
        "Sex": "male",
        "Age": 27,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 350043,
        "Fare": 7.7958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 148,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Ford, Miss. Robina Maggie \"Ruby",
        "Sex": "female",
        "Age": 9,
        "SibSp": 2,
        "Parch": 2,
        "Ticket": "W./C. 6608",
        "Fare": 34.375,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 149,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Navratil, Mr. Michel (\"Louis M Hoffman\")",
        "Sex": "male",
        "Age": 36.5,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 230080,
        "Fare": 26,
        "Cabin": "F2",
        "Embarked": "S"
    },
    {
        "PassengerId": 150,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Byles, Rev. Thomas Roussel Davids",
        "Sex": "male",
        "Age": 42,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 244310,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 151,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Bateman, Rev. Robert James",
        "Sex": "male",
        "Age": 51,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "S.O.P. 1166",
        "Fare": 12.525,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 152,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Pears, Mrs. Thomas (Edith Wearne)",
        "Sex": "female",
        "Age": 22,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 113776,
        "Fare": 66.6,
        "Cabin": "C2",
        "Embarked": "S"
    },
    {
        "PassengerId": 153,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Meo, Mr. Alfonzo",
        "Sex": "male",
        "Age": 55.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A.5. 11206",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 154,
        "Survived": 0,
        "Pclass": 3,
        "Name": "van Billiard, Mr. Austin Blyler",
        "Sex": "male",
        "Age": 40.5,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": "A/5. 851",
        "Fare": 14.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 155,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Olsen, Mr. Ole Martin",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "Fa 265302",
        "Fare": 7.3125,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 156,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Williams, Mr. Charles Duane",
        "Sex": "male",
        "Age": 51,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": "PC 17597",
        "Fare": 61.3792,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 157,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Gilnagh, Miss. Katherine \"Katie",
        "Sex": "female",
        "Age": 16,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 35851,
        "Fare": 7.7333,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 158,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Corn, Mr. Harry",
        "Sex": "male",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/OQ 392090",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 159,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Smiljanic, Mr. Mile",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 315037,
        "Fare": 8.6625,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 160,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sage, Master. Thomas Henry",
        "Sex": "male",
        "Age": "",
        "SibSp": 8,
        "Parch": 2,
        "Ticket": "CA. 2343",
        "Fare": 69.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 161,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Cribb, Mr. John Hatfield",
        "Sex": "male",
        "Age": 44,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 371362,
        "Fare": 16.1,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 162,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Watt, Mrs. James (Elizabeth \"Bessie\" Inglis Milne)",
        "Sex": "female",
        "Age": 40,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A. 33595",
        "Fare": 15.75,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 163,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Bengtsson, Mr. John Viktor",
        "Sex": "male",
        "Age": 26,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347068,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 164,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Calic, Mr. Jovo",
        "Sex": "male",
        "Age": 17,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 315093,
        "Fare": 8.6625,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 165,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Panula, Master. Eino Viljami",
        "Sex": "male",
        "Age": 1,
        "SibSp": 4,
        "Parch": 1,
        "Ticket": 3101295,
        "Fare": 39.6875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 166,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Goldsmith, Master. Frank John William \"Frankie",
        "Sex": "male",
        "Age": 9,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 363291,
        "Fare": 20.525,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 167,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Chibnall, Mrs. (Edith Martha Bowerman)",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 113505,
        "Fare": 55,
        "Cabin": "E33",
        "Embarked": "S"
    },
    {
        "PassengerId": 168,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Skoog, Mrs. William (Anna Bernhardina Karlsson)",
        "Sex": "female",
        "Age": 45,
        "SibSp": 1,
        "Parch": 4,
        "Ticket": 347088,
        "Fare": 27.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 169,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Baumann, Mr. John D",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17318",
        "Fare": 25.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 170,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Ling, Mr. Lee",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 1601,
        "Fare": 56.4958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 171,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Van der hoef, Mr. Wyckoff",
        "Sex": "male",
        "Age": 61,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 111240,
        "Fare": 33.5,
        "Cabin": "B19",
        "Embarked": "S"
    },
    {
        "PassengerId": 172,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Rice, Master. Arthur",
        "Sex": "male",
        "Age": 4,
        "SibSp": 4,
        "Parch": 1,
        "Ticket": 382652,
        "Fare": 29.125,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 173,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Johnson, Miss. Eleanor Ileen",
        "Sex": "female",
        "Age": 1,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 347742,
        "Fare": 11.1333,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 174,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sivola, Mr. Antti Wilhelm",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O 2. 3101280",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 175,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Smith, Mr. James Clinch",
        "Sex": "male",
        "Age": 56,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 17764,
        "Fare": 30.6958,
        "Cabin": "A7",
        "Embarked": "C"
    },
    {
        "PassengerId": 176,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Klasen, Mr. Klas Albin",
        "Sex": "male",
        "Age": 18,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 350404,
        "Fare": 7.8542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 177,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lefebre, Master. Henry Forbes",
        "Sex": "male",
        "Age": "",
        "SibSp": 3,
        "Parch": 1,
        "Ticket": 4133,
        "Fare": 25.4667,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 178,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Isham, Miss. Ann Elizabeth",
        "Sex": "female",
        "Age": 50,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17595",
        "Fare": 28.7125,
        "Cabin": "C49",
        "Embarked": "C"
    },
    {
        "PassengerId": 179,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Hale, Mr. Reginald",
        "Sex": "male",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 250653,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 180,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Leonard, Mr. Lionel",
        "Sex": "male",
        "Age": 36,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "LINE",
        "Fare": 0,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 181,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sage, Miss. Constance Gladys",
        "Sex": "female",
        "Age": "",
        "SibSp": 8,
        "Parch": 2,
        "Ticket": "CA. 2343",
        "Fare": 69.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 182,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Pernot, Mr. Rene",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SC/PARIS 2131",
        "Fare": 15.05,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 183,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Asplund, Master. Clarence Gustaf Hugo",
        "Sex": "male",
        "Age": 9,
        "SibSp": 4,
        "Parch": 2,
        "Ticket": 347077,
        "Fare": 31.3875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 184,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Becker, Master. Richard F",
        "Sex": "male",
        "Age": 1,
        "SibSp": 2,
        "Parch": 1,
        "Ticket": 230136,
        "Fare": 39,
        "Cabin": "F4",
        "Embarked": "S"
    },
    {
        "PassengerId": 185,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Kink-Heilmann, Miss. Luise Gretchen",
        "Sex": "female",
        "Age": 4,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 315153,
        "Fare": 22.025,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 186,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Rood, Mr. Hugh Roscoe",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113767,
        "Fare": 50,
        "Cabin": "A32",
        "Embarked": "S"
    },
    {
        "PassengerId": 187,
        "Survived": 1,
        "Pclass": 3,
        "Name": "O'Brien, Mrs. Thomas (Johanna \"Hannah\" Godfrey)",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 370365,
        "Fare": 15.5,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 188,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Romaine, Mr. Charles Hallace (\"Mr C Rolmane\")",
        "Sex": "male",
        "Age": 45,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 111428,
        "Fare": 26.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 189,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Bourke, Mr. John",
        "Sex": "male",
        "Age": 40,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 364849,
        "Fare": 15.5,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 190,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Turcin, Mr. Stjepan",
        "Sex": "male",
        "Age": 36,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349247,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 191,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Pinsky, Mrs. (Rosa)",
        "Sex": "female",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 234604,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 192,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Carbines, Mr. William",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 28424,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 193,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Andersen-Jensen, Miss. Carla Christine Nielsine",
        "Sex": "female",
        "Age": 19,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 350046,
        "Fare": 7.8542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 194,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Navratil, Master. Michel M",
        "Sex": "male",
        "Age": 3,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 230080,
        "Fare": 26,
        "Cabin": "F2",
        "Embarked": "S"
    },
    {
        "PassengerId": 195,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Brown, Mrs. James Joseph (Margaret Tobin)",
        "Sex": "female",
        "Age": 44,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17610",
        "Fare": 27.7208,
        "Cabin": "B4",
        "Embarked": "C"
    },
    {
        "PassengerId": 196,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Lurette, Miss. Elise",
        "Sex": "female",
        "Age": 58,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17569",
        "Fare": 146.5208,
        "Cabin": "B80",
        "Embarked": "C"
    },
    {
        "PassengerId": 197,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Mernagh, Mr. Robert",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 368703,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 198,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Olsen, Mr. Karl Siegwart Andreas",
        "Sex": "male",
        "Age": 42,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 4579,
        "Fare": 8.4042,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 199,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Madigan, Miss. Margaret \"Maggie",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 370370,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 200,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Yrois, Miss. Henriette (\"Mrs Harbeck\")",
        "Sex": "female",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 248747,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 201,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Vande Walle, Mr. Nestor Cyriel",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 345770,
        "Fare": 9.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 202,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sage, Mr. Frederick",
        "Sex": "male",
        "Age": "",
        "SibSp": 8,
        "Parch": 2,
        "Ticket": "CA. 2343",
        "Fare": 69.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 203,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Johanson, Mr. Jakob Alfred",
        "Sex": "male",
        "Age": 34,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 3101264,
        "Fare": 6.4958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 204,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Youseff, Mr. Gerious",
        "Sex": "male",
        "Age": 45.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2628,
        "Fare": 7.225,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 205,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Cohen, Mr. Gurshon \"Gus",
        "Sex": "male",
        "Age": 18,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/5 3540",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 206,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Strom, Miss. Telma Matilda",
        "Sex": "female",
        "Age": 2,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 347054,
        "Fare": 10.4625,
        "Cabin": "G6",
        "Embarked": "S"
    },
    {
        "PassengerId": 207,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Backstrom, Mr. Karl Alfred",
        "Sex": "male",
        "Age": 32,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 3101278,
        "Fare": 15.85,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 208,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Albimona, Mr. Nassef Cassem",
        "Sex": "male",
        "Age": 26,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2699,
        "Fare": 18.7875,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 209,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Carr, Miss. Helen \"Ellen",
        "Sex": "female",
        "Age": 16,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 367231,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 210,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Blank, Mr. Henry",
        "Sex": "male",
        "Age": 40,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 112277,
        "Fare": 31,
        "Cabin": "A31",
        "Embarked": "C"
    },
    {
        "PassengerId": 211,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Ali, Mr. Ahmed",
        "Sex": "male",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/O.Q. 3101311",
        "Fare": 7.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 212,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Cameron, Miss. Clear Annie",
        "Sex": "female",
        "Age": 35,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "F.C.C. 13528",
        "Fare": 21,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 213,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Perkin, Mr. John Henry",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/5 21174",
        "Fare": 7.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 214,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Givard, Mr. Hans Kristensen",
        "Sex": "male",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 250646,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 215,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Kiernan, Mr. Philip",
        "Sex": "male",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 367229,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 216,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Newell, Miss. Madeleine",
        "Sex": "female",
        "Age": 31,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 35273,
        "Fare": 113.275,
        "Cabin": "D36",
        "Embarked": "C"
    },
    {
        "PassengerId": 217,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Honkanen, Miss. Eliina",
        "Sex": "female",
        "Age": 27,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O2. 3101283",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 218,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Jacobsohn, Mr. Sidney Samuel",
        "Sex": "male",
        "Age": 42,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 243847,
        "Fare": 27,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 219,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Bazzani, Miss. Albina",
        "Sex": "female",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 11813,
        "Fare": 76.2917,
        "Cabin": "D15",
        "Embarked": "C"
    },
    {
        "PassengerId": 220,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Harris, Mr. Walter",
        "Sex": "male",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "W/C 14208",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 221,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Sunderland, Mr. Victor Francis",
        "Sex": "male",
        "Age": 16,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/OQ 392089",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 222,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Bracken, Mr. James H",
        "Sex": "male",
        "Age": 27,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 220367,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 223,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Green, Mr. George Henry",
        "Sex": "male",
        "Age": 51,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 21440,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 224,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Nenkoff, Mr. Christo",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349234,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 225,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Hoyt, Mr. Frederick Maxfield",
        "Sex": "male",
        "Age": 38,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 19943,
        "Fare": 90,
        "Cabin": "C93",
        "Embarked": "S"
    },
    {
        "PassengerId": 226,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Berglund, Mr. Karl Ivar Sven",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PP 4348",
        "Fare": 9.35,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 227,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Mellors, Mr. William John",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SW/PP 751",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 228,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lovell, Mr. John Hall (\"Henry\")",
        "Sex": "male",
        "Age": 20.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/5 21173",
        "Fare": 7.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 229,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Fahlstrom, Mr. Arne Jonas",
        "Sex": "male",
        "Age": 18,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 236171,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 230,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lefebre, Miss. Mathilde",
        "Sex": "female",
        "Age": "",
        "SibSp": 3,
        "Parch": 1,
        "Ticket": 4133,
        "Fare": 25.4667,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 231,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Harris, Mrs. Henry Birkhardt (Irene Wallach)",
        "Sex": "female",
        "Age": 35,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 36973,
        "Fare": 83.475,
        "Cabin": "C83",
        "Embarked": "S"
    },
    {
        "PassengerId": 232,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Larsson, Mr. Bengt Edvin",
        "Sex": "male",
        "Age": 29,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347067,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 233,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Sjostedt, Mr. Ernst Adolf",
        "Sex": "male",
        "Age": 59,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 237442,
        "Fare": 13.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 234,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Asplund, Miss. Lillian Gertrud",
        "Sex": "female",
        "Age": 5,
        "SibSp": 4,
        "Parch": 2,
        "Ticket": 347077,
        "Fare": 31.3875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 235,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Leyson, Mr. Robert William Norman",
        "Sex": "male",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A. 29566",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 236,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Harknett, Miss. Alice Phoebe",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "W./C. 6609",
        "Fare": 7.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 237,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Hold, Mr. Stephen",
        "Sex": "male",
        "Age": 44,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 26707,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 238,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Collyer, Miss. Marjorie \"Lottie",
        "Sex": "female",
        "Age": 8,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": "C.A. 31921",
        "Fare": 26.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 239,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Pengelly, Mr. Frederick William",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 28665,
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 240,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Hunt, Mr. George Henry",
        "Sex": "male",
        "Age": 33,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SCO/W 1585",
        "Fare": 12.275,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 241,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Zabour, Miss. Thamine",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 2665,
        "Fare": 14.4542,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 242,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Murphy, Miss. Katherine \"Kate",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 367230,
        "Fare": 15.5,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 243,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Coleridge, Mr. Reginald Charles",
        "Sex": "male",
        "Age": 29,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "W./C. 14263",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 244,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Maenpaa, Mr. Matti Alexanteri",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O 2. 3101275",
        "Fare": 7.125,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 245,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Attalah, Mr. Sleiman",
        "Sex": "male",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2694,
        "Fare": 7.225,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 246,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Minahan, Dr. William Edward",
        "Sex": "male",
        "Age": 44,
        "SibSp": 2,
        "Parch": 0,
        "Ticket": 19928,
        "Fare": 90,
        "Cabin": "C78",
        "Embarked": "Q"
    },
    {
        "PassengerId": 247,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lindahl, Miss. Agda Thorilda Viktoria",
        "Sex": "female",
        "Age": 25,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347071,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 248,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Hamalainen, Mrs. William (Anna)",
        "Sex": "female",
        "Age": 24,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 250649,
        "Fare": 14.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 249,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Beckwith, Mr. Richard Leonard",
        "Sex": "male",
        "Age": 37,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 11751,
        "Fare": 52.5542,
        "Cabin": "D35",
        "Embarked": "S"
    },
    {
        "PassengerId": 250,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Carter, Rev. Ernest Courtenay",
        "Sex": "male",
        "Age": 54,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 244252,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 251,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Reed, Mr. James George",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 362316,
        "Fare": 7.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 252,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Strom, Mrs. Wilhelm (Elna Matilda Persson)",
        "Sex": "female",
        "Age": 29,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 347054,
        "Fare": 10.4625,
        "Cabin": "G6",
        "Embarked": "S"
    },
    {
        "PassengerId": 253,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Stead, Mr. William Thomas",
        "Sex": "male",
        "Age": 62,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113514,
        "Fare": 26.55,
        "Cabin": "C87",
        "Embarked": "S"
    },
    {
        "PassengerId": 254,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lobb, Mr. William Arthur",
        "Sex": "male",
        "Age": 30,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "A/5. 3336",
        "Fare": 16.1,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 255,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Rosblom, Mrs. Viktor (Helena Wilhelmina)",
        "Sex": "female",
        "Age": 41,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 370129,
        "Fare": 20.2125,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 256,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Touma, Mrs. Darwis (Hanne Youssef Razi)",
        "Sex": "female",
        "Age": 29,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 2650,
        "Fare": 15.2458,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 257,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Thorne, Mrs. Gertrude Maybelle",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17585",
        "Fare": 79.2,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 258,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Cherry, Miss. Gladys",
        "Sex": "female",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 110152,
        "Fare": 86.5,
        "Cabin": "B77",
        "Embarked": "S"
    },
    {
        "PassengerId": 259,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Ward, Miss. Anna",
        "Sex": "female",
        "Age": 35,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17755",
        "Fare": 512.3292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 260,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Parrish, Mrs. (Lutie Davis)",
        "Sex": "female",
        "Age": 50,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 230433,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 261,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Smith, Mr. Thomas",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 384461,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 262,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Asplund, Master. Edvin Rojj Felix",
        "Sex": "male",
        "Age": 3,
        "SibSp": 4,
        "Parch": 2,
        "Ticket": 347077,
        "Fare": 31.3875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 263,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Taussig, Mr. Emil",
        "Sex": "male",
        "Age": 52,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 110413,
        "Fare": 79.65,
        "Cabin": "E67",
        "Embarked": "S"
    },
    {
        "PassengerId": 264,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Harrison, Mr. William",
        "Sex": "male",
        "Age": 40,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 112059,
        "Fare": 0,
        "Cabin": "B94",
        "Embarked": "S"
    },
    {
        "PassengerId": 265,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Henry, Miss. Delia",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 382649,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 266,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Reeves, Mr. David",
        "Sex": "male",
        "Age": 36,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A. 17248",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 267,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Panula, Mr. Ernesti Arvid",
        "Sex": "male",
        "Age": 16,
        "SibSp": 4,
        "Parch": 1,
        "Ticket": 3101295,
        "Fare": 39.6875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 268,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Persson, Mr. Ernst Ulrik",
        "Sex": "male",
        "Age": 25,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 347083,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 269,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Graham, Mrs. William Thompson (Edith Junkins)",
        "Sex": "female",
        "Age": 58,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": "PC 17582",
        "Fare": 153.4625,
        "Cabin": "C125",
        "Embarked": "S"
    },
    {
        "PassengerId": 270,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Bissette, Miss. Amelia",
        "Sex": "female",
        "Age": 35,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17760",
        "Fare": 135.6333,
        "Cabin": "C99",
        "Embarked": "S"
    },
    {
        "PassengerId": 271,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Cairns, Mr. Alexander",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113798,
        "Fare": 31,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 272,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Tornquist, Mr. William Henry",
        "Sex": "male",
        "Age": 25,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "LINE",
        "Fare": 0,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 273,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Mellinger, Mrs. (Elizabeth Anne Maidment)",
        "Sex": "female",
        "Age": 41,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 250644,
        "Fare": 19.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 274,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Natsch, Mr. Charles H",
        "Sex": "male",
        "Age": 37,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": "PC 17596",
        "Fare": 29.7,
        "Cabin": "C118",
        "Embarked": "C"
    },
    {
        "PassengerId": 275,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Healy, Miss. Hanora \"Nora",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 370375,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 276,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Andrews, Miss. Kornelia Theodosia",
        "Sex": "female",
        "Age": 63,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 13502,
        "Fare": 77.9583,
        "Cabin": "D7",
        "Embarked": "S"
    },
    {
        "PassengerId": 277,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lindblom, Miss. Augusta Charlotta",
        "Sex": "female",
        "Age": 45,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347073,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 278,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Parkes, Mr. Francis \"Frank",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 239853,
        "Fare": 0,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 279,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Rice, Master. Eric",
        "Sex": "male",
        "Age": 7,
        "SibSp": 4,
        "Parch": 1,
        "Ticket": 382652,
        "Fare": 29.125,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 280,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Abbott, Mrs. Stanton (Rosa Hunt)",
        "Sex": "female",
        "Age": 35,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "C.A. 2673",
        "Fare": 20.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 281,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Duane, Mr. Frank",
        "Sex": "male",
        "Age": 65,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 336439,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 282,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Olsson, Mr. Nils Johan Goransson",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347464,
        "Fare": 7.8542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 283,
        "Survived": 0,
        "Pclass": 3,
        "Name": "de Pelsmaeker, Mr. Alfons",
        "Sex": "male",
        "Age": 16,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 345778,
        "Fare": 9.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 284,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Dorking, Mr. Edward Arthur",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/5. 10482",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 285,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Smith, Mr. Richard William",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113056,
        "Fare": 26,
        "Cabin": "A19",
        "Embarked": "S"
    },
    {
        "PassengerId": 286,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Stankovic, Mr. Ivan",
        "Sex": "male",
        "Age": 33,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349239,
        "Fare": 8.6625,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 287,
        "Survived": 1,
        "Pclass": 3,
        "Name": "de Mulder, Mr. Theodore",
        "Sex": "male",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 345774,
        "Fare": 9.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 288,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Naidenoff, Mr. Penko",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349206,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 289,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Hosono, Mr. Masabumi",
        "Sex": "male",
        "Age": 42,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 237798,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 290,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Connolly, Miss. Kate",
        "Sex": "female",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 370373,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 291,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Barber, Miss. Ellen \"Nellie",
        "Sex": "female",
        "Age": 26,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 19877,
        "Fare": 78.85,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 292,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Bishop, Mrs. Dickinson H (Helen Walton)",
        "Sex": "female",
        "Age": 19,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 11967,
        "Fare": 91.0792,
        "Cabin": "B49",
        "Embarked": "C"
    },
    {
        "PassengerId": 293,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Levy, Mr. Rene Jacques",
        "Sex": "male",
        "Age": 36,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SC/Paris 2163",
        "Fare": 12.875,
        "Cabin": "D",
        "Embarked": "C"
    },
    {
        "PassengerId": 294,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Haas, Miss. Aloisia",
        "Sex": "female",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349236,
        "Fare": 8.85,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 295,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Mineff, Mr. Ivan",
        "Sex": "male",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349233,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 296,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Lewy, Mr. Ervin G",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17612",
        "Fare": 27.7208,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 297,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Hanna, Mr. Mansour",
        "Sex": "male",
        "Age": 23.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2693,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 298,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Allison, Miss. Helen Loraine",
        "Sex": "female",
        "Age": 2,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": 113781,
        "Fare": 151.55,
        "Cabin": "C22 C26",
        "Embarked": "S"
    },
    {
        "PassengerId": 299,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Saalfeld, Mr. Adolphe",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 19988,
        "Fare": 30.5,
        "Cabin": "C106",
        "Embarked": "S"
    },
    {
        "PassengerId": 300,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Baxter, Mrs. James (Helene DeLaudeniere Chaput)",
        "Sex": "female",
        "Age": 50,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": "PC 17558",
        "Fare": 247.5208,
        "Cabin": "B58 B60",
        "Embarked": "C"
    },
    {
        "PassengerId": 301,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Kelly, Miss. Anna Katherine \"Annie Kate",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 9234,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 302,
        "Survived": 1,
        "Pclass": 3,
        "Name": "McCoy, Mr. Bernard",
        "Sex": "male",
        "Age": "",
        "SibSp": 2,
        "Parch": 0,
        "Ticket": 367226,
        "Fare": 23.25,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 303,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Johnson, Mr. William Cahoone Jr",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "LINE",
        "Fare": 0,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 304,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Keane, Miss. Nora A",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 226593,
        "Fare": 12.35,
        "Cabin": "E101",
        "Embarked": "Q"
    },
    {
        "PassengerId": 305,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Williams, Mr. Howard Hugh \"Harry",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/5 2466",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 306,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Allison, Master. Hudson Trevor",
        "Sex": "male",
        "Age": 0.92,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": 113781,
        "Fare": 151.55,
        "Cabin": "C22 C26",
        "Embarked": "S"
    },
    {
        "PassengerId": 307,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Fleming, Miss. Margaret",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 17421,
        "Fare": 110.8833,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 308,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Penasco y Castellana, Mrs. Victor de Satode (Maria Josefa Perez de Soto y Vallejo)",
        "Sex": "female",
        "Age": 17,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "PC 17758",
        "Fare": 108.9,
        "Cabin": "C65",
        "Embarked": "C"
    },
    {
        "PassengerId": 309,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Abelson, Mr. Samuel",
        "Sex": "male",
        "Age": 30,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "P/PP 3381",
        "Fare": 24,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 310,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Francatelli, Miss. Laura Mabel",
        "Sex": "female",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17485",
        "Fare": 56.9292,
        "Cabin": "E36",
        "Embarked": "C"
    },
    {
        "PassengerId": 311,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Hays, Miss. Margaret Bechstein",
        "Sex": "female",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 11767,
        "Fare": 83.1583,
        "Cabin": "C54",
        "Embarked": "C"
    },
    {
        "PassengerId": 312,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Ryerson, Miss. Emily Borie",
        "Sex": "female",
        "Age": 18,
        "SibSp": 2,
        "Parch": 2,
        "Ticket": "PC 17608",
        "Fare": 262.375,
        "Cabin": "B57 B59 B63 B66",
        "Embarked": "C"
    },
    {
        "PassengerId": 313,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Lahtinen, Mrs. William (Anna Sylfven)",
        "Sex": "female",
        "Age": 26,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 250651,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 314,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Hendekovic, Mr. Ignjac",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349243,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 315,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Hart, Mr. Benjamin",
        "Sex": "male",
        "Age": 43,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "F.C.C. 13529",
        "Fare": 26.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 316,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Nilsson, Miss. Helmina Josefina",
        "Sex": "female",
        "Age": 26,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347470,
        "Fare": 7.8542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 317,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Kantor, Mrs. Sinai (Miriam Sternin)",
        "Sex": "female",
        "Age": 24,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 244367,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 318,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Moraweck, Dr. Ernest",
        "Sex": "male",
        "Age": 54,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 29011,
        "Fare": 14,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 319,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Wick, Miss. Mary Natalie",
        "Sex": "female",
        "Age": 31,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 36928,
        "Fare": 164.8667,
        "Cabin": "C7",
        "Embarked": "S"
    },
    {
        "PassengerId": 320,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Spedden, Mrs. Frederic Oakley (Margaretta Corning Stone)",
        "Sex": "female",
        "Age": 40,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 16966,
        "Fare": 134.5,
        "Cabin": "E34",
        "Embarked": "C"
    },
    {
        "PassengerId": 321,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Dennis, Mr. Samuel",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/5 21172",
        "Fare": 7.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 322,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Danoff, Mr. Yoto",
        "Sex": "male",
        "Age": 27,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349219,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 323,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Slayter, Miss. Hilda Mary",
        "Sex": "female",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 234818,
        "Fare": 12.35,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 324,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Caldwell, Mrs. Albert Francis (Sylvia Mae Harbaugh)",
        "Sex": "female",
        "Age": 22,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 248738,
        "Fare": 29,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 325,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sage, Mr. George John Jr",
        "Sex": "male",
        "Age": "",
        "SibSp": 8,
        "Parch": 2,
        "Ticket": "CA. 2343",
        "Fare": 69.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 326,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Young, Miss. Marie Grice",
        "Sex": "female",
        "Age": 36,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17760",
        "Fare": 135.6333,
        "Cabin": "C32",
        "Embarked": "C"
    },
    {
        "PassengerId": 327,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Nysveen, Mr. Johan Hansen",
        "Sex": "male",
        "Age": 61,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 345364,
        "Fare": 6.2375,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 328,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Ball, Mrs. (Ada E Hall)",
        "Sex": "female",
        "Age": 36,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 28551,
        "Fare": 13,
        "Cabin": "D",
        "Embarked": "S"
    },
    {
        "PassengerId": 329,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Goldsmith, Mrs. Frank John (Emily Alice Brown)",
        "Sex": "female",
        "Age": 31,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 363291,
        "Fare": 20.525,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 330,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Hippach, Miss. Jean Gertrude",
        "Sex": "female",
        "Age": 16,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 111361,
        "Fare": 57.9792,
        "Cabin": "B18",
        "Embarked": "C"
    },
    {
        "PassengerId": 331,
        "Survived": 1,
        "Pclass": 3,
        "Name": "McCoy, Miss. Agnes",
        "Sex": "female",
        "Age": "",
        "SibSp": 2,
        "Parch": 0,
        "Ticket": 367226,
        "Fare": 23.25,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 332,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Partner, Mr. Austen",
        "Sex": "male",
        "Age": 45.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113043,
        "Fare": 28.5,
        "Cabin": "C124",
        "Embarked": "S"
    },
    {
        "PassengerId": 333,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Graham, Mr. George Edward",
        "Sex": "male",
        "Age": 38,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": "PC 17582",
        "Fare": 153.4625,
        "Cabin": "C91",
        "Embarked": "S"
    },
    {
        "PassengerId": 334,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Vander Planke, Mr. Leo Edmondus",
        "Sex": "male",
        "Age": 16,
        "SibSp": 2,
        "Parch": 0,
        "Ticket": 345764,
        "Fare": 18,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 335,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Frauenthal, Mrs. Henry William (Clara Heinsheimer)",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "PC 17611",
        "Fare": 133.65,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 336,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Denkoff, Mr. Mitto",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349225,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 337,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Pears, Mr. Thomas Clinton",
        "Sex": "male",
        "Age": 29,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 113776,
        "Fare": 66.6,
        "Cabin": "C2",
        "Embarked": "S"
    },
    {
        "PassengerId": 338,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Burns, Miss. Elizabeth Margaret",
        "Sex": "female",
        "Age": 41,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 16966,
        "Fare": 134.5,
        "Cabin": "E40",
        "Embarked": "C"
    },
    {
        "PassengerId": 339,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Dahl, Mr. Karl Edwart",
        "Sex": "male",
        "Age": 45,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 7598,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 340,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Blackwell, Mr. Stephen Weart",
        "Sex": "male",
        "Age": 45,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113784,
        "Fare": 35.5,
        "Cabin": "T",
        "Embarked": "S"
    },
    {
        "PassengerId": 341,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Navratil, Master. Edmond Roger",
        "Sex": "male",
        "Age": 2,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 230080,
        "Fare": 26,
        "Cabin": "F2",
        "Embarked": "S"
    },
    {
        "PassengerId": 342,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Fortune, Miss. Alice Elizabeth",
        "Sex": "female",
        "Age": 24,
        "SibSp": 3,
        "Parch": 2,
        "Ticket": 19950,
        "Fare": 263,
        "Cabin": "C23 C25 C27",
        "Embarked": "S"
    },
    {
        "PassengerId": 343,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Collander, Mr. Erik Gustaf",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 248740,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 344,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Sedgwick, Mr. Charles Frederick Waddington",
        "Sex": "male",
        "Age": 25,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 244361,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 345,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Fox, Mr. Stanley Hubert",
        "Sex": "male",
        "Age": 36,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 229236,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 346,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Brown, Miss. Amelia \"Mildred",
        "Sex": "female",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 248733,
        "Fare": 13,
        "Cabin": "F33",
        "Embarked": "S"
    },
    {
        "PassengerId": 347,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Smith, Miss. Marion Elsie",
        "Sex": "female",
        "Age": 40,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 31418,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 348,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Davison, Mrs. Thomas Henry (Mary E Finck)",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 386525,
        "Fare": 16.1,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 349,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Coutts, Master. William Loch \"William",
        "Sex": "male",
        "Age": 3,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "C.A. 37671",
        "Fare": 15.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 350,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Dimic, Mr. Jovan",
        "Sex": "male",
        "Age": 42,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 315088,
        "Fare": 8.6625,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 351,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Odahl, Mr. Nils Martin",
        "Sex": "male",
        "Age": 23,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 7267,
        "Fare": 9.225,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 352,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Williams-Lambert, Mr. Fletcher Fellows",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113510,
        "Fare": 35,
        "Cabin": "C128",
        "Embarked": "S"
    },
    {
        "PassengerId": 353,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Elias, Mr. Tannous",
        "Sex": "male",
        "Age": 15,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 2695,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 354,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Arnold-Franchi, Mr. Josef",
        "Sex": "male",
        "Age": 25,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 349237,
        "Fare": 17.8,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 355,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Yousif, Mr. Wazli",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2647,
        "Fare": 7.225,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 356,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Vanden Steen, Mr. Leo Peter",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 345783,
        "Fare": 9.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 357,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Bowerman, Miss. Elsie Edith",
        "Sex": "female",
        "Age": 22,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 113505,
        "Fare": 55,
        "Cabin": "E33",
        "Embarked": "S"
    },
    {
        "PassengerId": 358,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Funk, Miss. Annie Clemmer",
        "Sex": "female",
        "Age": 38,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 237671,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 359,
        "Survived": 1,
        "Pclass": 3,
        "Name": "McGovern, Miss. Mary",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 330931,
        "Fare": 7.8792,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 360,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Mockler, Miss. Helen Mary \"Ellie",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 330980,
        "Fare": 7.8792,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 361,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Skoog, Mr. Wilhelm",
        "Sex": "male",
        "Age": 40,
        "SibSp": 1,
        "Parch": 4,
        "Ticket": 347088,
        "Fare": 27.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 362,
        "Survived": 0,
        "Pclass": 2,
        "Name": "del Carlo, Mr. Sebastiano",
        "Sex": "male",
        "Age": 29,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "SC/PARIS 2167",
        "Fare": 27.7208,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 363,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Barbara, Mrs. (Catherine David)",
        "Sex": "female",
        "Age": 45,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 2691,
        "Fare": 14.4542,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 364,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Asim, Mr. Adola",
        "Sex": "male",
        "Age": 35,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/O.Q. 3101310",
        "Fare": 7.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 365,
        "Survived": 0,
        "Pclass": 3,
        "Name": "O'Brien, Mr. Thomas",
        "Sex": "male",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 370365,
        "Fare": 15.5,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 366,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Adahl, Mr. Mauritz Nils Martin",
        "Sex": "male",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C 7076",
        "Fare": 7.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 367,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Warren, Mrs. Frank Manley (Anna Sophia Atkinson)",
        "Sex": "female",
        "Age": 60,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 110813,
        "Fare": 75.25,
        "Cabin": "D37",
        "Embarked": "C"
    },
    {
        "PassengerId": 368,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Moussa, Mrs. (Mantoura Boulos)",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2626,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 369,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Jermyn, Miss. Annie",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 14313,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 370,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Aubart, Mme. Leontine Pauline",
        "Sex": "female",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17477",
        "Fare": 69.3,
        "Cabin": "B35",
        "Embarked": "C"
    },
    {
        "PassengerId": 371,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Harder, Mr. George Achilles",
        "Sex": "male",
        "Age": 25,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 11765,
        "Fare": 55.4417,
        "Cabin": "E50",
        "Embarked": "C"
    },
    {
        "PassengerId": 372,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Wiklund, Mr. Jakob Alfred",
        "Sex": "male",
        "Age": 18,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 3101267,
        "Fare": 6.4958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 373,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Beavan, Mr. William Thomas",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 323951,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 374,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Ringhini, Mr. Sante",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17760",
        "Fare": 135.6333,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 375,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Palsson, Miss. Stina Viola",
        "Sex": "female",
        "Age": 3,
        "SibSp": 3,
        "Parch": 1,
        "Ticket": 349909,
        "Fare": 21.075,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 376,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Meyer, Mrs. Edgar Joseph (Leila Saks)",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "PC 17604",
        "Fare": 82.1708,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 377,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Landergren, Miss. Aurora Adelia",
        "Sex": "female",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C 7077",
        "Fare": 7.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 378,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Widener, Mr. Harry Elkins",
        "Sex": "male",
        "Age": 27,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 113503,
        "Fare": 211.5,
        "Cabin": "C82",
        "Embarked": "C"
    },
    {
        "PassengerId": 379,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Betros, Mr. Tannous",
        "Sex": "male",
        "Age": 20,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2648,
        "Fare": 4.0125,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 380,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Gustafsson, Mr. Karl Gideon",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347069,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 381,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Bidois, Miss. Rosalie",
        "Sex": "female",
        "Age": 42,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17757",
        "Fare": 227.525,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 382,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Nakid, Miss. Maria (\"Mary\")",
        "Sex": "female",
        "Age": 1,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 2653,
        "Fare": 15.7417,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 383,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Tikkanen, Mr. Juho",
        "Sex": "male",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O 2. 3101293",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 384,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Holverson, Mrs. Alexander Oskar (Mary Aline Towner)",
        "Sex": "female",
        "Age": 35,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 113789,
        "Fare": 52,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 385,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Plotcharsky, Mr. Vasil",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349227,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 386,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Davies, Mr. Charles Henry",
        "Sex": "male",
        "Age": 18,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "S.O.C. 14879",
        "Fare": 73.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 387,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Goodwin, Master. Sidney Leonard",
        "Sex": "male",
        "Age": 1,
        "SibSp": 5,
        "Parch": 2,
        "Ticket": "CA 2144",
        "Fare": 46.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 388,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Buss, Miss. Kate",
        "Sex": "female",
        "Age": 36,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 27849,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 389,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sadlier, Mr. Matthew",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 367655,
        "Fare": 7.7292,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 390,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Lehmann, Miss. Bertha",
        "Sex": "female",
        "Age": 17,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SC 1748",
        "Fare": 12,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 391,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Carter, Mr. William Ernest",
        "Sex": "male",
        "Age": 36,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": 113760,
        "Fare": 120,
        "Cabin": "B96 B98",
        "Embarked": "S"
    },
    {
        "PassengerId": 392,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Jansson, Mr. Carl Olof",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 350034,
        "Fare": 7.7958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 393,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Gustafsson, Mr. Johan Birger",
        "Sex": "male",
        "Age": 28,
        "SibSp": 2,
        "Parch": 0,
        "Ticket": 3101277,
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 394,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Newell, Miss. Marjorie",
        "Sex": "female",
        "Age": 23,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 35273,
        "Fare": 113.275,
        "Cabin": "D36",
        "Embarked": "C"
    },
    {
        "PassengerId": 395,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Sandstrom, Mrs. Hjalmar (Agnes Charlotta Bengtsson)",
        "Sex": "female",
        "Age": 24,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": "PP 9549",
        "Fare": 16.7,
        "Cabin": "G6",
        "Embarked": "S"
    },
    {
        "PassengerId": 396,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Johansson, Mr. Erik",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 350052,
        "Fare": 7.7958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 397,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Olsson, Miss. Elina",
        "Sex": "female",
        "Age": 31,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 350407,
        "Fare": 7.8542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 398,
        "Survived": 0,
        "Pclass": 2,
        "Name": "McKane, Mr. Peter David",
        "Sex": "male",
        "Age": 46,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 28403,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 399,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Pain, Dr. Alfred",
        "Sex": "male",
        "Age": 23,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 244278,
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 400,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Trout, Mrs. William H (Jessie L)",
        "Sex": "female",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 240929,
        "Fare": 12.65,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 401,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Niskanen, Mr. Juha",
        "Sex": "male",
        "Age": 39,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O 2. 3101289",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 402,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Adams, Mr. John",
        "Sex": "male",
        "Age": 26,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 341826,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 403,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Jussila, Miss. Mari Aina",
        "Sex": "female",
        "Age": 21,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 4137,
        "Fare": 9.825,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 404,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Hakkarainen, Mr. Pekka Pietari",
        "Sex": "male",
        "Age": 28,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "STON/O2. 3101279",
        "Fare": 15.85,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 405,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Oreskovic, Miss. Marija",
        "Sex": "female",
        "Age": 20,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 315096,
        "Fare": 8.6625,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 406,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Gale, Mr. Shadrach",
        "Sex": "male",
        "Age": 34,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 28664,
        "Fare": 21,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 407,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Widegren, Mr. Carl/Charles Peter",
        "Sex": "male",
        "Age": 51,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347064,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 408,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Richards, Master. William Rowe",
        "Sex": "male",
        "Age": 3,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 29106,
        "Fare": 18.75,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 409,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Birkeland, Mr. Hans Martin Monsen",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 312992,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 410,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lefebre, Miss. Ida",
        "Sex": "female",
        "Age": "",
        "SibSp": 3,
        "Parch": 1,
        "Ticket": 4133,
        "Fare": 25.4667,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 411,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sdycoff, Mr. Todor",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349222,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 412,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Hart, Mr. Henry",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 394140,
        "Fare": 6.8583,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 413,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Minahan, Miss. Daisy E",
        "Sex": "female",
        "Age": 33,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 19928,
        "Fare": 90,
        "Cabin": "C78",
        "Embarked": "Q"
    },
    {
        "PassengerId": 414,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Cunningham, Mr. Alfred Fleming",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 239853,
        "Fare": 0,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 415,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Sundman, Mr. Johan Julian",
        "Sex": "male",
        "Age": 44,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O 2. 3101269",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 416,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Meek, Mrs. Thomas (Annie Louise Rowley)",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 343095,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 417,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Drew, Mrs. James Vivian (Lulu Thorne Christian)",
        "Sex": "female",
        "Age": 34,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 28220,
        "Fare": 32.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 418,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Silven, Miss. Lyyli Karoliina",
        "Sex": "female",
        "Age": 18,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 250652,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 419,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Matthews, Mr. William John",
        "Sex": "male",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 28228,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 420,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Van Impe, Miss. Catharina",
        "Sex": "female",
        "Age": 10,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 345773,
        "Fare": 24.15,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 421,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Gheorgheff, Mr. Stanio",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349254,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 422,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Charters, Mr. David",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/5. 13032",
        "Fare": 7.7333,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 423,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Zimmerman, Mr. Leo",
        "Sex": "male",
        "Age": 29,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 315082,
        "Fare": 7.875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 424,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Danbom, Mrs. Ernst Gilbert (Anna Sigrid Maria Brogren)",
        "Sex": "female",
        "Age": 28,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 347080,
        "Fare": 14.4,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 425,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Rosblom, Mr. Viktor Richard",
        "Sex": "male",
        "Age": 18,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 370129,
        "Fare": 20.2125,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 426,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Wiseman, Mr. Phillippe",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/4. 34244",
        "Fare": 7.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 427,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Clarke, Mrs. Charles V (Ada Maria Winfield)",
        "Sex": "female",
        "Age": 28,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 2003,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 428,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Phillips, Miss. Kate Florence (\"Mrs Kate Louise Phillips Marshall\")",
        "Sex": "female",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 250655,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 429,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Flynn, Mr. James",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 364851,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 430,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Pickard, Mr. Berk (Berk Trembisky)",
        "Sex": "male",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/O.Q. 392078",
        "Fare": 8.05,
        "Cabin": "E10",
        "Embarked": "S"
    },
    {
        "PassengerId": 431,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Bjornstrom-Steffansson, Mr. Mauritz Hakan",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 110564,
        "Fare": 26.55,
        "Cabin": "C52",
        "Embarked": "S"
    },
    {
        "PassengerId": 432,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Thorneycroft, Mrs. Percival (Florence Kate White)",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 376564,
        "Fare": 16.1,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 433,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Louch, Mrs. Charles Alexander (Alice Adelaide Slow)",
        "Sex": "female",
        "Age": 42,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "SC/AH 3085",
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 434,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Kallio, Mr. Nikolai Erland",
        "Sex": "male",
        "Age": 17,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O 2. 3101274",
        "Fare": 7.125,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 435,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Silvey, Mr. William Baird",
        "Sex": "male",
        "Age": 50,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 13507,
        "Fare": 55.9,
        "Cabin": "E44",
        "Embarked": "S"
    },
    {
        "PassengerId": 436,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Carter, Miss. Lucile Polk",
        "Sex": "female",
        "Age": 14,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": 113760,
        "Fare": 120,
        "Cabin": "B96 B98",
        "Embarked": "S"
    },
    {
        "PassengerId": 437,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Ford, Miss. Doolina Margaret \"Daisy",
        "Sex": "female",
        "Age": 21,
        "SibSp": 2,
        "Parch": 2,
        "Ticket": "W./C. 6608",
        "Fare": 34.375,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 438,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Richards, Mrs. Sidney (Emily Hocking)",
        "Sex": "female",
        "Age": 24,
        "SibSp": 2,
        "Parch": 3,
        "Ticket": 29106,
        "Fare": 18.75,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 439,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Fortune, Mr. Mark",
        "Sex": "male",
        "Age": 64,
        "SibSp": 1,
        "Parch": 4,
        "Ticket": 19950,
        "Fare": 263,
        "Cabin": "C23 C25 C27",
        "Embarked": "S"
    },
    {
        "PassengerId": 440,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Kvillner, Mr. Johan Henrik Johannesson",
        "Sex": "male",
        "Age": 31,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A. 18723",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 441,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Hart, Mrs. Benjamin (Esther Ada Bloomfield)",
        "Sex": "female",
        "Age": 45,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "F.C.C. 13529",
        "Fare": 26.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 442,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Hampe, Mr. Leon",
        "Sex": "male",
        "Age": 20,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 345769,
        "Fare": 9.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 443,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Petterson, Mr. Johan Emil",
        "Sex": "male",
        "Age": 25,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 347076,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 444,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Reynaldo, Ms. Encarnacion",
        "Sex": "female",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 230434,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 445,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Johannesen-Bratthammer, Mr. Bernt",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 65306,
        "Fare": 8.1125,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 446,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Dodge, Master. Washington",
        "Sex": "male",
        "Age": 4,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 33638,
        "Fare": 81.8583,
        "Cabin": "A34",
        "Embarked": "S"
    },
    {
        "PassengerId": 447,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Mellinger, Miss. Madeleine Violet",
        "Sex": "female",
        "Age": 13,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 250644,
        "Fare": 19.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 448,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Seward, Mr. Frederic Kimber",
        "Sex": "male",
        "Age": 34,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113794,
        "Fare": 26.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 449,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Baclini, Miss. Marie Catherine",
        "Sex": "female",
        "Age": 5,
        "SibSp": 2,
        "Parch": 1,
        "Ticket": 2666,
        "Fare": 19.2583,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 450,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Peuchen, Major. Arthur Godfrey",
        "Sex": "male",
        "Age": 52,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113786,
        "Fare": 30.5,
        "Cabin": "C104",
        "Embarked": "S"
    },
    {
        "PassengerId": 451,
        "Survived": 0,
        "Pclass": 2,
        "Name": "West, Mr. Edwy Arthur",
        "Sex": "male",
        "Age": 36,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": "C.A. 34651",
        "Fare": 27.75,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 452,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Hagland, Mr. Ingvald Olai Olsen",
        "Sex": "male",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 65303,
        "Fare": 19.9667,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 453,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Foreman, Mr. Benjamin Laventall",
        "Sex": "male",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113051,
        "Fare": 27.75,
        "Cabin": "C111",
        "Embarked": "C"
    },
    {
        "PassengerId": 454,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Goldenberg, Mr. Samuel L",
        "Sex": "male",
        "Age": 49,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 17453,
        "Fare": 89.1042,
        "Cabin": "C92",
        "Embarked": "C"
    },
    {
        "PassengerId": 455,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Peduzzi, Mr. Joseph",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/5 2817",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 456,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Jalsevac, Mr. Ivan",
        "Sex": "male",
        "Age": 29,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349240,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 457,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Millet, Mr. Francis Davis",
        "Sex": "male",
        "Age": 65,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 13509,
        "Fare": 26.55,
        "Cabin": "E38",
        "Embarked": "S"
    },
    {
        "PassengerId": 458,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Kenyon, Mrs. Frederick R (Marion)",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 17464,
        "Fare": 51.8625,
        "Cabin": "D21",
        "Embarked": "S"
    },
    {
        "PassengerId": 459,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Toomey, Miss. Ellen",
        "Sex": "female",
        "Age": 50,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "F.C.C. 13531",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 460,
        "Survived": 0,
        "Pclass": 3,
        "Name": "O'Connor, Mr. Maurice",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 371060,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 461,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Anderson, Mr. Harry",
        "Sex": "male",
        "Age": 48,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 19952,
        "Fare": 26.55,
        "Cabin": "E12",
        "Embarked": "S"
    },
    {
        "PassengerId": 462,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Morley, Mr. William",
        "Sex": "male",
        "Age": 34,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 364506,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 463,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Gee, Mr. Arthur H",
        "Sex": "male",
        "Age": 47,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 111320,
        "Fare": 38.5,
        "Cabin": "E63",
        "Embarked": "S"
    },
    {
        "PassengerId": 464,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Milling, Mr. Jacob Christian",
        "Sex": "male",
        "Age": 48,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 234360,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 465,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Maisner, Mr. Simon",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/S 2816",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 466,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Goncalves, Mr. Manuel Estanslas",
        "Sex": "male",
        "Age": 38,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/O.Q. 3101306",
        "Fare": 7.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 467,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Campbell, Mr. William",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 239853,
        "Fare": 0,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 468,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Smart, Mr. John Montgomery",
        "Sex": "male",
        "Age": 56,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113792,
        "Fare": 26.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 469,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Scanlan, Mr. James",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 36209,
        "Fare": 7.725,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 470,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Baclini, Miss. Helene Barbara",
        "Sex": "female",
        "Age": 0.75,
        "SibSp": 2,
        "Parch": 1,
        "Ticket": 2666,
        "Fare": 19.2583,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 471,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Keefe, Mr. Arthur",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 323592,
        "Fare": 7.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 472,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Cacic, Mr. Luka",
        "Sex": "male",
        "Age": 38,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 315089,
        "Fare": 8.6625,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 473,
        "Survived": 1,
        "Pclass": 2,
        "Name": "West, Mrs. Edwy Arthur (Ada Mary Worth)",
        "Sex": "female",
        "Age": 33,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": "C.A. 34651",
        "Fare": 27.75,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 474,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Jerwan, Mrs. Amin S (Marie Marthe Thuillard)",
        "Sex": "female",
        "Age": 23,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SC/AH Basle 541",
        "Fare": 13.7917,
        "Cabin": "D",
        "Embarked": "C"
    },
    {
        "PassengerId": 475,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Strandberg, Miss. Ida Sofia",
        "Sex": "female",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 7553,
        "Fare": 9.8375,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 476,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Clifford, Mr. George Quincy",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 110465,
        "Fare": 52,
        "Cabin": "A14",
        "Embarked": "S"
    },
    {
        "PassengerId": 477,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Renouf, Mr. Peter Henry",
        "Sex": "male",
        "Age": 34,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 31027,
        "Fare": 21,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 478,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Braund, Mr. Lewis Richard",
        "Sex": "male",
        "Age": 29,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 3460,
        "Fare": 7.0458,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 479,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Karlsson, Mr. Nils August",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 350060,
        "Fare": 7.5208,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 480,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Hirvonen, Miss. Hildur E",
        "Sex": "female",
        "Age": 2,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 3101298,
        "Fare": 12.2875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 481,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Goodwin, Master. Harold Victor",
        "Sex": "male",
        "Age": 9,
        "SibSp": 5,
        "Parch": 2,
        "Ticket": "CA 2144",
        "Fare": 46.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 482,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Frost, Mr. Anthony Wood \"Archie",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 239854,
        "Fare": 0,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 483,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Rouse, Mr. Richard Henry",
        "Sex": "male",
        "Age": 50,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/5 3594",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 484,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Turkula, Mrs. (Hedwig)",
        "Sex": "female",
        "Age": 63,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 4134,
        "Fare": 9.5875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 485,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Bishop, Mr. Dickinson H",
        "Sex": "male",
        "Age": 25,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 11967,
        "Fare": 91.0792,
        "Cabin": "B49",
        "Embarked": "C"
    },
    {
        "PassengerId": 486,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lefebre, Miss. Jeannie",
        "Sex": "female",
        "Age": "",
        "SibSp": 3,
        "Parch": 1,
        "Ticket": 4133,
        "Fare": 25.4667,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 487,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Hoyt, Mrs. Frederick Maxfield (Jane Anne Forby)",
        "Sex": "female",
        "Age": 35,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 19943,
        "Fare": 90,
        "Cabin": "C93",
        "Embarked": "S"
    },
    {
        "PassengerId": 488,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Kent, Mr. Edward Austin",
        "Sex": "male",
        "Age": 58,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 11771,
        "Fare": 29.7,
        "Cabin": "B37",
        "Embarked": "C"
    },
    {
        "PassengerId": 489,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Somerton, Mr. Francis William",
        "Sex": "male",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A.5. 18509",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 490,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Coutts, Master. Eden Leslie \"Neville",
        "Sex": "male",
        "Age": 9,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "C.A. 37671",
        "Fare": 15.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 491,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Hagland, Mr. Konrad Mathias Reiersen",
        "Sex": "male",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 65304,
        "Fare": 19.9667,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 492,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Windelov, Mr. Einar",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/OQ 3101317",
        "Fare": 7.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 493,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Molson, Mr. Harry Markland",
        "Sex": "male",
        "Age": 55,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113787,
        "Fare": 30.5,
        "Cabin": "C30",
        "Embarked": "S"
    },
    {
        "PassengerId": 494,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Artagaveytia, Mr. Ramon",
        "Sex": "male",
        "Age": 71,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17609",
        "Fare": 49.5042,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 495,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Stanley, Mr. Edward Roland",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/4 45380",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 496,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Yousseff, Mr. Gerious",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2627,
        "Fare": 14.4583,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 497,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Eustis, Miss. Elizabeth Mussey",
        "Sex": "female",
        "Age": 54,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 36947,
        "Fare": 78.2667,
        "Cabin": "D20",
        "Embarked": "C"
    },
    {
        "PassengerId": 498,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Shellard, Mr. Frederick William",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A. 6212",
        "Fare": 15.1,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 499,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Allison, Mrs. Hudson J C (Bessie Waldo Daniels)",
        "Sex": "female",
        "Age": 25,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": 113781,
        "Fare": 151.55,
        "Cabin": "C22 C26",
        "Embarked": "S"
    },
    {
        "PassengerId": 500,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Svensson, Mr. Olof",
        "Sex": "male",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 350035,
        "Fare": 7.7958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 501,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Calic, Mr. Petar",
        "Sex": "male",
        "Age": 17,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 315086,
        "Fare": 8.6625,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 502,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Canavan, Miss. Mary",
        "Sex": "female",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 364846,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 503,
        "Survived": 0,
        "Pclass": 3,
        "Name": "O'Sullivan, Miss. Bridget Mary",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 330909,
        "Fare": 7.6292,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 504,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Laitinen, Miss. Kristina Sofia",
        "Sex": "female",
        "Age": 37,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 4135,
        "Fare": 9.5875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 505,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Maioni, Miss. Roberta",
        "Sex": "female",
        "Age": 16,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 110152,
        "Fare": 86.5,
        "Cabin": "B79",
        "Embarked": "S"
    },
    {
        "PassengerId": 506,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Penasco y Castellana, Mr. Victor de Satode",
        "Sex": "male",
        "Age": 18,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "PC 17758",
        "Fare": 108.9,
        "Cabin": "C65",
        "Embarked": "C"
    },
    {
        "PassengerId": 507,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Quick, Mrs. Frederick Charles (Jane Richards)",
        "Sex": "female",
        "Age": 33,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 26360,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 508,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Bradley, Mr. George (\"George Arthur Brayton\")",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 111427,
        "Fare": 26.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 509,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Olsen, Mr. Henry Margido",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C 4001",
        "Fare": 22.525,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 510,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Lang, Mr. Fang",
        "Sex": "male",
        "Age": 26,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 1601,
        "Fare": 56.4958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 511,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Daly, Mr. Eugene Patrick",
        "Sex": "male",
        "Age": 29,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 382651,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 512,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Webber, Mr. James",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/OQ 3101316",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 513,
        "Survived": 1,
        "Pclass": 1,
        "Name": "McGough, Mr. James Robert",
        "Sex": "male",
        "Age": 36,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17473",
        "Fare": 26.2875,
        "Cabin": "E25",
        "Embarked": "S"
    },
    {
        "PassengerId": 514,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Rothschild, Mrs. Martin (Elizabeth L. Barrett)",
        "Sex": "female",
        "Age": 54,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "PC 17603",
        "Fare": 59.4,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 515,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Coleff, Mr. Satio",
        "Sex": "male",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349209,
        "Fare": 7.4958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 516,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Walker, Mr. William Anderson",
        "Sex": "male",
        "Age": 47,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 36967,
        "Fare": 34.0208,
        "Cabin": "D46",
        "Embarked": "S"
    },
    {
        "PassengerId": 517,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Lemore, Mrs. (Amelia Milley)",
        "Sex": "female",
        "Age": 34,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A. 34260",
        "Fare": 10.5,
        "Cabin": "F33",
        "Embarked": "S"
    },
    {
        "PassengerId": 518,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Ryan, Mr. Patrick",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 371110,
        "Fare": 24.15,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 519,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Angle, Mrs. William A (Florence \"Mary\" Agnes Hughes)",
        "Sex": "female",
        "Age": 36,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 226875,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 520,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Pavlovic, Mr. Stefo",
        "Sex": "male",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349242,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 521,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Perreault, Miss. Anne",
        "Sex": "female",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 12749,
        "Fare": 93.5,
        "Cabin": "B73",
        "Embarked": "S"
    },
    {
        "PassengerId": 522,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Vovk, Mr. Janko",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349252,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 523,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lahoud, Mr. Sarkis",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2624,
        "Fare": 7.225,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 524,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Hippach, Mrs. Louis Albert (Ida Sophia Fischer)",
        "Sex": "female",
        "Age": 44,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 111361,
        "Fare": 57.9792,
        "Cabin": "B18",
        "Embarked": "C"
    },
    {
        "PassengerId": 525,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Kassem, Mr. Fared",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2700,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 526,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Farrell, Mr. James",
        "Sex": "male",
        "Age": 40.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 367232,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 527,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Ridsdale, Miss. Lucy",
        "Sex": "female",
        "Age": 50,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "W./C. 14258",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 528,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Farthing, Mr. John",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17483",
        "Fare": 221.7792,
        "Cabin": "C95",
        "Embarked": "S"
    },
    {
        "PassengerId": 529,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Salonen, Mr. Johan Werner",
        "Sex": "male",
        "Age": 39,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 3101296,
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 530,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Hocking, Mr. Richard George",
        "Sex": "male",
        "Age": 23,
        "SibSp": 2,
        "Parch": 1,
        "Ticket": 29104,
        "Fare": 11.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 531,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Quick, Miss. Phyllis May",
        "Sex": "female",
        "Age": 2,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 26360,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 532,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Toufik, Mr. Nakli",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2641,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 533,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Elias, Mr. Joseph Jr",
        "Sex": "male",
        "Age": 17,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 2690,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 534,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Peter, Mrs. Catherine (Catherine Rizk)",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 2668,
        "Fare": 22.3583,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 535,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Cacic, Miss. Marija",
        "Sex": "female",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 315084,
        "Fare": 8.6625,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 536,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Hart, Miss. Eva Miriam",
        "Sex": "female",
        "Age": 7,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": "F.C.C. 13529",
        "Fare": 26.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 537,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Butt, Major. Archibald Willingham",
        "Sex": "male",
        "Age": 45,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113050,
        "Fare": 26.55,
        "Cabin": "B38",
        "Embarked": "S"
    },
    {
        "PassengerId": 538,
        "Survived": 1,
        "Pclass": 1,
        "Name": "LeRoy, Miss. Bertha",
        "Sex": "female",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17761",
        "Fare": 106.425,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 539,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Risien, Mr. Samuel Beard",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 364498,
        "Fare": 14.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 540,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Frolicher, Miss. Hedwig Margaritha",
        "Sex": "female",
        "Age": 22,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 13568,
        "Fare": 49.5,
        "Cabin": "B39",
        "Embarked": "C"
    },
    {
        "PassengerId": 541,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Crosby, Miss. Harriet R",
        "Sex": "female",
        "Age": 36,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": "WE/P 5735",
        "Fare": 71,
        "Cabin": "B22",
        "Embarked": "S"
    },
    {
        "PassengerId": 542,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Andersson, Miss. Ingeborg Constanzia",
        "Sex": "female",
        "Age": 9,
        "SibSp": 4,
        "Parch": 2,
        "Ticket": 347082,
        "Fare": 31.275,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 543,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Andersson, Miss. Sigrid Elisabeth",
        "Sex": "female",
        "Age": 11,
        "SibSp": 4,
        "Parch": 2,
        "Ticket": 347082,
        "Fare": 31.275,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 544,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Beane, Mr. Edward",
        "Sex": "male",
        "Age": 32,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 2908,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 545,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Douglas, Mr. Walter Donald",
        "Sex": "male",
        "Age": 50,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "PC 17761",
        "Fare": 106.425,
        "Cabin": "C86",
        "Embarked": "C"
    },
    {
        "PassengerId": 546,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Nicholson, Mr. Arthur Ernest",
        "Sex": "male",
        "Age": 64,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 693,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 547,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Beane, Mrs. Edward (Ethel Clarke)",
        "Sex": "female",
        "Age": 19,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 2908,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 548,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Padro y Manent, Mr. Julian",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SC/PARIS 2146",
        "Fare": 13.8625,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 549,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Goldsmith, Mr. Frank John",
        "Sex": "male",
        "Age": 33,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 363291,
        "Fare": 20.525,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 550,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Davies, Master. John Morgan Jr",
        "Sex": "male",
        "Age": 8,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "C.A. 33112",
        "Fare": 36.75,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 551,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Thayer, Mr. John Borland Jr",
        "Sex": "male",
        "Age": 17,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 17421,
        "Fare": 110.8833,
        "Cabin": "C70",
        "Embarked": "C"
    },
    {
        "PassengerId": 552,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Sharp, Mr. Percival James R",
        "Sex": "male",
        "Age": 27,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 244358,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 553,
        "Survived": 0,
        "Pclass": 3,
        "Name": "O'Brien, Mr. Timothy",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 330979,
        "Fare": 7.8292,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 554,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Leeni, Mr. Fahim (\"Philip Zenni\")",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2620,
        "Fare": 7.225,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 555,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Ohman, Miss. Velin",
        "Sex": "female",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347085,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 556,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Wright, Mr. George",
        "Sex": "male",
        "Age": 62,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113807,
        "Fare": 26.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 557,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Duff Gordon, Lady. (Lucille Christiana Sutherland) (\"Mrs Morgan\")",
        "Sex": "female",
        "Age": 48,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 11755,
        "Fare": 39.6,
        "Cabin": "A16",
        "Embarked": "C"
    },
    {
        "PassengerId": 558,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Robbins, Mr. Victor",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17757",
        "Fare": 227.525,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 559,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Taussig, Mrs. Emil (Tillie Mandelbaum)",
        "Sex": "female",
        "Age": 39,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 110413,
        "Fare": 79.65,
        "Cabin": "E67",
        "Embarked": "S"
    },
    {
        "PassengerId": 560,
        "Survived": 1,
        "Pclass": 3,
        "Name": "de Messemaeker, Mrs. Guillaume Joseph (Emma)",
        "Sex": "female",
        "Age": 36,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 345572,
        "Fare": 17.4,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 561,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Morrow, Mr. Thomas Rowan",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 372622,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 562,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sivic, Mr. Husein",
        "Sex": "male",
        "Age": 40,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349251,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 563,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Norman, Mr. Robert Douglas",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 218629,
        "Fare": 13.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 564,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Simmons, Mr. John",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/OQ 392082",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 565,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Meanwell, Miss. (Marion Ogden)",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/O.Q. 392087",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 566,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Davies, Mr. Alfred J",
        "Sex": "male",
        "Age": 24,
        "SibSp": 2,
        "Parch": 0,
        "Ticket": "A/4 48871",
        "Fare": 24.15,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 567,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Stoytcheff, Mr. Ilia",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349205,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 568,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Palsson, Mrs. Nils (Alma Cornelia Berglund)",
        "Sex": "female",
        "Age": 29,
        "SibSp": 0,
        "Parch": 4,
        "Ticket": 349909,
        "Fare": 21.075,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 569,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Doharr, Mr. Tannous",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2686,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 570,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Jonsson, Mr. Carl",
        "Sex": "male",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 350417,
        "Fare": 7.8542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 571,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Harris, Mr. George",
        "Sex": "male",
        "Age": 62,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "S.W./PP 752",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 572,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Appleton, Mrs. Edward Dale (Charlotte Lamson)",
        "Sex": "female",
        "Age": 53,
        "SibSp": 2,
        "Parch": 0,
        "Ticket": 11769,
        "Fare": 51.4792,
        "Cabin": "C101",
        "Embarked": "S"
    },
    {
        "PassengerId": 573,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Flynn, Mr. John Irwin (\"Irving\")",
        "Sex": "male",
        "Age": 36,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17474",
        "Fare": 26.3875,
        "Cabin": "E25",
        "Embarked": "S"
    },
    {
        "PassengerId": 574,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Kelly, Miss. Mary",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 14312,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 575,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Rush, Mr. Alfred George John",
        "Sex": "male",
        "Age": 16,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/4. 20589",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 576,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Patchett, Mr. George",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 358585,
        "Fare": 14.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 577,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Garside, Miss. Ethel",
        "Sex": "female",
        "Age": 34,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 243880,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 578,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Silvey, Mrs. William Baird (Alice Munger)",
        "Sex": "female",
        "Age": 39,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 13507,
        "Fare": 55.9,
        "Cabin": "E44",
        "Embarked": "S"
    },
    {
        "PassengerId": 579,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Caram, Mrs. Joseph (Maria Elias)",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 2689,
        "Fare": 14.4583,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 580,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Jussila, Mr. Eiriik",
        "Sex": "male",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O 2. 3101286",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 581,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Christy, Miss. Julie Rachel",
        "Sex": "female",
        "Age": 25,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 237789,
        "Fare": 30,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 582,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Thayer, Mrs. John Borland (Marian Longstreth Morris)",
        "Sex": "female",
        "Age": 39,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 17421,
        "Fare": 110.8833,
        "Cabin": "C68",
        "Embarked": "C"
    },
    {
        "PassengerId": 583,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Downton, Mr. William James",
        "Sex": "male",
        "Age": 54,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 28403,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 584,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Ross, Mr. John Hugo",
        "Sex": "male",
        "Age": 36,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 13049,
        "Fare": 40.125,
        "Cabin": "A10",
        "Embarked": "C"
    },
    {
        "PassengerId": 585,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Paulner, Mr. Uscher",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 3411,
        "Fare": 8.7125,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 586,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Taussig, Miss. Ruth",
        "Sex": "female",
        "Age": 18,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 110413,
        "Fare": 79.65,
        "Cabin": "E68",
        "Embarked": "S"
    },
    {
        "PassengerId": 587,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Jarvis, Mr. John Denzil",
        "Sex": "male",
        "Age": 47,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 237565,
        "Fare": 15,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 588,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Frolicher-Stehli, Mr. Maxmillian",
        "Sex": "male",
        "Age": 60,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 13567,
        "Fare": 79.2,
        "Cabin": "B41",
        "Embarked": "C"
    },
    {
        "PassengerId": 589,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Gilinski, Mr. Eliezer",
        "Sex": "male",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 14973,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 590,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Murdlin, Mr. Joseph",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A./5. 3235",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 591,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Rintamaki, Mr. Matti",
        "Sex": "male",
        "Age": 35,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O 2. 3101273",
        "Fare": 7.125,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 592,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Stephenson, Mrs. Walter Bertram (Martha Eustis)",
        "Sex": "female",
        "Age": 52,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 36947,
        "Fare": 78.2667,
        "Cabin": "D20",
        "Embarked": "C"
    },
    {
        "PassengerId": 593,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Elsbury, Mr. William James",
        "Sex": "male",
        "Age": 47,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/5 3902",
        "Fare": 7.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 594,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Bourke, Miss. Mary",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 364848,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 595,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Chapman, Mr. John Henry",
        "Sex": "male",
        "Age": 37,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "SC/AH 29037",
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 596,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Van Impe, Mr. Jean Baptiste",
        "Sex": "male",
        "Age": 36,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 345773,
        "Fare": 24.15,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 597,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Leitch, Miss. Jessie Wills",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 248727,
        "Fare": 33,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 598,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Johnson, Mr. Alfred",
        "Sex": "male",
        "Age": 49,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "LINE",
        "Fare": 0,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 599,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Boulos, Mr. Hanna",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2664,
        "Fare": 7.225,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 600,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Duff Gordon, Sir. Cosmo Edmund (\"Mr Morgan\")",
        "Sex": "male",
        "Age": 49,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "PC 17485",
        "Fare": 56.9292,
        "Cabin": "A20",
        "Embarked": "C"
    },
    {
        "PassengerId": 601,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Jacobsohn, Mrs. Sidney Samuel (Amy Frances Christy)",
        "Sex": "female",
        "Age": 24,
        "SibSp": 2,
        "Parch": 1,
        "Ticket": 243847,
        "Fare": 27,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 602,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Slabenoff, Mr. Petco",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349214,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 603,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Harrington, Mr. Charles H",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113796,
        "Fare": 42.4,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 604,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Torber, Mr. Ernst William",
        "Sex": "male",
        "Age": 44,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 364511,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 605,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Homer, Mr. Harry (\"Mr E Haven\")",
        "Sex": "male",
        "Age": 35,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 111426,
        "Fare": 26.55,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 606,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lindell, Mr. Edvard Bengtsson",
        "Sex": "male",
        "Age": 36,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 349910,
        "Fare": 15.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 607,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Karaic, Mr. Milan",
        "Sex": "male",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349246,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 608,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Daniel, Mr. Robert Williams",
        "Sex": "male",
        "Age": 27,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113804,
        "Fare": 30.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 609,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Laroche, Mrs. Joseph (Juliette Marie Louise Lafargue)",
        "Sex": "female",
        "Age": 22,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": "SC/Paris 2123",
        "Fare": 41.5792,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 610,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Shutes, Miss. Elizabeth W",
        "Sex": "female",
        "Age": 40,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17582",
        "Fare": 153.4625,
        "Cabin": "C125",
        "Embarked": "S"
    },
    {
        "PassengerId": 611,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Andersson, Mrs. Anders Johan (Alfrida Konstantia Brogren)",
        "Sex": "female",
        "Age": 39,
        "SibSp": 1,
        "Parch": 5,
        "Ticket": 347082,
        "Fare": 31.275,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 612,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Jardin, Mr. Jose Neto",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/O.Q. 3101305",
        "Fare": 7.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 613,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Murphy, Miss. Margaret Jane",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 367230,
        "Fare": 15.5,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 614,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Horgan, Mr. John",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 370377,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 615,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Brocklebank, Mr. William Alfred",
        "Sex": "male",
        "Age": 35,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 364512,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 616,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Herman, Miss. Alice",
        "Sex": "female",
        "Age": 24,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": 220845,
        "Fare": 65,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 617,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Danbom, Mr. Ernst Gilbert",
        "Sex": "male",
        "Age": 34,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 347080,
        "Fare": 14.4,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 618,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lobb, Mrs. William Arthur (Cordelia K Stanlick)",
        "Sex": "female",
        "Age": 26,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "A/5. 3336",
        "Fare": 16.1,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 619,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Becker, Miss. Marion Louise",
        "Sex": "female",
        "Age": 4,
        "SibSp": 2,
        "Parch": 1,
        "Ticket": 230136,
        "Fare": 39,
        "Cabin": "F4",
        "Embarked": "S"
    },
    {
        "PassengerId": 620,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Gavey, Mr. Lawrence",
        "Sex": "male",
        "Age": 26,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 31028,
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 621,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Yasbeck, Mr. Antoni",
        "Sex": "male",
        "Age": 27,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 2659,
        "Fare": 14.4542,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 622,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Kimball, Mr. Edwin Nelson Jr",
        "Sex": "male",
        "Age": 42,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 11753,
        "Fare": 52.5542,
        "Cabin": "D19",
        "Embarked": "S"
    },
    {
        "PassengerId": 623,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Nakid, Mr. Sahid",
        "Sex": "male",
        "Age": 20,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 2653,
        "Fare": 15.7417,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 624,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Hansen, Mr. Henry Damsgaard",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 350029,
        "Fare": 7.8542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 625,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Bowen, Mr. David John \"Dai",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 54636,
        "Fare": 16.1,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 626,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Sutton, Mr. Frederick",
        "Sex": "male",
        "Age": 61,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 36963,
        "Fare": 32.3208,
        "Cabin": "D50",
        "Embarked": "S"
    },
    {
        "PassengerId": 627,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Kirkland, Rev. Charles Leonard",
        "Sex": "male",
        "Age": 57,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 219533,
        "Fare": 12.35,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 628,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Longley, Miss. Gretchen Fiske",
        "Sex": "female",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 13502,
        "Fare": 77.9583,
        "Cabin": "D9",
        "Embarked": "S"
    },
    {
        "PassengerId": 629,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Bostandyeff, Mr. Guentcho",
        "Sex": "male",
        "Age": 26,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349224,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 630,
        "Survived": 0,
        "Pclass": 3,
        "Name": "O'Connell, Mr. Patrick D",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 334912,
        "Fare": 7.7333,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 631,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Barkworth, Mr. Algernon Henry Wilson",
        "Sex": "male",
        "Age": 80,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 27042,
        "Fare": 30,
        "Cabin": "A23",
        "Embarked": "S"
    },
    {
        "PassengerId": 632,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lundahl, Mr. Johan Svensson",
        "Sex": "male",
        "Age": 51,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347743,
        "Fare": 7.0542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 633,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Stahelin-Maeglin, Dr. Max",
        "Sex": "male",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 13214,
        "Fare": 30.5,
        "Cabin": "B50",
        "Embarked": "C"
    },
    {
        "PassengerId": 634,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Parr, Mr. William Henry Marsh",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 112052,
        "Fare": 0,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 635,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Skoog, Miss. Mabel",
        "Sex": "female",
        "Age": 9,
        "SibSp": 3,
        "Parch": 2,
        "Ticket": 347088,
        "Fare": 27.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 636,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Davis, Miss. Mary",
        "Sex": "female",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 237668,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 637,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Leinonen, Mr. Antti Gustaf",
        "Sex": "male",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O 2. 3101292",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 638,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Collyer, Mr. Harvey",
        "Sex": "male",
        "Age": 31,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "C.A. 31921",
        "Fare": 26.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 639,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Panula, Mrs. Juha (Maria Emilia Ojala)",
        "Sex": "female",
        "Age": 41,
        "SibSp": 0,
        "Parch": 5,
        "Ticket": 3101295,
        "Fare": 39.6875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 640,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Thorneycroft, Mr. Percival",
        "Sex": "male",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 376564,
        "Fare": 16.1,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 641,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Jensen, Mr. Hans Peder",
        "Sex": "male",
        "Age": 20,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 350050,
        "Fare": 7.8542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 642,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Sagesser, Mlle. Emma",
        "Sex": "female",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17477",
        "Fare": 69.3,
        "Cabin": "B35",
        "Embarked": "C"
    },
    {
        "PassengerId": 643,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Skoog, Miss. Margit Elizabeth",
        "Sex": "female",
        "Age": 2,
        "SibSp": 3,
        "Parch": 2,
        "Ticket": 347088,
        "Fare": 27.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 644,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Foo, Mr. Choong",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 1601,
        "Fare": 56.4958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 645,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Baclini, Miss. Eugenie",
        "Sex": "female",
        "Age": 0.75,
        "SibSp": 2,
        "Parch": 1,
        "Ticket": 2666,
        "Fare": 19.2583,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 646,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Harper, Mr. Henry Sleeper",
        "Sex": "male",
        "Age": 48,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "PC 17572",
        "Fare": 76.7292,
        "Cabin": "D33",
        "Embarked": "C"
    },
    {
        "PassengerId": 647,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Cor, Mr. Liudevit",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349231,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 648,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Simonius-Blumer, Col. Oberst Alfons",
        "Sex": "male",
        "Age": 56,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 13213,
        "Fare": 35.5,
        "Cabin": "A26",
        "Embarked": "C"
    },
    {
        "PassengerId": 649,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Willey, Mr. Edward",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "S.O./P.P. 751",
        "Fare": 7.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 650,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Stanley, Miss. Amy Zillah Elsie",
        "Sex": "female",
        "Age": 23,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "CA. 2314",
        "Fare": 7.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 651,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Mitkoff, Mr. Mito",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349221,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 652,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Doling, Miss. Elsie",
        "Sex": "female",
        "Age": 18,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 231919,
        "Fare": 23,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 653,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Kalvik, Mr. Johannes Halvorsen",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 8475,
        "Fare": 8.4333,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 654,
        "Survived": 1,
        "Pclass": 3,
        "Name": "O'Leary, Miss. Hanora \"Norah",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 330919,
        "Fare": 7.8292,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 655,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Hegarty, Miss. Hanora \"Nora",
        "Sex": "female",
        "Age": 18,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 365226,
        "Fare": 6.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 656,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Hickman, Mr. Leonard Mark",
        "Sex": "male",
        "Age": 24,
        "SibSp": 2,
        "Parch": 0,
        "Ticket": "S.O.C. 14879",
        "Fare": 73.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 657,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Radeff, Mr. Alexander",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349223,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 658,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Bourke, Mrs. John (Catherine)",
        "Sex": "female",
        "Age": 32,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 364849,
        "Fare": 15.5,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 659,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Eitemiller, Mr. George Floyd",
        "Sex": "male",
        "Age": 23,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 29751,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 660,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Newell, Mr. Arthur Webster",
        "Sex": "male",
        "Age": 58,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": 35273,
        "Fare": 113.275,
        "Cabin": "D48",
        "Embarked": "C"
    },
    {
        "PassengerId": 661,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Frauenthal, Dr. Henry William",
        "Sex": "male",
        "Age": 50,
        "SibSp": 2,
        "Parch": 0,
        "Ticket": "PC 17611",
        "Fare": 133.65,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 662,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Badt, Mr. Mohamed",
        "Sex": "male",
        "Age": 40,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2623,
        "Fare": 7.225,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 663,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Colley, Mr. Edward Pomeroy",
        "Sex": "male",
        "Age": 47,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 5727,
        "Fare": 25.5875,
        "Cabin": "E58",
        "Embarked": "S"
    },
    {
        "PassengerId": 664,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Coleff, Mr. Peju",
        "Sex": "male",
        "Age": 36,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349210,
        "Fare": 7.4958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 665,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Lindqvist, Mr. Eino William",
        "Sex": "male",
        "Age": 20,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "STON/O 2. 3101285",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 666,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Hickman, Mr. Lewis",
        "Sex": "male",
        "Age": 32,
        "SibSp": 2,
        "Parch": 0,
        "Ticket": "S.O.C. 14879",
        "Fare": 73.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 667,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Butler, Mr. Reginald Fenton",
        "Sex": "male",
        "Age": 25,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 234686,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 668,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Rommetvedt, Mr. Knud Paust",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 312993,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 669,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Cook, Mr. Jacob",
        "Sex": "male",
        "Age": 43,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/5 3536",
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 670,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Taylor, Mrs. Elmer Zebley (Juliet Cummins Wright)",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 19996,
        "Fare": 52,
        "Cabin": "C126",
        "Embarked": "S"
    },
    {
        "PassengerId": 671,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Brown, Mrs. Thomas William Solomon (Elizabeth Catherine Ford)",
        "Sex": "female",
        "Age": 40,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 29750,
        "Fare": 39,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 672,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Davidson, Mr. Thornton",
        "Sex": "male",
        "Age": 31,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "F.C. 12750",
        "Fare": 52,
        "Cabin": "B71",
        "Embarked": "S"
    },
    {
        "PassengerId": 673,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Mitchell, Mr. Henry Michael",
        "Sex": "male",
        "Age": 70,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A. 24580",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 674,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Wilhelms, Mr. Charles",
        "Sex": "male",
        "Age": 31,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 244270,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 675,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Watson, Mr. Ennis Hastings",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 239856,
        "Fare": 0,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 676,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Edvardsson, Mr. Gustaf Hjalmar",
        "Sex": "male",
        "Age": 18,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349912,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 677,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sawyer, Mr. Frederick Charles",
        "Sex": "male",
        "Age": 24.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 342826,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 678,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Turja, Miss. Anna Sofia",
        "Sex": "female",
        "Age": 18,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 4138,
        "Fare": 9.8417,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 679,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Goodwin, Mrs. Frederick (Augusta Tyler)",
        "Sex": "female",
        "Age": 43,
        "SibSp": 1,
        "Parch": 6,
        "Ticket": "CA 2144",
        "Fare": 46.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 680,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Cardeza, Mr. Thomas Drake Martinez",
        "Sex": "male",
        "Age": 36,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": "PC 17755",
        "Fare": 512.3292,
        "Cabin": "B51 B53 B55",
        "Embarked": "C"
    },
    {
        "PassengerId": 681,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Peters, Miss. Katie",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 330935,
        "Fare": 8.1375,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 682,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Hassab, Mr. Hammad",
        "Sex": "male",
        "Age": 27,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17572",
        "Fare": 76.7292,
        "Cabin": "D49",
        "Embarked": "C"
    },
    {
        "PassengerId": 683,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Olsvigen, Mr. Thor Anderson",
        "Sex": "male",
        "Age": 20,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 6563,
        "Fare": 9.225,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 684,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Goodwin, Mr. Charles Edward",
        "Sex": "male",
        "Age": 14,
        "SibSp": 5,
        "Parch": 2,
        "Ticket": "CA 2144",
        "Fare": 46.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 685,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Brown, Mr. Thomas William Solomon",
        "Sex": "male",
        "Age": 60,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 29750,
        "Fare": 39,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 686,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Laroche, Mr. Joseph Philippe Lemercier",
        "Sex": "male",
        "Age": 25,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": "SC/Paris 2123",
        "Fare": 41.5792,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 687,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Panula, Mr. Jaako Arnold",
        "Sex": "male",
        "Age": 14,
        "SibSp": 4,
        "Parch": 1,
        "Ticket": 3101295,
        "Fare": 39.6875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 688,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Dakic, Mr. Branko",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349228,
        "Fare": 10.1708,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 689,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Fischer, Mr. Eberhard Thelander",
        "Sex": "male",
        "Age": 18,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 350036,
        "Fare": 7.7958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 690,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Madill, Miss. Georgette Alexandra",
        "Sex": "female",
        "Age": 15,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 24160,
        "Fare": 211.3375,
        "Cabin": "B5",
        "Embarked": "S"
    },
    {
        "PassengerId": 691,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Dick, Mr. Albert Adrian",
        "Sex": "male",
        "Age": 31,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 17474,
        "Fare": 57,
        "Cabin": "B20",
        "Embarked": "S"
    },
    {
        "PassengerId": 692,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Karun, Miss. Manca",
        "Sex": "female",
        "Age": 4,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 349256,
        "Fare": 13.4167,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 693,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Lam, Mr. Ali",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 1601,
        "Fare": 56.4958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 694,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Saad, Mr. Khalil",
        "Sex": "male",
        "Age": 25,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2672,
        "Fare": 7.225,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 695,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Weir, Col. John",
        "Sex": "male",
        "Age": 60,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113800,
        "Fare": 26.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 696,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Chapman, Mr. Charles Henry",
        "Sex": "male",
        "Age": 52,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 248731,
        "Fare": 13.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 697,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Kelly, Mr. James",
        "Sex": "male",
        "Age": 44,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 363592,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 698,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Mullens, Miss. Katherine \"Katie",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 35852,
        "Fare": 7.7333,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 699,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Thayer, Mr. John Borland",
        "Sex": "male",
        "Age": 49,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 17421,
        "Fare": 110.8833,
        "Cabin": "C68",
        "Embarked": "C"
    },
    {
        "PassengerId": 700,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Humblen, Mr. Adolf Mathias Nicolai Olsen",
        "Sex": "male",
        "Age": 42,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 348121,
        "Fare": 7.65,
        "Cabin": "F G63",
        "Embarked": "S"
    },
    {
        "PassengerId": 701,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Astor, Mrs. John Jacob (Madeleine Talmadge Force)",
        "Sex": "female",
        "Age": 18,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "PC 17757",
        "Fare": 227.525,
        "Cabin": "C62 C64",
        "Embarked": "C"
    },
    {
        "PassengerId": 702,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Silverthorne, Mr. Spencer Victor",
        "Sex": "male",
        "Age": 35,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17475",
        "Fare": 26.2875,
        "Cabin": "E24",
        "Embarked": "S"
    },
    {
        "PassengerId": 703,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Barbara, Miss. Saiide",
        "Sex": "female",
        "Age": 18,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 2691,
        "Fare": 14.4542,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 704,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Gallagher, Mr. Martin",
        "Sex": "male",
        "Age": 25,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 36864,
        "Fare": 7.7417,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 705,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Hansen, Mr. Henrik Juul",
        "Sex": "male",
        "Age": 26,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 350025,
        "Fare": 7.8542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 706,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Morley, Mr. Henry Samuel (\"Mr Henry Marshall\")",
        "Sex": "male",
        "Age": 39,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 250655,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 707,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Kelly, Mrs. Florence \"Fannie",
        "Sex": "female",
        "Age": 45,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 223596,
        "Fare": 13.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 708,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Calderhead, Mr. Edward Pennington",
        "Sex": "male",
        "Age": 42,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17476",
        "Fare": 26.2875,
        "Cabin": "E24",
        "Embarked": "S"
    },
    {
        "PassengerId": 709,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Cleaver, Miss. Alice",
        "Sex": "female",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113781,
        "Fare": 151.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 710,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Moubarek, Master. Halim Gonios (\"William George\")",
        "Sex": "male",
        "Age": "",
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 2661,
        "Fare": 15.2458,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 711,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Mayne, Mlle. Berthe Antonine (\"Mrs de Villiers\")",
        "Sex": "female",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17482",
        "Fare": 49.5042,
        "Cabin": "C90",
        "Embarked": "C"
    },
    {
        "PassengerId": 712,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Klaber, Mr. Herman",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113028,
        "Fare": 26.55,
        "Cabin": "C124",
        "Embarked": "S"
    },
    {
        "PassengerId": 713,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Taylor, Mr. Elmer Zebley",
        "Sex": "male",
        "Age": 48,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 19996,
        "Fare": 52,
        "Cabin": "C126",
        "Embarked": "S"
    },
    {
        "PassengerId": 714,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Larsson, Mr. August Viktor",
        "Sex": "male",
        "Age": 29,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 7545,
        "Fare": 9.4833,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 715,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Greenberg, Mr. Samuel",
        "Sex": "male",
        "Age": 52,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 250647,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 716,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Soholt, Mr. Peter Andreas Lauritz Andersen",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 348124,
        "Fare": 7.65,
        "Cabin": "F G73",
        "Embarked": "S"
    },
    {
        "PassengerId": 717,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Endres, Miss. Caroline Louise",
        "Sex": "female",
        "Age": 38,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17757",
        "Fare": 227.525,
        "Cabin": "C45",
        "Embarked": "C"
    },
    {
        "PassengerId": 718,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Troutt, Miss. Edwina Celia \"Winnie",
        "Sex": "female",
        "Age": 27,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 34218,
        "Fare": 10.5,
        "Cabin": "E101",
        "Embarked": "S"
    },
    {
        "PassengerId": 719,
        "Survived": 0,
        "Pclass": 3,
        "Name": "McEvoy, Mr. Michael",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 36568,
        "Fare": 15.5,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 720,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Johnson, Mr. Malkolm Joackim",
        "Sex": "male",
        "Age": 33,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347062,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 721,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Harper, Miss. Annie Jessie \"Nina",
        "Sex": "female",
        "Age": 6,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 248727,
        "Fare": 33,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 722,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Jensen, Mr. Svend Lauritz",
        "Sex": "male",
        "Age": 17,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 350048,
        "Fare": 7.0542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 723,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Gillespie, Mr. William Henry",
        "Sex": "male",
        "Age": 34,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 12233,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 724,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Hodges, Mr. Henry Price",
        "Sex": "male",
        "Age": 50,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 250643,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 725,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Chambers, Mr. Norman Campbell",
        "Sex": "male",
        "Age": 27,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 113806,
        "Fare": 53.1,
        "Cabin": "E8",
        "Embarked": "S"
    },
    {
        "PassengerId": 726,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Oreskovic, Mr. Luka",
        "Sex": "male",
        "Age": 20,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 315094,
        "Fare": 8.6625,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 727,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Renouf, Mrs. Peter Henry (Lillian Jefferys)",
        "Sex": "female",
        "Age": 30,
        "SibSp": 3,
        "Parch": 0,
        "Ticket": 31027,
        "Fare": 21,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 728,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Mannion, Miss. Margareth",
        "Sex": "female",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 36866,
        "Fare": 7.7375,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 729,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Bryhl, Mr. Kurt Arnold Gottfrid",
        "Sex": "male",
        "Age": 25,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 236853,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 730,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Ilmakangas, Miss. Pieta Sofia",
        "Sex": "female",
        "Age": 25,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "STON/O2. 3101271",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 731,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Allen, Miss. Elisabeth Walton",
        "Sex": "female",
        "Age": 29,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 24160,
        "Fare": 211.3375,
        "Cabin": "B5",
        "Embarked": "S"
    },
    {
        "PassengerId": 732,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Hassan, Mr. Houssein G N",
        "Sex": "male",
        "Age": 11,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2699,
        "Fare": 18.7875,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 733,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Knight, Mr. Robert J",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 239855,
        "Fare": 0,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 734,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Berriman, Mr. William John",
        "Sex": "male",
        "Age": 23,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 28425,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 735,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Troupiansky, Mr. Moses Aaron",
        "Sex": "male",
        "Age": 23,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 233639,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 736,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Williams, Mr. Leslie",
        "Sex": "male",
        "Age": 28.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 54636,
        "Fare": 16.1,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 737,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Ford, Mrs. Edward (Margaret Ann Watson)",
        "Sex": "female",
        "Age": 48,
        "SibSp": 1,
        "Parch": 3,
        "Ticket": "W./C. 6608",
        "Fare": 34.375,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 738,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Lesurer, Mr. Gustave J",
        "Sex": "male",
        "Age": 35,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17755",
        "Fare": 512.3292,
        "Cabin": "B101",
        "Embarked": "C"
    },
    {
        "PassengerId": 739,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Ivanoff, Mr. Kanio",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349201,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 740,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Nankoff, Mr. Minko",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349218,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 741,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Hawksford, Mr. Walter James",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 16988,
        "Fare": 30,
        "Cabin": "D45",
        "Embarked": "S"
    },
    {
        "PassengerId": 742,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Cavendish, Mr. Tyrell William",
        "Sex": "male",
        "Age": 36,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 19877,
        "Fare": 78.85,
        "Cabin": "C46",
        "Embarked": "S"
    },
    {
        "PassengerId": 743,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Ryerson, Miss. Susan Parker \"Suzette",
        "Sex": "female",
        "Age": 21,
        "SibSp": 2,
        "Parch": 2,
        "Ticket": "PC 17608",
        "Fare": 262.375,
        "Cabin": "B57 B59 B63 B66",
        "Embarked": "C"
    },
    {
        "PassengerId": 744,
        "Survived": 0,
        "Pclass": 3,
        "Name": "McNamee, Mr. Neal",
        "Sex": "male",
        "Age": 24,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 376566,
        "Fare": 16.1,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 745,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Stranden, Mr. Juho",
        "Sex": "male",
        "Age": 31,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O 2. 3101288",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 746,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Crosby, Capt. Edward Gifford",
        "Sex": "male",
        "Age": 70,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "WE/P 5735",
        "Fare": 71,
        "Cabin": "B22",
        "Embarked": "S"
    },
    {
        "PassengerId": 747,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Abbott, Mr. Rossmore Edward",
        "Sex": "male",
        "Age": 16,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "C.A. 2673",
        "Fare": 20.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 748,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Sinkkonen, Miss. Anna",
        "Sex": "female",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 250648,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 749,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Marvin, Mr. Daniel Warner",
        "Sex": "male",
        "Age": 19,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 113773,
        "Fare": 53.1,
        "Cabin": "D30",
        "Embarked": "S"
    },
    {
        "PassengerId": 750,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Connaghton, Mr. Michael",
        "Sex": "male",
        "Age": 31,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 335097,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 751,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Wells, Miss. Joan",
        "Sex": "female",
        "Age": 4,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 29103,
        "Fare": 23,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 752,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Moor, Master. Meier",
        "Sex": "male",
        "Age": 6,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 392096,
        "Fare": 12.475,
        "Cabin": "E121",
        "Embarked": "S"
    },
    {
        "PassengerId": 753,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Vande Velde, Mr. Johannes Joseph",
        "Sex": "male",
        "Age": 33,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 345780,
        "Fare": 9.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 754,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Jonkoff, Mr. Lalio",
        "Sex": "male",
        "Age": 23,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349204,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 755,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Herman, Mrs. Samuel (Jane Laver)",
        "Sex": "female",
        "Age": 48,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": 220845,
        "Fare": 65,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 756,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Hamalainen, Master. Viljo",
        "Sex": "male",
        "Age": 0.67,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 250649,
        "Fare": 14.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 757,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Carlsson, Mr. August Sigfrid",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 350042,
        "Fare": 7.7958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 758,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Bailey, Mr. Percy Andrew",
        "Sex": "male",
        "Age": 18,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 29108,
        "Fare": 11.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 759,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Theobald, Mr. Thomas Leonard",
        "Sex": "male",
        "Age": 34,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 363294,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 760,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Rothes, the Countess. of (Lucy Noel Martha Dyer-Edwards)",
        "Sex": "female",
        "Age": 33,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 110152,
        "Fare": 86.5,
        "Cabin": "B77",
        "Embarked": "S"
    },
    {
        "PassengerId": 761,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Garfirth, Mr. John",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 358585,
        "Fare": 14.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 762,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Nirva, Mr. Iisakki Antino Aijo",
        "Sex": "male",
        "Age": 41,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/O2 3101272",
        "Fare": 7.125,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 763,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Barah, Mr. Hanna Assi",
        "Sex": "male",
        "Age": 20,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2663,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 764,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Carter, Mrs. William Ernest (Lucile Polk)",
        "Sex": "female",
        "Age": 36,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": 113760,
        "Fare": 120,
        "Cabin": "B96 B98",
        "Embarked": "S"
    },
    {
        "PassengerId": 765,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Eklund, Mr. Hans Linus",
        "Sex": "male",
        "Age": 16,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347074,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 766,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Hogeboom, Mrs. John C (Anna Andrews)",
        "Sex": "female",
        "Age": 51,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 13502,
        "Fare": 77.9583,
        "Cabin": "D11",
        "Embarked": "S"
    },
    {
        "PassengerId": 767,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Brewe, Dr. Arthur Jackson",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 112379,
        "Fare": 39.6,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 768,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Mangan, Miss. Mary",
        "Sex": "female",
        "Age": 30.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 364850,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 769,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Moran, Mr. Daniel J",
        "Sex": "male",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 371110,
        "Fare": 24.15,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 770,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Gronnestad, Mr. Daniel Danielsen",
        "Sex": "male",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 8471,
        "Fare": 8.3625,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 771,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lievens, Mr. Rene Aime",
        "Sex": "male",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 345781,
        "Fare": 9.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 772,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Jensen, Mr. Niels Peder",
        "Sex": "male",
        "Age": 48,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 350047,
        "Fare": 7.8542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 773,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Mack, Mrs. (Mary)",
        "Sex": "female",
        "Age": 57,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "S.O./P.P. 3",
        "Fare": 10.5,
        "Cabin": "E77",
        "Embarked": "S"
    },
    {
        "PassengerId": 774,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Elias, Mr. Dibo",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2674,
        "Fare": 7.225,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 775,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Hocking, Mrs. Elizabeth (Eliza Needs)",
        "Sex": "female",
        "Age": 54,
        "SibSp": 1,
        "Parch": 3,
        "Ticket": 29105,
        "Fare": 23,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 776,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Myhrman, Mr. Pehr Fabian Oliver Malkolm",
        "Sex": "male",
        "Age": 18,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347078,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 777,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Tobin, Mr. Roger",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 383121,
        "Fare": 7.75,
        "Cabin": "F38",
        "Embarked": "Q"
    },
    {
        "PassengerId": 778,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Emanuel, Miss. Virginia Ethel",
        "Sex": "female",
        "Age": 5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 364516,
        "Fare": 12.475,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 779,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Kilgannon, Mr. Thomas J",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 36865,
        "Fare": 7.7375,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 780,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Robert, Mrs. Edward Scott (Elisabeth Walton McMillan)",
        "Sex": "female",
        "Age": 43,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 24160,
        "Fare": 211.3375,
        "Cabin": "B3",
        "Embarked": "S"
    },
    {
        "PassengerId": 781,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Ayoub, Miss. Banoura",
        "Sex": "female",
        "Age": 13,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2687,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 782,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Dick, Mrs. Albert Adrian (Vera Gillespie)",
        "Sex": "female",
        "Age": 17,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 17474,
        "Fare": 57,
        "Cabin": "B20",
        "Embarked": "S"
    },
    {
        "PassengerId": 783,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Long, Mr. Milton Clyde",
        "Sex": "male",
        "Age": 29,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113501,
        "Fare": 30,
        "Cabin": "D6",
        "Embarked": "S"
    },
    {
        "PassengerId": 784,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Johnston, Mr. Andrew G",
        "Sex": "male",
        "Age": "",
        "SibSp": 1,
        "Parch": 2,
        "Ticket": "W./C. 6607",
        "Fare": 23.45,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 785,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Ali, Mr. William",
        "Sex": "male",
        "Age": 25,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/O.Q. 3101312",
        "Fare": 7.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 786,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Harmer, Mr. Abraham (David Lishin)",
        "Sex": "male",
        "Age": 25,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 374887,
        "Fare": 7.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 787,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Sjoblom, Miss. Anna Sofia",
        "Sex": "female",
        "Age": 18,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 3101265,
        "Fare": 7.4958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 788,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Rice, Master. George Hugh",
        "Sex": "male",
        "Age": 8,
        "SibSp": 4,
        "Parch": 1,
        "Ticket": 382652,
        "Fare": 29.125,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 789,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Dean, Master. Bertram Vere",
        "Sex": "male",
        "Age": 1,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": "C.A. 2315",
        "Fare": 20.575,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 790,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Guggenheim, Mr. Benjamin",
        "Sex": "male",
        "Age": 46,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17593",
        "Fare": 79.2,
        "Cabin": "B82 B84",
        "Embarked": "C"
    },
    {
        "PassengerId": 791,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Keane, Mr. Andrew \"Andy",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 12460,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 792,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Gaskell, Mr. Alfred",
        "Sex": "male",
        "Age": 16,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 239865,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 793,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sage, Miss. Stella Anna",
        "Sex": "female",
        "Age": "",
        "SibSp": 8,
        "Parch": 2,
        "Ticket": "CA. 2343",
        "Fare": 69.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 794,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Hoyt, Mr. William Fisher",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17600",
        "Fare": 30.6958,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 795,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Dantcheff, Mr. Ristiu",
        "Sex": "male",
        "Age": 25,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349203,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 796,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Otter, Mr. Richard",
        "Sex": "male",
        "Age": 39,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 28213,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 797,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Leader, Dr. Alice (Farnham)",
        "Sex": "female",
        "Age": 49,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 17465,
        "Fare": 25.9292,
        "Cabin": "D17",
        "Embarked": "S"
    },
    {
        "PassengerId": 798,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Osman, Mrs. Mara",
        "Sex": "female",
        "Age": 31,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349244,
        "Fare": 8.6833,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 799,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Ibrahim Shawah, Mr. Yousseff",
        "Sex": "male",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2685,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 800,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Van Impe, Mrs. Jean Baptiste (Rosalie Paula Govaert)",
        "Sex": "female",
        "Age": 30,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 345773,
        "Fare": 24.15,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 801,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Ponesell, Mr. Martin",
        "Sex": "male",
        "Age": 34,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 250647,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 802,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Collyer, Mrs. Harvey (Charlotte Annie Tate)",
        "Sex": "female",
        "Age": 31,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "C.A. 31921",
        "Fare": 26.25,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 803,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Carter, Master. William Thornton II",
        "Sex": "male",
        "Age": 11,
        "SibSp": 1,
        "Parch": 2,
        "Ticket": 113760,
        "Fare": 120,
        "Cabin": "B96 B98",
        "Embarked": "S"
    },
    {
        "PassengerId": 804,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Thomas, Master. Assad Alexander",
        "Sex": "male",
        "Age": 0.42,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 2625,
        "Fare": 8.5167,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 805,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Hedman, Mr. Oskar Arvid",
        "Sex": "male",
        "Age": 27,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347089,
        "Fare": 6.975,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 806,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Johansson, Mr. Karl Johan",
        "Sex": "male",
        "Age": 31,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347063,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 807,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Andrews, Mr. Thomas Jr",
        "Sex": "male",
        "Age": 39,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 112050,
        "Fare": 0,
        "Cabin": "A36",
        "Embarked": "S"
    },
    {
        "PassengerId": 808,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Pettersson, Miss. Ellen Natalia",
        "Sex": "female",
        "Age": 18,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347087,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 809,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Meyer, Mr. August",
        "Sex": "male",
        "Age": 39,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 248723,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 810,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Chambers, Mrs. Norman Campbell (Bertha Griggs)",
        "Sex": "female",
        "Age": 33,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 113806,
        "Fare": 53.1,
        "Cabin": "E8",
        "Embarked": "S"
    },
    {
        "PassengerId": 811,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Alexander, Mr. William",
        "Sex": "male",
        "Age": 26,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 3474,
        "Fare": 7.8875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 812,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lester, Mr. James",
        "Sex": "male",
        "Age": 39,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "A/4 48871",
        "Fare": 24.15,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 813,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Slemen, Mr. Richard James",
        "Sex": "male",
        "Age": 35,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 28206,
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 814,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Andersson, Miss. Ebba Iris Alfrida",
        "Sex": "female",
        "Age": 6,
        "SibSp": 4,
        "Parch": 2,
        "Ticket": 347082,
        "Fare": 31.275,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 815,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Tomlin, Mr. Ernest Portage",
        "Sex": "male",
        "Age": 30.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 364499,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 816,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Fry, Mr. Richard",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 112058,
        "Fare": 0,
        "Cabin": "B102",
        "Embarked": "S"
    },
    {
        "PassengerId": 817,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Heininen, Miss. Wendla Maria",
        "Sex": "female",
        "Age": 23,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "STON/O2. 3101290",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 818,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Mallet, Mr. Albert",
        "Sex": "male",
        "Age": 31,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "S.C./PARIS 2079",
        "Fare": 37.0042,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 819,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Holm, Mr. John Fredrik Alexander",
        "Sex": "male",
        "Age": 43,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C 7075",
        "Fare": 6.45,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 820,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Skoog, Master. Karl Thorsten",
        "Sex": "male",
        "Age": 10,
        "SibSp": 3,
        "Parch": 2,
        "Ticket": 347088,
        "Fare": 27.9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 821,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Hays, Mrs. Charles Melville (Clara Jennings Gregg)",
        "Sex": "female",
        "Age": 52,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 12749,
        "Fare": 93.5,
        "Cabin": "B69",
        "Embarked": "S"
    },
    {
        "PassengerId": 822,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Lulic, Mr. Nikola",
        "Sex": "male",
        "Age": 27,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 315098,
        "Fare": 8.6625,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 823,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Reuchlin, Jonkheer. John George",
        "Sex": "male",
        "Age": 38,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 19972,
        "Fare": 0,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 824,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Moor, Mrs. (Beila)",
        "Sex": "female",
        "Age": 27,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 392096,
        "Fare": 12.475,
        "Cabin": "E121",
        "Embarked": "S"
    },
    {
        "PassengerId": 825,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Panula, Master. Urho Abraham",
        "Sex": "male",
        "Age": 2,
        "SibSp": 4,
        "Parch": 1,
        "Ticket": 3101295,
        "Fare": 39.6875,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 826,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Flynn, Mr. John",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 368323,
        "Fare": 6.95,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 827,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lam, Mr. Len",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 1601,
        "Fare": 56.4958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 828,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Mallet, Master. Andre",
        "Sex": "male",
        "Age": 1,
        "SibSp": 0,
        "Parch": 2,
        "Ticket": "S.C./PARIS 2079",
        "Fare": 37.0042,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 829,
        "Survived": 1,
        "Pclass": 3,
        "Name": "McCormack, Mr. Thomas Joseph",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 367228,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 830,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Stone, Mrs. George Nelson (Martha Evelyn)",
        "Sex": "female",
        "Age": 62,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113572,
        "Fare": 80,
        "Cabin": "B28",
        "Embarked": ""
    },
    {
        "PassengerId": 831,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Yasbeck, Mrs. Antoni (Selini Alexander)",
        "Sex": "female",
        "Age": 15,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 2659,
        "Fare": 14.4542,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 832,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Richards, Master. George Sibley",
        "Sex": "male",
        "Age": 0.83,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 29106,
        "Fare": 18.75,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 833,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Saad, Mr. Amin",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2671,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 834,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Augustsson, Mr. Albert",
        "Sex": "male",
        "Age": 23,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347468,
        "Fare": 7.8542,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 835,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Allum, Mr. Owen George",
        "Sex": "male",
        "Age": 18,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2223,
        "Fare": 8.3,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 836,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Compton, Miss. Sara Rebecca",
        "Sex": "female",
        "Age": 39,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": "PC 17756",
        "Fare": 83.1583,
        "Cabin": "E49",
        "Embarked": "C"
    },
    {
        "PassengerId": 837,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Pasic, Mr. Jakob",
        "Sex": "male",
        "Age": 21,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 315097,
        "Fare": 8.6625,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 838,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sirota, Mr. Maurice",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 392092,
        "Fare": 8.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 839,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Chip, Mr. Chang",
        "Sex": "male",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 1601,
        "Fare": 56.4958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 840,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Marechal, Mr. Pierre",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 11774,
        "Fare": 29.7,
        "Cabin": "C47",
        "Embarked": "C"
    },
    {
        "PassengerId": 841,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Alhomaki, Mr. Ilmari Rudolf",
        "Sex": "male",
        "Age": 20,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/O2 3101287",
        "Fare": 7.925,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 842,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Mudd, Mr. Thomas Charles",
        "Sex": "male",
        "Age": 16,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "S.O./P.P. 3",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 843,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Serepeca, Miss. Augusta",
        "Sex": "female",
        "Age": 30,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113798,
        "Fare": 31,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 844,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Lemberopolous, Mr. Peter L",
        "Sex": "male",
        "Age": 34.5,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2683,
        "Fare": 6.4375,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 845,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Culumovic, Mr. Jeso",
        "Sex": "male",
        "Age": 17,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 315090,
        "Fare": 8.6625,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 846,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Abbing, Mr. Anthony",
        "Sex": "male",
        "Age": 42,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A. 5547",
        "Fare": 7.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 847,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sage, Mr. Douglas Bullen",
        "Sex": "male",
        "Age": "",
        "SibSp": 8,
        "Parch": 2,
        "Ticket": "CA. 2343",
        "Fare": 69.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 848,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Markoff, Mr. Marin",
        "Sex": "male",
        "Age": 35,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349213,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 849,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Harper, Rev. John",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 248727,
        "Fare": 33,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 850,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Goldenberg, Mrs. Samuel L (Edwiga Grabowska)",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 17453,
        "Fare": 89.1042,
        "Cabin": "C92",
        "Embarked": "C"
    },
    {
        "PassengerId": 851,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Andersson, Master. Sigvard Harald Elias",
        "Sex": "male",
        "Age": 4,
        "SibSp": 4,
        "Parch": 2,
        "Ticket": 347082,
        "Fare": 31.275,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 852,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Svensson, Mr. Johan",
        "Sex": "male",
        "Age": 74,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 347060,
        "Fare": 7.775,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 853,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Boulos, Miss. Nourelain",
        "Sex": "female",
        "Age": 9,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 2678,
        "Fare": 15.2458,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 854,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Lines, Miss. Mary Conover",
        "Sex": "female",
        "Age": 16,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": "PC 17592",
        "Fare": 39.4,
        "Cabin": "D28",
        "Embarked": "S"
    },
    {
        "PassengerId": 855,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Carter, Mrs. Ernest Courtenay (Lilian Hughes)",
        "Sex": "female",
        "Age": 44,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 244252,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 856,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Aks, Mrs. Sam (Leah Rosen)",
        "Sex": "female",
        "Age": 18,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 392091,
        "Fare": 9.35,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 857,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Wick, Mrs. George Dennick (Mary Hitchcock)",
        "Sex": "female",
        "Age": 45,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 36928,
        "Fare": 164.8667,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 858,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Daly, Mr. Peter Denis",
        "Sex": "male",
        "Age": 51,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 113055,
        "Fare": 26.55,
        "Cabin": "E17",
        "Embarked": "S"
    },
    {
        "PassengerId": 859,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Baclini, Mrs. Solomon (Latifa Qurban)",
        "Sex": "female",
        "Age": 24,
        "SibSp": 0,
        "Parch": 3,
        "Ticket": 2666,
        "Fare": 19.2583,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 860,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Razi, Mr. Raihed",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2629,
        "Fare": 7.2292,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 861,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Hansen, Mr. Claus Peter",
        "Sex": "male",
        "Age": 41,
        "SibSp": 2,
        "Parch": 0,
        "Ticket": 350026,
        "Fare": 14.1083,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 862,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Giles, Mr. Frederick Edward",
        "Sex": "male",
        "Age": 21,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": 28134,
        "Fare": 11.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 863,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Swift, Mrs. Frederick Joel (Margaret Welles Barron)",
        "Sex": "female",
        "Age": 48,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 17466,
        "Fare": 25.9292,
        "Cabin": "D17",
        "Embarked": "S"
    },
    {
        "PassengerId": 864,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sage, Miss. Dorothy Edith \"Dolly",
        "Sex": "female",
        "Age": "",
        "SibSp": 8,
        "Parch": 2,
        "Ticket": "CA. 2343",
        "Fare": 69.55,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 865,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Gill, Mr. John William",
        "Sex": "male",
        "Age": 24,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 233866,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 866,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Bystrom, Mrs. (Karolina)",
        "Sex": "female",
        "Age": 42,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 236852,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 867,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Duran y More, Miss. Asuncion",
        "Sex": "female",
        "Age": 27,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "SC/PARIS 2149",
        "Fare": 13.8583,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 868,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Roebling, Mr. Washington Augustus II",
        "Sex": "male",
        "Age": 31,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "PC 17590",
        "Fare": 50.4958,
        "Cabin": "A24",
        "Embarked": "S"
    },
    {
        "PassengerId": 869,
        "Survived": 0,
        "Pclass": 3,
        "Name": "van Melkebeke, Mr. Philemon",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 345777,
        "Fare": 9.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 870,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Johnson, Master. Harold Theodor",
        "Sex": "male",
        "Age": 4,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 347742,
        "Fare": 11.1333,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 871,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Balkic, Mr. Cerin",
        "Sex": "male",
        "Age": 26,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349248,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 872,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Beckwith, Mrs. Richard Leonard (Sallie Monypeny)",
        "Sex": "female",
        "Age": 47,
        "SibSp": 1,
        "Parch": 1,
        "Ticket": 11751,
        "Fare": 52.5542,
        "Cabin": "D35",
        "Embarked": "S"
    },
    {
        "PassengerId": 873,
        "Survived": 0,
        "Pclass": 1,
        "Name": "Carlsson, Mr. Frans Olof",
        "Sex": "male",
        "Age": 33,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 695,
        "Fare": 5,
        "Cabin": "B51 B53 B55",
        "Embarked": "S"
    },
    {
        "PassengerId": 874,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Vander Cruyssen, Mr. Victor",
        "Sex": "male",
        "Age": 47,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 345765,
        "Fare": 9,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 875,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Abelson, Mrs. Samuel (Hannah Wizosky)",
        "Sex": "female",
        "Age": 28,
        "SibSp": 1,
        "Parch": 0,
        "Ticket": "P/PP 3381",
        "Fare": 24,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 876,
        "Survived": 1,
        "Pclass": 3,
        "Name": "Najib, Miss. Adele Kiamie \"Jane",
        "Sex": "female",
        "Age": 15,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 2667,
        "Fare": 7.225,
        "Cabin": "",
        "Embarked": "C"
    },
    {
        "PassengerId": 877,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Gustafsson, Mr. Alfred Ossian",
        "Sex": "male",
        "Age": 20,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 7534,
        "Fare": 9.8458,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 878,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Petroff, Mr. Nedelio",
        "Sex": "male",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349212,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 879,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Laleff, Mr. Kristo",
        "Sex": "male",
        "Age": "",
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349217,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 880,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Potter, Mrs. Thomas Jr (Lily Alexenia Wilson)",
        "Sex": "female",
        "Age": 56,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 11767,
        "Fare": 83.1583,
        "Cabin": "C50",
        "Embarked": "C"
    },
    {
        "PassengerId": 881,
        "Survived": 1,
        "Pclass": 2,
        "Name": "Shelley, Mrs. William (Imanita Parrish Hall)",
        "Sex": "female",
        "Age": 25,
        "SibSp": 0,
        "Parch": 1,
        "Ticket": 230433,
        "Fare": 26,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 882,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Markun, Mr. Johann",
        "Sex": "male",
        "Age": 33,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 349257,
        "Fare": 7.8958,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 883,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Dahlberg, Miss. Gerda Ulrika",
        "Sex": "female",
        "Age": 22,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 7552,
        "Fare": 10.5167,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 884,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Banfield, Mr. Frederick James",
        "Sex": "male",
        "Age": 28,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "C.A./SOTON 34068",
        "Fare": 10.5,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 885,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Sutehall, Mr. Henry Jr",
        "Sex": "male",
        "Age": 25,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": "SOTON/OQ 392076",
        "Fare": 7.05,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 886,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Rice, Mrs. William (Margaret Norton)",
        "Sex": "female",
        "Age": 39,
        "SibSp": 0,
        "Parch": 5,
        "Ticket": 382652,
        "Fare": 29.125,
        "Cabin": "",
        "Embarked": "Q"
    },
    {
        "PassengerId": 887,
        "Survived": 0,
        "Pclass": 2,
        "Name": "Montvila, Rev. Juozas",
        "Sex": "male",
        "Age": 27,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 211536,
        "Fare": 13,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 888,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Graham, Miss. Margaret Edith",
        "Sex": "female",
        "Age": 19,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 112053,
        "Fare": 30,
        "Cabin": "B42",
        "Embarked": "S"
    },
    {
        "PassengerId": 889,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Johnston, Miss. Catherine Helen \"Carrie",
        "Sex": "female",
        "Age": "",
        "SibSp": 1,
        "Parch": 2,
        "Ticket": "W./C. 6607",
        "Fare": 23.45,
        "Cabin": "",
        "Embarked": "S"
    },
    {
        "PassengerId": 890,
        "Survived": 1,
        "Pclass": 1,
        "Name": "Behr, Mr. Karl Howell",
        "Sex": "male",
        "Age": 26,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 111369,
        "Fare": 30,
        "Cabin": "C148",
        "Embarked": "C"
    },
    {
        "PassengerId": 891,
        "Survived": 0,
        "Pclass": 3,
        "Name": "Dooley, Mr. Patrick",
        "Sex": "male",
        "Age": 32,
        "SibSp": 0,
        "Parch": 0,
        "Ticket": 370376,
        "Fare": 7.75,
        "Cabin": "",
        "Embarked": "Q"
    }
]