import React, { FC, ReactNode, useState } from "react";

import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { ProjectTypes } from "../../../models/ProjectTypes";
import { useAuth } from "../../../context/store/authContext";
import { PermissionTypes } from "../../../permissions";
import { useLocation, useNavigate } from "react-router-dom";
import { menuItems } from "../../NavBar";
import { useProject } from "../../../context/store/projectContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faCubes,
  faFileMedical,
  faHome,
  faMedkit,
  faMoneyBill,
  faShip,
} from "@fortawesome/free-solid-svg-icons";
import { useMobile } from "../../../hooks/useMobile";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon: React.ReactNode,
  permission: PermissionTypes,
  isDesktop?: boolean,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    permission,
    isDesktop,
  } as MenuItem;
}

type Props = {
  children: ReactNode;
};

const LayoutSider: FC<Props> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true);
  const { loggedInUserData } = useAuth();
  const { projectType, onChangeProjectType } = useProject();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useMobile();

  const setSubMenu = (projectType: ProjectTypes): MenuItem[] =>
    [...menuItems]
      .filter(
        (item) =>
          (!item.permissionType ||
            (loggedInUserData &&
              loggedInUserData?.permissions?.includes(item.permissionType))) &&
          projectType === item.projectType
      )
      .map((item) =>
        getItem(item.label, item.to, undefined, item.permissionType)
      );

  const setMenuItems = (isDesktop = true): MenuItem[] => [
    getItem(
      "Base",
      ProjectTypes.Base.toString(),
      <FontAwesomeIcon icon={faHome} />,
      PermissionTypes.ViewBasePagesTab,
      isDesktop,
      isDesktop ? undefined : setSubMenu(ProjectTypes.Base)
    ),
    getItem(
      "Yevmiye",
      ProjectTypes.Yevmiye.toString(),
      <FontAwesomeIcon icon={faMoneyBill} />,
      PermissionTypes.ViewYevmiyePagesTab,
      isDesktop,
      isDesktop ? undefined : setSubMenu(ProjectTypes.Yevmiye)
    ),
    getItem(
      "Titanic Passengers",
      ProjectTypes.DataAnalysis.toString(),
      <FontAwesomeIcon icon={faShip} />,
      PermissionTypes.ViewDataAnalysisPagesTab,
      isDesktop,
      isDesktop ? undefined : setSubMenu(ProjectTypes.DataAnalysis)
    ),
    getItem(
      "Health",
      ProjectTypes.Health.toString(),
      <FontAwesomeIcon icon={faMedkit} />,
      PermissionTypes.ViewHealthPagesTab,
      isDesktop,
      isDesktop ? undefined : setSubMenu(ProjectTypes.Health)
    ),
    getItem(
      "Yatırım Fonları",
      ProjectTypes.Fund.toString(),
      <FontAwesomeIcon icon={faChartLine} />,
      PermissionTypes.ViewFundPagesTab,
      isDesktop,
      isDesktop ? undefined : setSubMenu(ProjectTypes.Fund)
    ),
    getItem(
      "Hastane Yönetimi",
      ProjectTypes.Hospital.toString(),
      <FontAwesomeIcon icon={faFileMedical} />,
      PermissionTypes.ViewHospitalPagesTab,
      isDesktop,
      isDesktop ? undefined : setSubMenu(ProjectTypes.Hospital)
    ),
    getItem(
      "Hospital Management",
      ProjectTypes.HospitalInEng.toString(),
      <FontAwesomeIcon icon={faFileMedical} />,
      PermissionTypes.ViewHospitalInEngPagesTab,
      isDesktop,
      isDesktop ? undefined : setSubMenu(ProjectTypes.HospitalInEng)
    ),
    getItem(
      "Work Cube",
      ProjectTypes.WorkCube.toString(),
      <FontAwesomeIcon icon={faCubes} />,
      PermissionTypes.ViewWorkCubePagesTab,
      isDesktop,
      isDesktop ? undefined : setSubMenu(ProjectTypes.WorkCube)
    ),
  ];

  const onSelect = (key: string, isMainMenu: boolean) => {
    if (isMainMenu) {
      onChangeProjectType(Number(key));
      const page = menuItems.find(
        (item) =>
          item.projectType === Number(key) &&
          loggedInUserData?.permissions?.includes(item.permissionType)
      )?.to;
      if (page) {
        navigate(`${page}?project_type=${key}`);
      }
    } else {
      navigate(`${key}?project_type=${projectType}`);
    }
  };

  return (
    <Layout style={{ width: "100vw", overflow: "auto" }}>
      {!isMobile ? (
        <Sider
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Menu
            theme="dark"
            selectedKeys={[projectType.toString()]}
            mode="inline"
            items={setMenuItems().filter((item) =>
              loggedInUserData?.permissions?.includes((item as any)!.permission)
            )}
            onSelect={(info) =>
              onSelect(info.key, (info?.item as any)?.props?.isDesktop)
            }
          />
        </Sider>
      ) : (
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Menu
            theme="dark"
            selectedKeys={[projectType.toString(), location.pathname]}
            mode="inline"
            items={setMenuItems(false).filter((item) =>
              loggedInUserData?.permissions?.includes((item as any)!.permission)
            )}
            onSelect={(info) =>
              onSelect(info.key, (info?.item as any)?.props?.isDesktop)
            }
            openKeys={isMobile && !collapsed ? [projectType.toString()] : undefined}
            onOpenChange={(openKeys) => {
              const newKey = openKeys.find((key) => key !== projectType.toString());
              if (newKey) {
                onSelect(newKey, true);
              }
            }}
          />
        </Sider>
      )}
      <Layout>{children}</Layout>
    </Layout>
  );
};

export default LayoutSider;
