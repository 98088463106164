import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { cloneElement } from 'react';
import { Tabs } from 'antd';
import { DraggableTabPaneProps, TabItemModel, TargetKey } from './types';

const DraggableTabNode = ({ className, ...props }: DraggableTabPaneProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props['data-node-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleX: 1 }),
    transition,
    cursor: 'move',
  };

  return React.cloneElement(props.children as React.ReactElement, {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
  });
};

interface Props {
    onDragAndRemove: (items: TabItemModel[]) => void;
    items: TabItemModel[];
    activeKey?: string;
    onChangeActiveKey: (key: string) => void;
}

const DraggableTabs: React.FC<Props> = ({ onDragAndRemove, items, activeKey, onChangeActiveKey }) => {
  const sensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      let existItems = items;
      const activeIndex = existItems.findIndex((i) => i.key === active.id);
      const overIndex = existItems.findIndex((i) => i.key === over?.id);
      existItems = arrayMove(existItems, activeIndex, overIndex);
      onDragAndRemove(existItems);
    }
  };

  const remove = (targetKey: TargetKey) => {
    const targetIndex = items.findIndex((pane) => pane.key === targetKey);
    const newPanes = items.filter((pane) => pane.key !== targetKey);
    if (newPanes.length && targetKey === activeKey) {
      const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
      onChangeActiveKey(key);
    }
    onDragAndRemove(newPanes);
  };

  const onEdit = (targetKey: TargetKey, action: 'add' | 'remove') => {
    remove(targetKey);
  };

  const onTabClick = (key: string) => {
    onChangeActiveKey(key);
  };

  return (
    <Tabs
      hideAdd
      items={items}
      type="editable-card"
      onEdit={onEdit}
      activeKey={activeKey}
      onTabClick={onTabClick}
      renderTabBar={(tabBarProps, DefaultTabBar) => (
        <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
          <SortableContext items={items.map((i) => i.key)} strategy={horizontalListSortingStrategy}>
            <DefaultTabBar {...tabBarProps}>
              {(node) => (
                <DraggableTabNode {...node.props} key={node.key}>
                  {cloneElement(node, {
                    title: items?.find((item) => item.key === node.key)?.title ?? ''
                  })}
                </DraggableTabNode>
              )}
            </DefaultTabBar>
          </SortableContext>
        </DndContext>
      )}
    />
  );
};

export default DraggableTabs;