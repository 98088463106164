import React from "react";
import LoginPage from "./pages/Login";
import SignUp from "./pages/SignUp";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// import Home from "./pages/Home";
import PrivateRoute from "./routes";
import Layout from "./components/Layout";
import BaseAssignments from "./pages/BaseAssignments";
import BaseAssignmentDetail from "./pages/BaseAssignmentDetail";
import BaseQuestionCollections from "./pages/BaseQuestionCollections";
import BaseDashboard from "./pages/BaseDashboard";
import BaseDocumentCollections from "./pages/BaseDocumentCollections";
import DataAnalysis from "./pages/DataAnalysis";
import YevmiyeAnalysis from "./pages/Yevmiye";
import YevmiyeAnalysisSQL from "./pages/YevmiyeSQL";
import YevmiyeQueryLogs from "./pages/YevmiyeQueryLogs";
import YevmiyeSQLQueryLogs from "./pages/YevmiyeSQLQueryLogs";
import { PermissionTypes } from "./permissions";
import HealthCreatePatient from "./pages/HealthCreatePatient";
import HealthPatients from "./pages/HealthPatients";
import HealthUpdatePatient from "./pages/HealthUpdatePatient";
import HealthPatientDetail from "./pages/HealthPatientDetail";
import HealthLLMLogs from "./pages/HealthLLMLogs";
import Home from "./pages/Home";
import HealthQueryLogs from "./pages/HealthQueryLogs";
import HealthInstruction from "./pages/HealthInstructions";
import HealthChatQueryLogs from "./pages/HealthChatQueryLogs";
import FundQuery from "./pages/FundQuery";
import FundLogs from "./pages/FundLogs";
import FundLLMLogs from "./pages/FundLLMLogs";
import HospitalQuery from "./pages/HospitalQuery";
import HospitalLogs from "./pages/HospitalLogs";
import HospitalLLMLogs from "./pages/HospitalLLMLogs";
import BaseCollectionDetail from "./pages/BaseCollectionDetail";
import BaseQueryLogs from "./pages/BaseQueryLogs";
import BaseLLMLogs from "./pages/BaseLLMLogs";
import DataAnalysisLogs from "./pages/DataAnalysisLogs";
import DataAnalysisLLMLogs from "./pages/DataAnalysisLLMLogs";
import YevmiyeLLMLogs from "./pages/YevmiyeLLMLogs";
import YevmiyeSQLLLMLogs from "./pages/YevmiyeSQLLLMLogs";
import HospitalInEngQuery from "./pages/HospitalInEngQuery";
import HospitalInEngLogs from "./pages/HospitalInEngLogs";
import HospitalInEngLLMLogs from "./pages/HospitalInEngLLMLogs";
import WorkCubeQuery from "./pages/WorkCubeQuery";
import WorkCubeLogs from "./pages/WorkCubeLogs";
import WorkCubeLLMLogs from "./pages/WorkCubeLLMLogs";

const App: React.FC = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/" element={<Navigate to={"home"} />} />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Layout>
                  <Home />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/base-assignments"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewBaseAssignmentsPage}>
                <Layout>
                  <BaseAssignments />
                </Layout>
              </PrivateRoute>
            }
          />

          <Route
            path="/base-dashboard"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewBaseDashboardPage}>
                <Layout>
                  <BaseDashboard />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/base-query-logs"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewBaseQueryLogsPage}>
                <Layout>
                  <BaseQueryLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/base-llm-logs"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewBaseLLMLogsPage}>
                <Layout>
                  <BaseLLMLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/base-assignments/:quizId"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewBaseAssignmentsPage}>
                <Layout>
                  <BaseAssignmentDetail />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/base-collections/:collectionId"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewBaseDocumentCollectionsPage}
              >
                <Layout>
                  <BaseCollectionDetail />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/base-question-collections"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewBaseQuestionCollectionPage}>
                <Layout>
                  <BaseQuestionCollections />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/health-create-patient"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewHealthPatientPages}>
                <Layout>
                  <HealthCreatePatient />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/health-update-patient/:id"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewHealthPatientPages}>
                <Layout>
                  <HealthUpdatePatient />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/health-patients"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewHealthPatientPages}>
                <Layout>
                  <HealthPatients />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/health-instructions"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewHealthInstructionsPages}
              >
                <Layout>
                  <HealthInstruction />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/health-patients/:id"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewHealthPatientPages}>
                <Layout>
                  <HealthPatientDetail />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/base-document-collections"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewBaseDocumentCollectionsPage}
              >
                <Layout>
                  <BaseDocumentCollections />
                </Layout>
              </PrivateRoute>
            }
          />

          <Route
            path="/data-analysis"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewDataAnalysisPage}
              >
                <Layout>
                  <DataAnalysis />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/data-analysis-logs"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewDataAnalysisLogsPage}
              >
                <Layout>
                  <DataAnalysisLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/data-analysis-llm-logs"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewDataAnalysisLLMLogsPage}
              >
                <Layout>
                  <DataAnalysisLLMLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/health-logs"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewHealthLLMLogsPage}>
                <Layout>
                  <HealthLLMLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/yevmiye-query-logs"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewYevmiyeQueryLogsPage}>
                <Layout>
                  <YevmiyeQueryLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/yevmiye-llm-logs"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewYevmiyeLLMLogsPage}>
                <Layout>
                  <YevmiyeLLMLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/yevmiye-sql-query-logs"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewYevmiyeSQLQueryLogsPage}
              >
                <Layout>
                  <YevmiyeSQLQueryLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/yevmiye-sql-llm-logs"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewYevmiyeSQLLLMLogsPage}>
                <Layout>
                  <YevmiyeSQLLLMLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/health-query-logs"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewHealthQueryLogsPage}
              >
                <Layout>
                  <HealthQueryLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/health-chat-query-logs"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewHealthChatQueryLogsPage}
              >
                <Layout>
                  <HealthChatQueryLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/yevmiye"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewYevmiyePage}>
                <Layout>
                  <YevmiyeAnalysis />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/yevmiye-sql"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewYevmiyeSQLPage}>
                <Layout>
                  <YevmiyeAnalysisSQL />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/fund-query"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewFundQueryPage}>
                <Layout>
                  <FundQuery />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/fund-logs"
            element={
              <PrivateRoute permissionType={PermissionTypes.ViewFundLogsPage}>
                <Layout>
                  <FundLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/fund-llm-logs"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewFundLLMLogsPage}
              >
                <Layout>
                  <FundLLMLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/hospital-query"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewHospitalQueryPage}
              >
                <Layout>
                  <HospitalQuery />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/hospital-logs"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewHospitalLogsPage}
              >
                <Layout>
                  <HospitalLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/hospital-llm-logs"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewHospitalLLMLogsPage}
              >
                <Layout>
                  <HospitalLLMLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/hospital-in-eng-query"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewHospitalInEngQueryPage}
              >
                <Layout>
                  <HospitalInEngQuery />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/hospital-in-eng-logs"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewHospitalInEngLogsPage}
              >
                <Layout>
                  <HospitalInEngLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/hospital-in-eng-llm-logs"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewHospitalInEngLLMLogsPage}
              >
                <Layout>
                  <HospitalInEngLLMLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/workcube-query"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewWorkCubeQueryPage}
              >
                <Layout>
                  <WorkCubeQuery />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/workcube-logs"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewWorkCubeLogsPage}
              >
                <Layout>
                  <WorkCubeLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/workcube-llm-logs"
            element={
              <PrivateRoute
                permissionType={PermissionTypes.ViewWorkCubeLLMLogsPage}
              >
                <Layout>
                  <WorkCubeLLMLogs />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={<div style={{ textAlign: "center" }}>Page Not Found</div>}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
