import React, { memo, useMemo, useState } from "react";
import "./style.scss";
import { toast } from "react-toastify";
import DataAnalysisTable from "./DataTable";
import CollectionAWSService from "../../services/ui-services/collectionService/index";
import Tab from "../../components/Tab";
import { TabItem } from "../../components/Tab/types";
import { Tabs } from "../../models/Tabs";
import PlotQueryForm from "./PlotQueryForm";
import TextQueryForm from "./TextQueryForm";
import Loader from "../../components/Loading/Loader";

const tabItems: TabItem[] = [
  { id: Tabs.TextQuery, name: "Sample Text Queries" },
  { id: Tabs.PlotQuery, name: "Sample Plot Queries" },
];

const DataAnalysis: React.FC = () => {
  const collectionAWSService = useMemo(() => {
    return new CollectionAWSService();
  }, []);

  const [query, setQuery] = useState<string>("");
  const [queryResponse, setQueryResponse] = useState<string>("");
  const [plotQuery, setPlotQuery] = useState<string>("");
  const [plotQueryResponse, setPlotQueryResponse] = useState<string>("");
  const [activeTab, setActiveTab] = useState<number>(Tabs.TextQuery);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sendQuery = async () => {
    try {
      setIsLoading(true);
      await collectionAWSService.dataInsight(query).then((response) => {
        if (!response.result_status) {
          toast.error(response.error_message);
        } else {
          // setQuery("");
          setQueryResponse(response.answer);
        }
      });
    } catch (error) {
      toast.error((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const sendPlotQuery = async () => {
    try {
      setIsLoading(true);
      await collectionAWSService
        .dataInsightWithPlot(plotQuery)
        .then((response) => {
          if (!response.result_status) {
            toast.error(response.error_message);
          } else {
            const fileUrl =
              process.env.REACT_APP_S3_DATA_INSIGHT_URL + response.file_name;
            // setPlotQuery("");
            setPlotQueryResponse(fileUrl);
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onTabClick = (id: number) => setActiveTab(id);

  return (
    <div className="data-analysis-page-container">
      <div className="left-side">
        <div className="tab-container">
          <Tab activeTab={activeTab} tabs={tabItems} onTabClick={onTabClick} />
        </div>
        {activeTab === Tabs.PlotQuery && (
          <PlotQueryForm
            handlePlotQuery={sendPlotQuery}
            plotQuery={plotQuery}
            setPlotQuery={setPlotQuery}
            responseData={plotQueryResponse}
            isLoading={isLoading}
          />
        )}
        {activeTab === Tabs.TextQuery && (
          <TextQueryForm
            handleTextQuery={sendQuery}
            textQuery={query}
            setTextQuery={setQuery}
            responseData={queryResponse}
            isLoading={isLoading}
          />
        )}
        {isLoading && (
          <div className="loading-container">
            <Loader />
          </div>
        )}
      </div>
      <div className="right-side">
        <div className="action-container">
          <a
            className="download-data"
            href={`${process.env.REACT_APP_S3_BASE_URL}csv_files/titanic.xlsx`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Titanic Passenger Data
          </a>
        </div>
        <DataAnalysisTable />
      </div>
    </div>
  );
};

export default memo(DataAnalysis);
