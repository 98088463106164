import React from "react";
import "./style.scss";
import { dataAnalysis } from "./data";
import DinamicTable from "../../../components/DinamicTable";
import { IDinamicTableHeader } from "../../../components/DinamicTable/types";

const DataAnalysisTable: React.FC = () => {
  const quizForData = dataAnalysis.map((column) => {
    return {
      passengerId: column.PassengerId,
      survived: column.Survived,
      pclass: column.Pclass,
      name: column.Name,
      age: column.Age,
      sex: column.Sex,
      sibSp: column.SibSp,
      parch: column.Parch,
      ticket: column.Ticket,
      fare: column.Fare,
      cabin: column.Cabin,
      embarked: column.Embarked,
    };
  });

  const quizForColumns: IDinamicTableHeader[] = [
    {
      label: "PassengerId",
      key: "passengerId",
    },
    {
      label: "Survived",
      key: "survived",
    },
    {
      label: "Pclass",
      key: "pclass",
    },
    { label: "Name", key: "name" },
    { label: "Sex", key: "sex" },
    { label: "Age", key: "age" },
    {
      label: "SibSp",
      key: "sibSp",
    },
    {
      label: "Parch",
      key: "parch",
    },
    {
      label: "Ticket",
      key: "ticket",
    },
    {
      label: "Fare",
      key: "fare",
    },
    {
      label: "Cabin",
      key: "cabin",
    },
    {
      label: "Embarked",
      key: "embarked",
    },
  ];

  return (
    <div className="analysis-table-container">
      <DinamicTable items={quizForData} headers={quizForColumns} showDownloadButton={false} />
    </div>
  );
};

export default DataAnalysisTable;
