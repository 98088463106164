import React, { useCallback, useEffect, useState, memo, useMemo } from "react";
import "./style.scss";
import { toast } from "react-toastify";
import LogsTable from "./LogsTable";
import { useLoading } from "../../context/store/loadingContext";
import { useTranslation } from "react-i18next";
import { timestampToDate } from "../../utils/helpers";
import Dropdown, { DropdownOption } from "../../components/Dropdown";
import CSVDownloadButton from "../../components/CSVDownloadButton";
import { useUtilContext } from "../../context/store/utilContext";
import { IDataAnalysisLogTableModel } from "./types";
import { ILogResponseModel } from "../../services/ui-services/types";
import DataAnalysisLogService from "../../services/ui-services/dataAnalysisLogService";

const DataAnalysisLLMLogs: React.FC = () => {
  const dataAnalysisLogService = useMemo(() => {
    return new DataAnalysisLogService();
  }, []);

  const { onCompleted, onLoading } = useLoading();
  const { dataAnalysisLLMLogs, onChangeDataAnalysisLLMLogs } = useUtilContext();
  const { t } = useTranslation();
  const [logResponseModel, setLogResponseModel] = useState<ILogResponseModel[]>(
    []
  );
  const [logs, setLogs] = useState<IDataAnalysisLogTableModel[]>([]);
  const [filteredLogs, setFilteredLogs] = useState<IDataAnalysisLogTableModel[]>([]);
  const [modelsOptions, setModelsOptions] = useState<DropdownOption[]>([]);
  const [filterByModel, setFilterByModel] = useState<
    DropdownOption | undefined
  >(undefined);
  const defaultFilterDropdownOption: DropdownOption = {
    value: 0,
    label: t("dashboard.all"),
  };
  const logDayCount = 3;

  const getAllLogs = useCallback(
    async () => {
      try {
        onLoading();
        let response = await dataAnalysisLogService.getAllLogs(logDayCount);
        response = response.map((res) => ({
          ...res,
          llm_resp_time: Number(res.llm_resp_time),
          query_fb_path: res.query_fb_path?.replace("dataInsightCsvQueryLogs/", ""),
        }));
        onChangeDataAnalysisLLMLogs(response);
      } catch (error) {
        toast.error(t("error.tryAgain"));
      } finally {
        onCompleted();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (dataAnalysisLLMLogs.length) {
      const groupedData = groupData(dataAnalysisLLMLogs);
      const modelsOptions = separateModels(dataAnalysisLLMLogs);
      setLogResponseModel(dataAnalysisLLMLogs);
      setModelsOptions(modelsOptions);
      setLogs(groupedData);
    }
  }, [dataAnalysisLLMLogs]);

  function separateModels(models: ILogResponseModel[]): DropdownOption[] {
    const uniqueModels: { [key: string]: ILogResponseModel } = {};

    models.forEach((model) => {
      uniqueModels[model.model] = model;
    });

    return Object.keys(uniqueModels).map((modelName) => ({
      value: modelName,
      label: modelName,
    }));
  }

  const groupData = (logs: ILogResponseModel[]): IDataAnalysisLogTableModel[] => {
    const groupedData: IDataAnalysisLogTableModel[] = [];

    logs.forEach((log) => {
      const existingEntry = groupedData.find(
        (entry) =>
          entry.modelName === log.model &&
          entry.requestTime.toDateString() ===
            timestampToDate(log.requestTime)!.toDateString()
      );

      if (existingEntry) {
        existingEntry.logs.push(log);
        existingEntry.totalCompletionTokens += log.completion_tokens;
        existingEntry.totalCost += log.total_cost;
        existingEntry.totalPromptTokens += log.prompt_tokens;
        existingEntry.totalTokens += log.total_tokens;
        (existingEntry.llm_resp_time as number) += log.llm_resp_time as number;
      } else {
        groupedData.push({
          modelName: log.model,
          totalCost: log.total_cost,
          totalCompletionTokens: log.completion_tokens,
          totalTokens: log.total_tokens,
          totalPromptTokens: log.prompt_tokens,
          llm_resp_time: log.llm_resp_time,
          requestTime: timestampToDate(log.requestTime)!,
          logs: [log],
        });
      }
    });

    return groupedData;
  };

  useEffect(() => {
    if (!dataAnalysisLLMLogs.length) {
      getAllLogs();
    }
  }, [getAllLogs, dataAnalysisLLMLogs.length]);

  useEffect(() => {
    const filteredData = logs.filter((log) =>
      filterByModel?.value ? log.modelName === filterByModel.value : log
    );
    setFilteredLogs(filteredData);
  }, [filterByModel?.value, logs]);

  return (
    <div className="logs-page-container">
      <div className="actions-container">
        <CSVDownloadButton
          data={logResponseModel.map((log) => {
            return {
              model: log.model,
              requestDate: timestampToDate(log.requestTime)?.toDateString(),
              requestTime: timestampToDate(log.requestTime)?.toTimeString(),
              completionTokens: log.completion_tokens,
              promptTokens: log.prompt_tokens,
              llm_resp_time: log.llm_resp_time,
              totalCost: log.total_cost,
              totalTokens: log.total_tokens,
            };
          })}
          columns={[
            {
              label: "Model",
              key: "model",
            },
            {
              label: "Request Date",
              key: "requestDate",
            },
            {
              label: "Request Time",
              key: "requestTime",
            },
            {
              label: "LLM Response Time",
              key: "llm_resp_time",
            },
            {
              label: "Completion Tokens",
              key: "completionTokens",
            },
            {
              label: "Prompt Tokens",
              key: "promptTokens",
            },
            { label: "Total Cost", key: "totalCost" },
            { label: "Total Tokens", key: "totalTokens" },
          ]}
          filename="logs"
        />
        <Dropdown
          className="filter-dropdown"
          options={[defaultFilterDropdownOption, ...modelsOptions]}
          onSelect={setFilterByModel}
          value={filterByModel}
          label={t("logs.filterMenu.filterByModel")}
          key="packages"
        />
      </div>
      <div className="content">
        <LogsTable logs={filteredLogs} />
      </div>
    </div>
  );
};

export default memo(DataAnalysisLLMLogs);
