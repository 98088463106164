import { collection, getDocs, query, where, Timestamp, orderBy, getDoc, doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase-services";
import { QueryLogDBModel } from "./types";



class QueryLogDBServices {
  private yevmiyeCsvCollectionName = "yevmiyeCsvQueryLogs";
  private yevmiyeSqlCollectionName = "yevmiyeSqlQueryLogs";
  private fundCollectionName = "fundsQueryLogs";
  private workCubeCollectionName = "workcubeQueryLogs";
  private hospitalCollectionName = "hospitalQueryLogs";
  private hospitalInEngCollectionName = "hospitalEnglishQueryLogs";
  private baseCollectionName = "ragQueryLogs";
  private dataAnalysisCollectionName = "dataInsightCsvQueryLogs";

  private logDBRefCSV = collection(db, this.yevmiyeCsvCollectionName);
  private logDBRefSQL = collection(db, this.yevmiyeSqlCollectionName);
  private logDBRefFund = collection(db, this.fundCollectionName);
  private logDBRefWorkCube = collection(db, this.workCubeCollectionName);
  private logDBRefHospital = collection(db, this.hospitalCollectionName);
  private logDBRefHospitalInEng = collection(db, this.hospitalInEngCollectionName);
  private logDBRefBase = collection(db, this.baseCollectionName);
  private logDBRefDataAnalysis = collection(db, this.dataAnalysisCollectionName);

  async getAllQueryLogs(dayAgo = 3): Promise<QueryLogDBModel[]> {
    try {
      const date = new Date();
      date.setDate(date.getDate() - dayAgo);

      const querySnapshot = await getDocs(
        query(this.logDBRefCSV,
        where('requestTime', '>=', Timestamp.fromDate(date)),
        orderBy('requestTime', 'desc'))
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllDataAnalysisQueryLogs(dayAgo = 3): Promise<QueryLogDBModel[]> {
    try {
      const date = new Date();
      date.setDate(date.getDate() - dayAgo);

      const querySnapshot = await getDocs(
        query(this.logDBRefDataAnalysis,
        where('requestTime', '>=', Timestamp.fromDate(date)),
        orderBy('requestTime', 'desc'))
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllFundQueryLogs(dayAgo = 3): Promise<QueryLogDBModel[]> {
    try {
      const date = new Date();
      date.setDate(date.getDate() - dayAgo);

      const querySnapshot = await getDocs(
        query(this.logDBRefFund,
        where('requestTime', '>=', Timestamp.fromDate(date)),
        orderBy('requestTime', 'desc'))
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllWorkCubeQueryLogs(dayAgo = 3): Promise<QueryLogDBModel[]> {
    try {
      const date = new Date();
      date.setDate(date.getDate() - dayAgo);

      const querySnapshot = await getDocs(
        query(this.logDBRefWorkCube,
        where('requestTime', '>=', Timestamp.fromDate(date)),
        orderBy('requestTime', 'desc'))
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllBaseQueryLogs(dayAgo = 3): Promise<QueryLogDBModel[]> {
    try {
      const date = new Date();
      date.setDate(date.getDate() - dayAgo);

      const querySnapshot = await getDocs(
        query(this.logDBRefBase,
        where('requestTime', '>=', Timestamp.fromDate(date)),
        orderBy('requestTime', 'desc'))
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllHospitalQueryLogs(dayAgo = 3): Promise<QueryLogDBModel[]> {
    try {
      const date = new Date();
      date.setDate(date.getDate() - dayAgo);

      const querySnapshot = await getDocs(
        query(this.logDBRefHospital,
        where('requestTime', '>=', Timestamp.fromDate(date)),
        orderBy('requestTime', 'desc'))
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllHospitalInEngQueryLogs(dayAgo = 3): Promise<QueryLogDBModel[]> {
    try {
      const date = new Date();
      date.setDate(date.getDate() - dayAgo);

      const querySnapshot = await getDocs(
        query(this.logDBRefHospitalInEng,
        where('requestTime', '>=', Timestamp.fromDate(date)),
        orderBy('requestTime', 'desc'))
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllQueryLogsWithSQL(dayAgo = 3): Promise<QueryLogDBModel[]> {
    try {
      const date = new Date();
      date.setDate(date.getDate() - dayAgo);

      const querySnapshot = await getDocs(
        query(this.logDBRefSQL,
        where('requestTime', '>=', Timestamp.fromDate(date)),
        orderBy('requestTime', 'desc'))
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogsByEmail(email: string): Promise<QueryLogDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(this.logDBRefCSV,
        where('request_email', '==', email)
        )
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getFundQueryLogsByEmail(email: string): Promise<QueryLogDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(this.logDBRefFund,
        where('request_email', '==', email)
        )
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getWorkCubeQueryLogsByEmail(email: string): Promise<QueryLogDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(this.logDBRefWorkCube,
        where('request_email', '==', email)
        )
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getDataAnalysisQueryLogsByEmail(email: string): Promise<QueryLogDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(this.logDBRefDataAnalysis,
        where('request_email', '==', email)
        )
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getBaseQueryLogsByEmail(email: string): Promise<QueryLogDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(this.logDBRefBase,
        where('request_email', '==', email)
        )
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getHospitalQueryLogsByEmail(email: string): Promise<QueryLogDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(this.logDBRefHospital,
        where('request_email', '==', email)
        )
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getHospitalInEngQueryLogsByEmail(email: string): Promise<QueryLogDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(this.logDBRefHospitalInEng,
        where('request_email', '==', email)
        )
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogsSQLByEmail(email: string): Promise<QueryLogDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(this.logDBRefSQL,
        where('request_email', '==', email)
        )
      );

      const logs: QueryLogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateById(id: string, data: QueryLogDBModel) {
    try {
      const docRef = doc(db, this.yevmiyeCsvCollectionName, id);
      await setDoc(
        docRef,
        data,
        { merge: true }
      );
      return docRef.id;
    } catch (error) {
      console.error(error);
      throw error;
    }

  }

  async updateByIdWithSQL(id: string, data: QueryLogDBModel) {
    try {
      const docRef = doc(db, this.yevmiyeSqlCollectionName, id);
      await setDoc(
        docRef,
        data,
        { merge: true }
      );
      return docRef.id;
    } catch (error) {
      console.error(error);
      throw error;
    }

  }

  async updateFundLogById(id: string, data: QueryLogDBModel) {
    try {
      const docRef = doc(db, this.fundCollectionName, id);
      await setDoc(
        docRef,
        data,
        { merge: true }
      );
      return docRef.id;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateWorkCubeLogById(id: string, data: QueryLogDBModel) {
    try {
      const docRef = doc(db, this.workCubeCollectionName, id);
      await setDoc(
        docRef,
        data,
        { merge: true }
      );
      return docRef.id;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateHospitalLogById(id: string, data: QueryLogDBModel) {
    try {
      const docRef = doc(db, this.hospitalCollectionName, id);
      await setDoc(
        docRef,
        data,
        { merge: true }
      );
      return docRef.id;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateHospitalInEngLogById(id: string, data: QueryLogDBModel) {
    try {
      const docRef = doc(db, this.hospitalInEngCollectionName, id);
      await setDoc(
        docRef,
        data,
        { merge: true }
      );
      return docRef.id;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getById(logId: string): Promise<QueryLogDBModel | null> {
    try {
        const docRef = doc(db, this.yevmiyeCsvCollectionName, logId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            const logData = docSnapshot.data() as QueryLogDBModel;
            return {
                ...logData,
                id: docSnapshot.id,
            };
        } else {
            return null
        }
    } catch (error) {
        console.error(error);
        throw error
    }
}

async getByIdWithSQL(logId: string): Promise<QueryLogDBModel | null> {
  try {
      const docRef = doc(db, this.yevmiyeSqlCollectionName, logId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
          const logData = docSnapshot.data() as QueryLogDBModel;
          return {
              ...logData,
              id: docSnapshot.id,
          };
      } else {
          return null
      }
  } catch (error) {
      console.error(error);
      throw error
  }
}

async getFundLogById(logId: string): Promise<QueryLogDBModel | null> {
  try {
      const docRef = doc(db, this.fundCollectionName, logId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
          const logData = docSnapshot.data() as QueryLogDBModel;
          return {
              ...logData,
              id: docSnapshot.id,
          };
      } else {
          return null
      }
  } catch (error) {
      console.error(error);
      throw error
  }
}

async getWorkCubeLogById(logId: string): Promise<QueryLogDBModel | null> {
  try {
      const docRef = doc(db, this.workCubeCollectionName, logId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
          const logData = docSnapshot.data() as QueryLogDBModel;
          return {
              ...logData,
              id: docSnapshot.id,
          };
      } else {
          return null
      }
  } catch (error) {
      console.error(error);
      throw error
  }
}

async getDataAnalysisLogById(logId: string): Promise<QueryLogDBModel | null> {
  try {
      const docRef = doc(db, this.dataAnalysisCollectionName, logId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
          const logData = docSnapshot.data() as QueryLogDBModel;
          return {
              ...logData,
              id: docSnapshot.id,
          };
      } else {
          return null
      }
  } catch (error) {
      console.error(error);
      throw error
  }
}

async getBaseLogById(logId: string): Promise<QueryLogDBModel | null> {
  try {
      const docRef = doc(db, this.baseCollectionName, logId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
          const logData = docSnapshot.data() as QueryLogDBModel;
          return {
              ...logData,
              id: docSnapshot.id,
          };
      } else {
          return null
      }
  } catch (error) {
      console.error(error);
      throw error
  }
}

async getHospitalLogById(logId: string): Promise<QueryLogDBModel | null> {
  try {
      const docRef = doc(db, this.hospitalCollectionName, logId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
          const logData = docSnapshot.data() as QueryLogDBModel;
          return {
              ...logData,
              id: docSnapshot.id,
          };
      } else {
          return null
      }
  } catch (error) {
      console.error(error);
      throw error
  }
}

async getHospitalInEngLogById(logId: string): Promise<QueryLogDBModel | null> {
  try {
      const docRef = doc(db, this.hospitalInEngCollectionName, logId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
          const logData = docSnapshot.data() as QueryLogDBModel;
          return {
              ...logData,
              id: docSnapshot.id,
          };
      } else {
          return null
      }
  } catch (error) {
      console.error(error);
      throw error
  }
}
}

export default QueryLogDBServices;