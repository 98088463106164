import {
  FC,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./style.scss";
import { useLoading } from "../../context/store/loadingContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { timestampToDate } from "../../utils/helpers";
import { useUtilContext } from "../../context/store/utilContext";
import Button from "../../components/Button";
import DraggableTabs from "../../components/DraggableTabs";
import { TabItemModel } from "../../components/DraggableTabs/types";
import { Select } from "antd";
import UserService from "../../services/ui-services/userService";
import { IUserModel } from "../../services/ui-services/userService/types";
import QueryHealthLogService from "../../services/ui-services/healthQueryLogsService";
import { IQueryLogHealthResponseModel } from "../../services/ui-services/healthQueryLogsService/types";
import { useSearchParams } from "react-router-dom";
import Input from "../../components/Input";

const HealthQueryLogs: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const logId = searchParams.get("log_id");

  const queryHealthLogService = useMemo(() => {
    return new QueryHealthLogService();
  }, []);
  const userService = useMemo(() => {
    return new UserService();
  }, []);
  const { onCompleted, onLoading } = useLoading();
  const { t } = useTranslation();
  const {
    healthQueryLogs,
    onChangeHealthQueryLogs,
    healthQueryLogsAnswers,
    onChangeHealthQueryLogsAnswers,
  } = useUtilContext();

  const [activeResponseTabKey, setActiveResponseTabKey] = useState<
    string | undefined
  >();
  const [queryLogId, setQueryLogId] = useState<string>("");
  const [queryLogEmail, setQueryLogEmail] = useState<string>("");
  const [queryLogsByEmail, setQueryLogsByEmail] = useState<
    IQueryLogHealthResponseModel[]
  >([]);
  const [users, setUsers] = useState<IUserModel[]>([]);
  const logDayCount = 3;
  const maxResponseCount = 100;

  const getAllUsers = useCallback(async () => {
    const res = await userService.getAllUsers();
    setUsers(res);
  }, [userService]);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  const getAllQueries = useCallback(
    async () => {
      try {
        onLoading();
        const response = await queryHealthLogService.getAllQueryLogs(
          logDayCount
        );
        onChangeHealthQueryLogs(response);
      } catch (error) {
        toast.error(t("error.tryAgain"));
      } finally {
        onCompleted();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getQueryLogsByEmail = async () => {
    try {
      onLoading();
      const response = await queryHealthLogService.getQueryLogsByEmail(
        queryLogEmail
      );
      setQueryLogEmail("");
      setQueryLogsByEmail(response);
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
  };

  useEffect(() => {
    if (!healthQueryLogs.length) {
      getAllQueries();
    }
  }, [getAllQueries, healthQueryLogs.length]);

  const onClickQuery = useCallback(
    async (log: IQueryLogHealthResponseModel) => {
      try {
        const existLog = healthQueryLogsAnswers.find(
          (answer) => answer.id === log.id
        );
        if (existLog) {
          setActiveResponseTabKey(existLog.id);
        } else {
          onLoading();
          let existAnswers = healthQueryLogsAnswers;
          existAnswers = [
            { ...log, answer: log.answer },
            ...existAnswers,
          ].slice(0, maxResponseCount);
          onChangeHealthQueryLogsAnswers(existAnswers);
          setActiveResponseTabKey(log.id);
        }
      } catch (error) {
        toast.error(t("error.tryAgain"));
      } finally {
        onCompleted();
      }
    },
    [
      onChangeHealthQueryLogsAnswers,
      onCompleted,
      onLoading,
      healthQueryLogsAnswers,
      t,
    ]
  );

  const onClickExecute = async () => {
    if (queryLogId) {
      const existLog = healthQueryLogsAnswers.find(
        (answer) => answer.id === queryLogId
      );

      if (existLog) {
        setActiveResponseTabKey(existLog.id);
        setQueryLogId("");
      } else {
        try {
          onLoading();
          const log = await queryHealthLogService.getQueryLogById(queryLogId);
          if (log) {
            await onClickQuery(log);
            setQueryLogId("");
          } else {
            toast.error(t("error.tryAgain"));
          }
        } catch (error) {
          toast.error(t("error.tryAgain"));
        } finally {
          onCompleted();
        }
      }
    }
  };

  const executeByParamsLogId = useCallback(async () => {
    if (logId) {
      const existLog = healthQueryLogsAnswers.find(
        (answer) => answer.id === logId
      );

      if (existLog) {
        setActiveResponseTabKey(existLog.id);
        searchParams.delete("log_id");
        setSearchParams(searchParams);
      } else {
        try {
          onLoading();
          const log = await queryHealthLogService.getQueryLogById(logId);
          if (log) {
            await onClickQuery(log);
            searchParams.delete("log_id");
            setSearchParams(searchParams);
          } else {
            toast.error(t("error.tryAgain"));
          }
        } catch (error) {
          toast.error(t("error.tryAgain"));
        } finally {
          onCompleted();
        }
      }
    }
  }, [
    logId,
    onClickQuery,
    onCompleted,
    onLoading,
    healthQueryLogsAnswers,
    queryHealthLogService,
    searchParams,
    setSearchParams,
    t,
  ]);

  useEffect(() => {
    executeByParamsLogId();
  }, [executeByParamsLogId]);

  const responseViewer = (
    response: IQueryLogHealthResponseModel
  ): ReactNode => {
    const {
      answer,
      prompted_query,
      requestTime,
      request_email,
      id,
      error_message,
      result_status,
      patient_id,
      query_type,
      request_rating,
      request_comment,
      exec_times_in_second,
    } = response;

    const answerTopSide = (
      <div className="question-container">
        <div className="fieldLabel">Request Id:</div>
        <div className="fieldValue">{id || ""}</div>
        <div className="fieldLabel">Request Email:</div>
        <div className="fieldValue">{request_email || ""}</div>
        <div className="fieldLabel">Request Time:</div>
        <div className="fieldValue">
          {timestampToDate(requestTime!)!.toString()}
        </div>
        <div className="fieldLabel">Execute Time In Seconds:</div>
        <div className="fieldValue">
          {JSON.stringify(exec_times_in_second || {})}
        </div>
        <div className="fieldLabel">Request Rating:</div>
        <div className="fieldValue">{request_rating || ""}</div>
        <div className="fieldLabel">Request Comment:</div>
        <div className="fieldValue">{request_comment || ""}</div>
        <div className="fieldLabel">Patient Id:</div>
        <div className="fieldValue">{patient_id || ""}</div>
        <div className="fieldLabel">Query Type:</div>
        <div className="fieldValue">{query_type || ""}</div>
        <div className="fieldLabel">Result Status:</div>
        <div className="fieldValue">{result_status ? "True" : "False"}</div>
        <div className="fieldLabel">Error Message:</div>
        <div className="fieldValue">
          <div style={{ whiteSpace: "pre-wrap" }}>{error_message || ""}</div>
        </div>
        <div className="fieldLabel">Prompted Query:</div>
        <div className="fieldValue">
          <div style={{ whiteSpace: "pre-wrap" }}>
            <code>{`${prompted_query}`}</code>
          </div>
        </div>
        <div className="fieldLabel">Answer:</div>
      </div>
    );

    if (typeof answer === "string") {
      return (
        <div>
          {answerTopSide}
          <div
            className="fieldValue"
            dangerouslySetInnerHTML={{ __html: answer }}
            style={{ whiteSpace: "normal" }}
          />
        </div>
      );
    } else {
      return (
        <div>
          {answerTopSide}
          <div className="fieldValue">{answer?.triage_level}</div>
          <div className="fieldValue" style={{ whiteSpace: "normal" }}>
            {answer?.triage_reasoning}
          </div>
        </div>
      );
    }
  };

  const setResponseTabItems = (): TabItemModel[] =>
    healthQueryLogsAnswers.map((res, i) => {
      const { id, patient_id, query_type } = res;

      return {
        key: id!,
        label: `${patient_id}-${query_type}`,
        children: responseViewer(res),
        title: `${patient_id}-${query_type}`,
      };
    });

  const onResponseTabClick = (key: string) => setActiveResponseTabKey(key);

  const onDragAndRemoveTabs = (items: TabItemModel[]) => {
    const newAnswers = items.map((item) => ({
      ...(healthQueryLogsAnswers.find((log) => log.id === item.key) ||
        ({} as IQueryLogHealthResponseModel)),
    }));
    onChangeHealthQueryLogsAnswers(newAnswers);
  };

  const setUserOptions = () =>
    users
      .filter((user) => user.email)
      .map((user) => ({
        value: user.email,
        label: user.email,
        id: user.id,
        key: user.id,
      }));

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="query-logs-page-container">
      <div className="left-side">
        <div className="inputContainer">
          <Input
            value={queryLogId}
            onChange={(val) => setQueryLogId(val as string)}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickExecute();
            }}
          />
          <Button disabled={!queryLogId} onClick={onClickExecute}>
            Search By Id
          </Button>
        </div>
        <div className="inputContainer">
          <Select
            className="user_dropdown"
            showSearch
            placeholder="Select a person"
            filterOption={filterOption}
            optionFilterProp="children"
            value={queryLogEmail}
            onChange={(val) => setQueryLogEmail(val as string)}
            options={setUserOptions()}
          ></Select>
          <Button disabled={!queryLogEmail} onClick={getQueryLogsByEmail}>
            Search By Email
          </Button>
        </div>
        <div className="prompt-container">
          <ul>
            {queryLogsByEmail
              .sort(
                (a, b) =>
                  timestampToDate(b.requestTime!)!.getTime() -
                  timestampToDate(a.requestTime!)!.getTime()
              )
              .map((log) => {
                return (
                  <li
                    style={{ border: "1px solid teal" }}
                    key={log.id}
                    onClick={() => onClickQuery(log)}
                  >
                    {`${log.patient_id}-${log.query_type}`}
                  </li>
                );
              })}
            {healthQueryLogs.map((log) => {
              return (
                <li key={log.id} onClick={() => onClickQuery(log)}>
                  {`${log.patient_id}-${log.query_type}`}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="right-side">
        <div className="response_tab_container">
          <DraggableTabs
            items={setResponseTabItems()}
            activeKey={activeResponseTabKey}
            onChangeActiveKey={onResponseTabClick}
            onDragAndRemove={onDragAndRemoveTabs}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(HealthQueryLogs);
