import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faInfoCircle,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Button";
import AutoSizeTextArea from "../../../components/TextArea";

const queryPrompt: string[] = [
  "Plot a chart for the number of survived passengers in Pclass.",
  "Create a pie chart to represent the distribution of passengers by gender.",
  "Plot the correlation heatmap of features.",
  "Plot the age distribution of the number of passengers with 10 bins.",
];

interface IProps {
  responseData: string;
  plotQuery: string;
  isLoading: boolean;
  setPlotQuery: (query: string) => void;
  handlePlotQuery: () => void;
}

const PlotQueryForm: React.FC<IProps> = ({
  handlePlotQuery,
  setPlotQuery,
  plotQuery,
  isLoading,
  responseData,
}) => {
  const { t } = useTranslation();

  return (
    <div className="plot-form-container">
      <div className="form-line">
        <div className="prompt-container">
          <ul>
            {queryPrompt.map((prompt, index) => {
              return (
                <li key={index} onClick={() => setPlotQuery(prompt)}>
                  {prompt}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="label-container">
          <span>{t("dataAnalysis.query.name")}</span>
          <Tooltip id="topN" content={t("dataAnalysis.query.label")} />
          <FontAwesomeIcon data-tooltip-id="topN" icon={faInfoCircle} />
        </div>
        <AutoSizeTextArea
          className="text-input"
          value={plotQuery}
          onChange={(e) => setPlotQuery(e.target.value)}
          autoSize={false}
          rows={5}
        />
      </div>
      <div className="send-button">
        <Button
          disabled={plotQuery.length < 1 || isLoading}
          onClick={handlePlotQuery}
          className="save-button"
          icon={faPaperPlane}
          iconSize="1x"
        />
      </div>

      {responseData && (
        <div className="response-container">
          <img className="response-img" src={responseData} alt="response" />
          <a rel="noreferrer" target="_blank" href={responseData}>
            <Button
              className="download-button"
              icon={faDownload}
              iconSize="1x"
            />
          </a>
        </div>
      )}
    </div>
  );
};

export default PlotQueryForm;
