import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Button, Input } from "antd";

const { TextArea } = Input;

const queryPrompt: string[] = [
  "What is the median age of the passengers?",
  "What is the shape of the dataframe?",
  "What are the feature names of the dataframe?",
  "How many passengers did survive?",
];

interface IProps {
  responseData: string;
  textQuery: string;
  isLoading: boolean;
  setTextQuery: (query: string) => void;
  handleTextQuery: () => void;
}

const TextQueryForm: React.FC<IProps> = ({
  handleTextQuery,
  setTextQuery,
  textQuery,
  responseData,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <div className="form-container">
      <div className="form-line">
        <div className="prompt-container">
          <ul>
            {queryPrompt.map((prompt, index) => {
              return (
                <li key={index} onClick={() => setTextQuery(prompt)}>
                  {prompt}
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <div className="label-container">
            <Tooltip id="topN" content={t("dataAnalysis.query.label")} />
            <FontAwesomeIcon data-tooltip-id="topN" icon={faInfoCircle} />
          </div>
          <TextArea
            className="text-input"
            value={textQuery}
            onChange={(e) => setTextQuery(e.target.value)}
            style={{ resize: 'none', marginTop: '5px' }}
            rows={5}
            placeholder="How can I help you?"
          />
        </div>
      </div>
      <div className="send-button">
        <Button
          disabled={textQuery.length < 1 || isLoading}
          onClick={handleTextQuery}
          className="save-button"
          icon={<FontAwesomeIcon icon={faPaperPlane} />}
          size="large"
        />
      </div>

      <div className="response-container">{responseData}</div>
    </div>
  );
};

export default TextQueryForm;
