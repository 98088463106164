import React, { ReactNode, memo, useEffect, useMemo, useState } from "react";
import "./style.scss";
import { toast } from "react-toastify";
import CollectionAWSService from "../../services/ui-services/collectionService/index";
import Tab from "../../components/Tab";
import { TabItem } from "../../components/Tab/types";
import { Tabs } from "../../models/Tabs";
// import PlotQueryForm from "./PlotQueryForm";
import TextQueryForm from "../../components/TextQueryForm";
import Loader from "../../components/Loading/Loader";
import { IQueryResponseModel, IQueryResponseWithQuestionModel } from "../../services/ui-services/collectionService/types";
import { useAuth } from "../../context/store/authContext";
import DraggableTabs from "../../components/DraggableTabs";
import { TabItemModel } from "../../components/DraggableTabs/types";
import CommentModal from "./CommentModal";
import QueryLogService from "../../services/ui-services/queryLogsService";
import { ICreateUpdateCommentInputModel } from "../../services/ui-services/queryLogsService/types";
import { useUtilContext } from "../../context/store/utilContext";
import { useTranslation } from "react-i18next";
import DinamicTable from "../../components/DinamicTable";
import { PermissionTypes } from "../../permissions";



const tabItems: TabItem[] = [
  { id: Tabs.TextQuery, name: "Text Query" },
  { id: Tabs.PlotQuery, name: "Plot Query", disabled: true },
];

const queryResponseLocalStorageKey = 'queryResponses';

const YevmiyeAnalysis: React.FC = () => {
  const { loggedInUserData } = useAuth();
  const { yevmiyeQueryLogsAnswers, onChangeYevmiyeQueryLogsAnswers } = useUtilContext();
  const { t } = useTranslation();

  const collectionAWSService = useMemo(() => {
    return new CollectionAWSService();
  }, [])

  const queryLogService = useMemo(() => {
    return new QueryLogService()
  }, [])

  const [queryResponses, setQueryResponses] = useState<IQueryResponseWithQuestionModel[]>([]);
  const [activeResponseTabKey, setActiveResponseTabKey] = useState<string>('');

  // const [plotQuery, setPlotQuery] = useState<string>("");
  // const [plotQueryResponse, setPlotQueryResponse] = useState<string>("");
  const [activeTab, setActiveTab] = useState<number>(Tabs.TextQuery);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const maxResponseCount = 100;

  useEffect(() => {
    const res = localStorage.getItem(queryResponseLocalStorageKey);
    if (res) {
      const responses = JSON.parse(res)?.filter((response: IQueryResponseModel) => response.fb_path) || [];
      setQueryResponses(responses);
      setActiveResponseTabKey((responses as IQueryResponseWithQuestionModel[])?.at(0)?.fb_path ?? '')
    }
  }, []);

  const onChangeQueryResponses = (value: IQueryResponseWithQuestionModel[]) => {
    setQueryResponses([ ...value ]);
    localStorage.setItem(queryResponseLocalStorageKey, JSON.stringify(value));
  };

  const onSentComment = async (data: IQueryResponseWithQuestionModel, successCallback: () => void) => {
    try {
      const id = data.fb_path.replace('queryLogs/', '');
      const input: ICreateUpdateCommentInputModel = {
        request_comment: data.request_comment,
        request_rating: data.request_rating
      };
      await queryLogService.createUpdateComment(id, input);

      let existResponses = queryResponses;
      const responseIndex = existResponses.findIndex((res) => res.fb_path === data.fb_path);
      if (responseIndex !== -1) {
        existResponses[responseIndex] = data;
        onChangeQueryResponses(existResponses);
      }

      let existLogAnswers = yevmiyeQueryLogsAnswers;
      const answerIndex = existLogAnswers.findIndex((answer) => answer.id === id);
      if (answerIndex !== -1) {
        existLogAnswers[answerIndex].request_comment = data.request_comment;
        existLogAnswers[answerIndex].request_rating = data.request_rating;

        onChangeYevmiyeQueryLogsAnswers(existLogAnswers);
      }

      successCallback();
    } catch (error) {
      toast.error(t("error.tryAgain"));
      console.log(error);
    }
  };

  const cacheResponses = (response: IQueryResponseModel, query: string) => {
    let existResponses = queryResponses;
    existResponses.unshift({ ...response, question: query });
    existResponses = existResponses.slice(0, maxResponseCount);
    onChangeQueryResponses(existResponses);
    setActiveResponseTabKey(response.fb_path);
  }

  const showId = () => loggedInUserData?.permissions?.includes(PermissionTypes.ViewYevmiyeQueryLogsPage);

  const sendQuery = async (query: string, successCallback: VoidFunction) => {
    try {
      setIsLoading(true);
      await collectionAWSService.accountingDataInsight(query, loggedInUserData?.email ?? '').then(
        (response) => {
          if (!response.result_status) {
            toast.error(response.error_message);
          } else {
            cacheResponses(response, query);
          }
        }
      );
      successCallback();
    } catch (error) {
      toast.error((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  // const sendPlotQuery = async () => {
  //   try {
  //     setIsLoading(true);
  //     await CollectionAWSService.dataInsightWithPlot(plotQuery).then(
  //       (response) => {
  //         if (!response.result_status) {
  //           toast.error(response.error_message);
  //         } else {
  //           const fileUrl =
  //             process.env.REACT_APP_S3_DATA_INSIGHT_URL + response.file_name;
  //           // setPlotQuery("");
  //           setPlotQueryResponse(fileUrl);
  //         }
  //       }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const onTabClick = (id: number) => setActiveTab(id);

  const responseViewer = (response: IQueryResponseWithQuestionModel): ReactNode => {
    const { answer, question, fb_path } = response;
    if (Array.isArray(answer)) {
      return <div>
        {showId() && (
          <div className="question-container">ID: {fb_path}</div>
        )}
        <div className="question-container">
          Question: {question}
        </div>
        <div>
          <CommentModal data={response} onSentComment={onSentComment} />
        </div>
        <DinamicTable items={answer} />
      </div>
    } else {
      return <div>
        {showId() && (
          <div className="question-container">ID: {fb_path}</div>
        )}
        <div className="question-container">
          Question: {question}
        </div>
        <div>
          <CommentModal data={response} onSentComment={onSentComment} />
        </div>
        <p className="answer-text">{answer}</p>
      </div>
    }
  }

  const setResponseTabItems = (): TabItemModel[] => queryResponses.slice(0, maxResponseCount).map((res, i) => {
    return {
      key: res.fb_path,
      label: res.question.substring(0, 10),
      children: responseViewer(res),
      title: res.question
    }
});

  const onDragAndRemoveTabs = (items: TabItemModel[]) => {
    const newResponses: IQueryResponseWithQuestionModel[] =  items.map((item) => ({
      ...(queryResponses.find((response) => response.fb_path === item.key) || {} as IQueryResponseWithQuestionModel)
    }));
    onChangeQueryResponses(newResponses);
  }

  return (
    <div className="yevmiye-analysis-page-container">
      <div className="left-side">
        <div className="tab-container">
          <Tab activeTab={activeTab} tabs={tabItems} onTabClick={onTabClick} />
        </div>
        {/* {activeTab === Tabs.PlotQuery && (
          <PlotQueryForm
            handlePlotQuery={sendPlotQuery}
            plotQuery={plotQuery}
            setPlotQuery={setPlotQuery}
            responseData={plotQueryResponse}
            isLoading={isLoading}
          />
        )} */}
        {activeTab === Tabs.TextQuery && (
          <TextQueryForm
            handleTextQuery={sendQuery}
            isLoading={isLoading}
          />
        )}
        {isLoading && (
          <div className="loading-container">
            <Loader />
          </div>
        )}
      </div>
      <div className="right-side">
        <div className="action-container">
          <a
            className="download-data"
            href={`${process.env.REACT_APP_S3_BASE_URL}csv_files/ornek_kebir_anonim_real.xlsx`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Tablo İndir
          </a>
        </div>
        <div className="response_tab_container">
          <DraggableTabs items={setResponseTabItems()} onDragAndRemove={onDragAndRemoveTabs} activeKey={activeResponseTabKey} onChangeActiveKey={(key) => setActiveResponseTabKey(key)} />
        </div>
      </div>
    </div>
  );
};

export default memo(YevmiyeAnalysis);
