import QueryLogHealthDBService from "../../db-services/queryLogHealthDBService";
import {
  IQueryLogHealthCommentRequestModel,
  IQueryLogHealthResponseModel,
} from "./types";

class QueryHealthLogService {
  private dbService;

  constructor() {
    this.dbService = new QueryLogHealthDBService();
  }

  async getAllQueryLogs(
    dayAgo: number
  ): Promise<IQueryLogHealthResponseModel[]> {
    try {
      const response = await this.dbService.getAllQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogHealthResponseModel[]> {
    try {
      const response = await this.dbService.getQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogById(id: string): Promise<IQueryLogHealthResponseModel | null> {
    try {
      const response = await this.dbService.getQueryLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async sendComment(
    payload: IQueryLogHealthCommentRequestModel
  ): Promise<void> {
    try {
      const existLog = await this.dbService.getQueryLogForComment(
        payload.patient_id,
        payload.query_type
      );
      if (!existLog) {
        throw new Error("Something went wrong!");
      }
      await this.dbService.createComment(existLog.id!, {
        request_comment: payload.request_comment,
        request_rating: payload.request_rating,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default QueryHealthLogService;
