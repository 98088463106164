export interface UserModel {
    id: string;
    email: string;
    role?: UserRoleTypes
}

export enum UserRoleTypes {
    Admin = "admin",
    Standart = "standart",
    Yevmiye = "yevmiye",
    YevmiyeDev = "yevmiye-dev",
    Mevzuat = "mevzuat",
    Health = "health",
    HealthDev = "health-dev",
    Demo = "demo",
    Fund = "fund",
    FundDev = "fund-dev",
    Hospital = "hospital",
    HospitalDev = "hospital-dev",
    HospitalInEng = "hospital-in-eng",
    HospitalInEngDev = "hospital-in-eng-dev",
    WorkCube = "workcube",
    WorkCubeDev = "workcube-dev"
}

export interface UserAuditDBModel {
    lastModifierUserInfo: UserModel,
    lastModificationTime: Date,
    creationTime: Date,
    creatorUserInfo: UserModel,
}